import { useMemo } from "react"

/**
 * Some things in HTML require a globally unique "id" attribute for an element.
 * It can be a pain to ensure these are actually globally unique, so this
 * helper just sticks a random number on the end so that we can ensure no
 * conflicts.
 *
 * @param args - Stuff to prepend to the id
 */
export function getRandomId(...args: (string | undefined)[]) {
  const fixedPart = args.join("--")
  return (
    fixedPart +
    Date.now().toString(36) +
    Math.random().toString(36).substring(2, 12).padStart(12, "0")
  )
}

/**
 * Same as getRandomId but the value will be consistent across component renders.
 */
export function useRandomId(...args: (string | undefined)[]) {
  const fixedPart = args.join("--")
  return useMemo(() => getRandomId(fixedPart), [fixedPart])
}
