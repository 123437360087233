import { TableRow, TableCell } from "@material-ui/core"

import CellSkeleton from "./CellSkeleton"
import { BaseTableProps } from "./RupaTable"

function TablePlaceholderRows<TData>({ table }: BaseTableProps<TData>) {
  return (
    <>
      {[...Array(table.getState().pagination.pageSize).keys()].map((rowIdx) => (
        <TableRow key={`skeleton-row-${rowIdx}`} className="bg-white">
          {table.getAllColumns().map((column, colIdx) => (
            <TableCell
              key={`skeleton-cell-${rowIdx}-${colIdx}`}
              className="py-[14px]"
              style={{
                ...column.columnDef.meta?.cellStyle,
                ...column.columnDef.meta?.dataCellStyle,
              }}
            >
              <CellSkeleton />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}

export default TablePlaceholderRows
