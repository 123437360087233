import { useEffect } from "react"

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@rupahealth/design"

import { ReactComponent as AllDocumentsOptionIcon } from "app/assets/images/document-select-option-icon.svg"
import { ReactComponent as FoodPlanOptionIcon } from "app/assets/images/food-plan-option-icon.svg"
import { ReactComponent as OrderedResultOptionIcon } from "app/assets/images/ordered-result-option-icon.svg"
import { ReactComponent as ResultsSummaryOptionIcon } from "app/assets/images/results-summary-option-icon.svg"
import useQueryState from "app/hooks/use-query-state"
import { ResourceType } from "app/types"

export const DOCUMENT_TYPE_QUERY_PARAM_KEY = "documentType"

export enum DocumentTypeQueryParamValue {
  ALL = "all",
  FOOD_PLAN = "food_plan",
  ORDER_USER_RESULT = "ordered_report",
  RESULTS_INTERPRETATION = "results_interpretation",
  IMPORTED_USER_RESULT = "imported_result",
}

export const DocumentTypeToResourceTypeMapping = {
  [DocumentTypeQueryParamValue.ALL]: "",
  [DocumentTypeQueryParamValue.FOOD_PLAN]: ResourceType.FOOD_PLAN,
  [DocumentTypeQueryParamValue.ORDER_USER_RESULT]:
    ResourceType.ORDER_USER_RESULT,
  [DocumentTypeQueryParamValue.RESULTS_INTERPRETATION]:
    ResourceType.RESULTS_INTERPRETATION,
  [DocumentTypeQueryParamValue.IMPORTED_USER_RESULT]:
    ResourceType.IMPORTED_USER_RESULT,
}

export const DOCUMENT_TYPE_OPTIONS = [
  {
    type: DocumentTypeQueryParamValue.ALL,
    paramKey: DocumentTypeQueryParamValue.ALL,
    label: (
      <>
        Document Type <strong>All</strong>
      </>
    ),
    icon: AllDocumentsOptionIcon,
  },
  {
    type: ResourceType.ORDER_USER_RESULT,
    paramKey: DocumentTypeQueryParamValue.ORDER_USER_RESULT,
    label: "Lab Report",
    icon: OrderedResultOptionIcon,
  },
  {
    type: ResourceType.FOOD_PLAN,
    paramKey: DocumentTypeQueryParamValue.FOOD_PLAN,
    label: "Food Plan",
    icon: FoodPlanOptionIcon,
  },
  {
    type: ResourceType.RESULTS_INTERPRETATION,
    paramKey: DocumentTypeQueryParamValue.RESULTS_INTERPRETATION,
    label: "Results Summary",
    icon: ResultsSummaryOptionIcon,
  },
  {
    type: ResourceType.IMPORTED_USER_RESULT,
    paramKey: DocumentTypeQueryParamValue.IMPORTED_USER_RESULT,
    label: "Imported Result",
    icon: ResultsSummaryOptionIcon,
  },
]
export type DocumentTypeOption = typeof DOCUMENT_TYPE_OPTIONS[0]
export function getDocumentTypeOption(
  type?: ResourceType | DocumentTypeQueryParamValue.ALL
) {
  return DOCUMENT_TYPE_OPTIONS.find((option) => option.type === type)
}

export function getDocumentParamValueOption(key?: string | null) {
  return DOCUMENT_TYPE_OPTIONS.find((option) => option.paramKey === key)
}

interface Props {
  disabled?: boolean
  inPatientPortal: boolean
}
const DocumentTypeSelect: React.FC<Props> = ({ disabled, inPatientPortal }) => {
  const [{ documentType }, setQueryState] = useQueryState(
    (query) => ({
      ...query,
      [DOCUMENT_TYPE_QUERY_PARAM_KEY]: query[
        DOCUMENT_TYPE_QUERY_PARAM_KEY
      ] as DocumentTypeQueryParamValue,
    }),
    (state) => state
  )
  const handleDocumentTypeChange = (value: DocumentTypeQueryParamValue) => {
    if (value !== documentType) {
      setQueryState((state) => ({
        ...state,
        [DOCUMENT_TYPE_QUERY_PARAM_KEY]: value,
      }))
    }
  }

  useEffect(() => {
    if (documentType && !getDocumentParamValueOption(documentType)) {
      handleDocumentTypeChange(DocumentTypeQueryParamValue.ALL)
    }
  }, [documentType, handleDocumentTypeChange])

  // Do not show FOOD_PLANs in patient portal
  const filteredOptions = DOCUMENT_TYPE_OPTIONS.filter(
    (option) =>
      !(
        inPatientPortal &&
        option.paramKey === DocumentTypeQueryParamValue.FOOD_PLAN
      )
  )

  return (
    <Select
      variant="default"
      disabled={disabled}
      defaultValue={DocumentTypeQueryParamValue.ALL}
      value={documentType}
      onValueChange={handleDocumentTypeChange}
    >
      <SelectTrigger className="w-auto min-w-[200px] h-[34px]">
        <SelectValue
          placeholder={
            <DocumentOptionLabel
              option={getDocumentParamValueOption(
                DocumentTypeQueryParamValue.ALL
              )}
            />
          }
        />
      </SelectTrigger>
      <SelectContent>
        {filteredOptions.map((option) => (
          <DocumentOptionLabel key={option.paramKey} option={option} />
        ))}
      </SelectContent>
    </Select>
  )
}

const DocumentOptionLabel: React.FC<{ option?: DocumentTypeOption }> = ({
  option,
}) => {
  if (!option) return null

  return (
    <SelectItem value={option.paramKey}>
      <div className="flex items-center text-body">
        <option.icon
          className="text-slate-500 text-[14px]"
          fill="currentColor"
        />
        <span className="mx-[10px]">{option.label}</span>
      </div>
    </SelectItem>
  )
}
export default DocumentTypeSelect
