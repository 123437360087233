import { ReactComponent as FoodPlanDocumentIcon } from "app/assets/images/documents/food-plan-document-icon.svg"
import useDownloadFoodPlan from "app/food-plans/hooks/use-download-food-plan"
import useRedirectToEditFoodPlan from "app/food-plans/hooks/use-redirect-to-edit-food-plan"
import useEventCallback from "app/hooks/use-event-callback"
import { Document } from "types/patient-with-documents-v2"

import DocumentCardActionsMenu from "./DocumentCardActionsMenu"
import PatientDocumentCard from "./PatientDocumentCard"
import useDocumentsDescriptors from "./hooks/use-documents-descriptors"

interface Props {
  patientId: string
  document: Document
  inPatientPortal: boolean
  onDelete: (document: Document) => void
}

const FoodPlanDocumentCard: React.FC<Props> = ({
  patientId,
  document,
  inPatientPortal,
  onDelete,
}) => {
  const { downloadFoodPlan, isDownloadingFoodPlan } = useDownloadFoodPlan()
  const redirectToEditFoodPlan = useRedirectToEditFoodPlan()

  const canDownload = document.meta.can_read
  const canEdit =
    document.meta.can_write && document.relationships.food_plan?.data.id
  const canDelete = document.meta.can_delete

  const onClick = useEventCallback(() => {
    if (canEdit && (!inPatientPortal || !canDownload)) {
      onEdit()
    } else if (canDownload) {
      onDownload()
    }
  })

  const onEdit = useEventCallback(() => {
    if (canEdit) {
      redirectToEditFoodPlan(
        patientId,
        document.relationships.food_plan!.data.id
      )
    }
  })

  const onDownload = useEventCallback(() => {
    if (canDownload) {
      downloadFoodPlan(document.relationships.food_plan!.data.id)
    }
  })

  const { title, subtitle } = useDocumentsDescriptors(document)

  return (
    <PatientDocumentCard
      onClick={onClick}
      Icon={(className) => <FoodPlanDocumentIcon className={className} />}
      title={title}
      subTitle={subtitle}
      actions={
        <DocumentCardActionsMenu
          onDelete={canDelete ? () => onDelete(document) : undefined}
          onDownload={canDownload ? onDownload : undefined}
          isDownloading={isDownloadingFoodPlan}
          onEdit={canEdit ? onEdit : undefined}
        />
      }
    />
  )
}

export default FoodPlanDocumentCard
