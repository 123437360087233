import { useEffect, useRef, useState } from "react"

import Lottie, { LottieRefCurrentProps } from "lottie-react"

import BodyText from "app/components/design-system/BodyText"
import useStatus from "app/hooks/use-status"
import { trackPdfParsingTimeout } from "app/services/segment"
import { JobStatus } from "types/job-status"

import resultsFilesAnimation from "../../assets/results-files-lottie.json"

const PDF_PROCESSING_TIMEOUT_MILLISECONDS = 150000

interface Props {
  playAnimation?: boolean
  invocationJobId?: string
  handleProcessingComplete: (userResultId: string) => void
  handleProcessingFailed: () => void
}

const ResultsUploadPdfProcessingCard = ({
  playAnimation,
  invocationJobId,
  handleProcessingComplete,
  handleProcessingFailed,
}: Props) => {
  const lottieRef = useRef<LottieRefCurrentProps | null>(null)
  const [progress, setProgress] = useState(5)
  const { jobStatus } = useStatus({ jobId: invocationJobId, fullSummary: true })

  const lastUpdatedRef = useRef(Date.now())
  const percentageRef = useRef<number | null>(null)
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  useEffect(() => {
    const playLottie = async () => {
      // Wait for component to fully render in, we have a 500 ms transition delay in the parent component
      await sleep(500)
      lottieRef.current?.play()
    }

    if (playAnimation) {
      playLottie()

      // Start a timer that runs every 2 seconds and increments progress (this is not a true
      // representation of the progress, but a way to make the UX better)
      const interval = setInterval(() => {
        setProgress((prev) => (prev < 95 ? prev + 4 : prev))
      }, 2000)

      return () => clearInterval(interval)
    }
  }, [playAnimation])

  const updateProgressTo100AndHandleProcessing = async (
    jobStatus: JobStatus
  ) => {
    setProgress(100)

    // Give the UI some time to show a 100% progress before handling the processing
    await sleep(1000)

    // Get the userResultId by iterating over the child statuses. The pipeline uses multiple nested
    // children to handle updating its status at various points in the pipeline. The final step of
    // the pipeline is responsible for creating the `UserResult`, which is what we're looking for. So
    // to find it, we have to iterate over the children until we find one that has a UserResult. We
    // assume the first one we find is the one we need.
    //
    // NOTE: If the pipeline changes how it saves its statuses, this may cease to work as it's forced
    // to make some hardcoded assumptions about Statuses, which are an inherently flexible object.
    let userResultId = ""
    for (const child of jobStatus.children) {
      if (typeof child === "string") {
        continue
      }
      const userResults = child.properties.user_results
      if (userResults && userResults.length) {
        userResultId = userResults[0]
        break
      }
    }

    // If a job does not result in a user result here, it means something in the processing failed
    if (userResultId) {
      handleProcessingComplete(userResultId)
    } else {
      handleProcessingFailed()
    }
  }

  useEffect(() => {
    const percentage = jobStatus?.overallPercent
    if (jobStatus && percentage !== undefined) {
      // If the percentage has changed, update the last updated time and the percentage for the timeout
      // This timeout mechanism is more of a temporary stop gap to prevent the parsing to never finishing if the pipeline silently fails
      // A more long term solution is to have proper error handling in parsing pipeline
      if (
        percentageRef.current === null ||
        percentageRef.current !== percentage
      ) {
        lastUpdatedRef.current = Date.now()
        percentageRef.current = percentage
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }

        // Call the updateProgressTo100AndHandleProcessing function if the timeout is reached
        timeoutRef.current = setTimeout(() => {
          trackPdfParsingTimeout({ job_status: jobStatus })
          updateProgressTo100AndHandleProcessing(jobStatus)
        }, PDF_PROCESSING_TIMEOUT_MILLISECONDS)
      }

      if (percentage === 100) {
        updateProgressTo100AndHandleProcessing(jobStatus)
      }
    }
  }, [jobStatus])

  return (
    <div
      className="pt-[60px] pb-[76px] sm:px-[75px] px-[25px] flex flex-col items-center gap-[18px] rounded-xl border-2"
      style={{
        background:
          "linear-gradient(0deg, rgba(255, 255, 255, 0.26) 0%, rgba(255, 255, 255, 0.26) 100%), linear-gradient(155deg, #E2EBFC 27.65%, #FFE9F2 83.95%)",
        borderColor: "rgba(0,0,0,0)",
      }}
    >
      <Lottie
        animationData={resultsFilesAnimation}
        width={250}
        loop={true}
        autoplay={false}
        initialSegment={[157, 636]}
        lottieRef={lottieRef}
      />
      <div className="flex flex-col gap-[10px] items-center w-[310px]">
        <BodyText size="md" weight="semibold">
          Scanning Results...
        </BodyText>
        <div className="flex w-full h-2 bg-slate-200 rounded-3xl p-0">
          <div
            className="rounded-3xl h-2"
            style={{
              width: `${progress}%`,
              background: "linear-gradient(270deg, #11D3D7 0%, #2892E2 100%)",
              transition: "width 500ms",
            }}
          ></div>
        </div>
        <BodyText className="text-center">
          We’re extracting as many lab values as we can! This will take less
          than a minute.
        </BodyText>
      </div>
    </div>
  )
}

export default ResultsUploadPdfProcessingCard
