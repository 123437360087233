import { useMemo, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import useLabCompanies from "app/main/results-upload/hooks/use-lab-companies"
import useResultsUploadManualEntryForm from "app/main/results-upload/hooks/use-results-upload-manual-entry-form"
import { useUserResultContext } from "app/main/results-upload/providers/UserResultDataProvider"
import * as Actions from "app/store/actions"
import { safeParseDate } from "app/utils/format-date-for-human"

import useResultUploadLabTestTypes from "../modals/components/useResultUploadLabTestTypes"

const useManualUploadModalMetadata = (
  userResultId: string,
  requireReview: boolean
) => {
  const dispatch = useDispatch()
  const { userResult, userBiomarkerResults, updateUserBiomarkerResult } =
    useUserResultContext()
  const labTestTypes = useResultUploadLabTestTypes()
  const labCompanies = useLabCompanies()

  const { methods } = useResultsUploadManualEntryForm({
    userResultId,
    updateUserBiomarkerResult,
    labCompanies,
    requireReview,
  })

  const resultFields = methods.watch("results") ?? []

  const existingBiomarkerIds = useMemo(() => {
    return resultFields.map(
      (result) => result.relationships?.biomarker?.data?.id
    )
  }, [resultFields])

  const isBaseFormEmpty = useMemo(() => {
    return methods
      .getValues(["sample_collection_date", "lab_company", "sample_type"])
      .every((v) => !v)
  }, [methods, userResult])

  const [enablePartTwoForm, setEnablePartTwoForm] = useState(false)

  useEffect(() => {
    if (userResult && labCompanies && isBaseFormEmpty) {
      let labCompanyValue = labCompanies.find(
        (labCompany) =>
          labCompany.id === userResult.relationships.lab_company?.data?.id
      )?.results_name

      // If lab company is not selected, but other lab company name is present, set lab company to "other"
      // We do this so that we appropriately set the Other Lab Company name in the input field
      if (!labCompanyValue) {
        labCompanyValue = userResult.attributes.other_lab_company_name
      }

      const anyFieldsSet =
        userResult.attributes.collection_date ||
        labCompanyValue ||
        userResult.relationships.sample_types?.data[0]?.id

      const extraOptionsForSetValue = {
        shouldDirty: !!anyFieldsSet,
        shouldValidate: !!anyFieldsSet,
        shouldTouch: !!anyFieldsSet,
      }

      methods.setValue(
        "sample_collection_date",
        safeParseDate(userResult.attributes.collection_date) ?? "",
        extraOptionsForSetValue
      )
      methods.setValue(
        "fasted",
        userResult.attributes.fasting_required ? "yes" : "no",
        extraOptionsForSetValue
      )
      methods.setValue(
        "lab_company",
        labCompanyValue ?? "",
        extraOptionsForSetValue
      )
      methods.setValue(
        "other_lab_company_name",
        userResult.attributes.other_lab_company_name,
        extraOptionsForSetValue
      )
      methods.setValue(
        "sample_type",
        userResult.relationships.sample_types?.data[0]?.id ?? "",
        extraOptionsForSetValue
      )

      const areAllBaseFormFieldValuesSet =
        userResult.attributes.collection_date &&
        labCompanyValue &&
        userResult.relationships.sample_types?.data[0]?.id

      // Hide button and enable part two if user is loading already valid biomarker results
      if (areAllBaseFormFieldValuesSet && !methods.formState.isDirty) {
        setEnablePartTwoForm(true)
      }
    }
  }, [userResult, labCompanies, isBaseFormEmpty, methods])

  useEffect(() => {
    if (userBiomarkerResults?.length !== resultFields.length) {
      methods.setValue("results", userBiomarkerResults)
    }
  }, [userBiomarkerResults, resultFields, methods])

  useEffect(() => {
    dispatch(Actions.getLabTestTypeList())
  }, [dispatch])

  return {
    userResult,
    userBiomarkerResults,
    labTestTypes,
    labCompanies,
    methods,
    existingBiomarkerIds,
    resultFields,
    enablePartTwoForm,
    setEnablePartTwoForm,
  }
}

export default useManualUploadModalMetadata
