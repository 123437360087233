import { useCallback, useMemo } from "react"

import { useSWRConfig } from "swr"

// Naive hook to invalidate all cache keys that include any of the given substrings
// Use with caution, as it can cause lots of re-validating
// It's better to provide more specific keys when possible
// This function is not exclusive to Collections, but it will be as we improve our SWR abstractions
export function useInvalidateCollections(keySubstrings: string[]) {
  const { cache, mutate } = useSWRConfig()
  const allCacheKeys = cache.keys()

  const keysToInvalidate = useMemo(() => {
    let res: string[] = []
    for (const key of allCacheKeys) {
      if (keySubstrings.some((prefix) => key.includes(prefix))) {
        res.push(key)
      }
    }
    return res
  }, [keySubstrings, allCacheKeys])

  return useCallback(async () => {
    return Promise.all(keysToInvalidate.map((key) => mutate(key)))
  }, [keysToInvalidate, mutate])
}
