import React, { useRef } from "react"

import Lottie, { LottieRefCurrentProps } from "lottie-react"

import BaseFileInput from "app/components/upload/BaseFileInput"
import Dropzone from "app/components/upload/dropzone/Dropzone"
import { RESULTS_UPLOAD_FILE_TYPES } from "app/main/results-upload-v2/results-upload-modal/utils"
import resultsFilesAnimation from "app/main/results-upload/assets/results-files-lottie.json"
import isEmpty from "lodash/isEmpty"

import {
  ResultsUploadFileGroups,
  ResultsUploadFormStep,
} from "./ResultsUploadForm"
import { Step1UploadInitial } from "./Step1UploadInitial"
import { Step1UploadMore } from "./Step1UploadMore"

export interface Step1UploadProps {
  patientId: string
  onClose: () => void
  fileGroups: ResultsUploadFileGroups
  addFiles: (files: File[], groupId?: string) => void
  removeFile: (groupId: string, fileId?: string) => void
  setStep: (step: ResultsUploadFormStep) => void
}

export const Step1Upload: React.FC<Step1UploadProps> = ({
  patientId,
  onClose,
  fileGroups,
  addFiles,
  removeFile,
  setStep,
}) => {
  const lottieRef = useRef<LottieRefCurrentProps | null>(null)

  return (
    <BaseFileInput
      onFilesAdded={addFiles}
      allowedFileTypes={RESULTS_UPLOAD_FILE_TYPES}
      multiple
      disableClickToUpload
    >
      {(inputProps) => (
        <Dropzone
          {...inputProps}
          className="py-11 px-[60px] sm:px-[75px] text-body"
          hideUploadLogo={true}
        >
          <div className="flex flex-col items-center">
            <Lottie
              animationData={resultsFilesAnimation}
              width={250}
              loop={false}
              initialSegment={[0, 60]}
              lottieRef={lottieRef}
            />
            {isEmpty(fileGroups) ? (
              <Step1UploadInitial
                patientId={patientId}
                onClose={onClose}
                handleUploadClick={inputProps.handleClick}
              />
            ) : (
              <Step1UploadMore
                fileGroups={fileGroups}
                setStep={setStep}
                addFiles={addFiles}
                removeFile={removeFile}
                handleUploadClick={inputProps.handleClick}
              />
            )}
          </div>
        </Dropzone>
      )}
    </BaseFileInput>
  )
}
