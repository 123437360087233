/**
 * The SignatureAndLicenseModal is a reusable component for collecting
 * e-signatures and licenses from anywhere in the app.
 *
 * First, the component will launch a HelloSign embedded session.
 * Once the e-signature is completed, it will launch a modal for uploading the
 * practitioner's license.
 *
 * After that is done, it will allow the practitioner to return to their page,
 * and will call the `onFullVerificationComplete` prop function. This allows
 * the rendering component to handle the verification status update.
 */
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { Theme, useMediaQuery } from "@material-ui/core"
import * as Sentry from "@sentry/react"

import { API } from "app/api"
import useInterval from "app/hooks/use-interval"
import useAppSelector from "app/hooks/useAppSelector"
import { setHideIntercom } from "app/services/intercom"
import { showSuccessToast } from "app/utils"

import ESignature from "./ESignature"
import UploadLicenseModal from "./UploadLicenseModal"

enum LicenseUploadStatus {
  COMPLETED = "completed",
  FAILED = "failed",
  STARTED = "started",
  UNSTARTED = "unstarted",
}

enum SignatureStatus {
  UNOPENED = "unopened",
  OPEN = "open",
  COMPLETED = "completed",
}

// Verification status polling config
const POLLING_FREQUENCY = 3000 // Poll API every 3 seconds
const MAX_ATTEMPTS = 60 // Fail after 60 attempts (3 minutes)

interface Props {
  autoClose?: boolean
  onClose: (fullVerificationComplete: boolean) => void
  onOpen: () => void
  open: boolean
  returnLinkText?: string
  onLicenseUpload?: () => void
}

const SignatureAndLicenseModal = ({
  autoClose,
  onClose,
  onOpen,
  open,
  returnLinkText,
  onLicenseUpload,
}: Props) => {
  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const [signatureStatus, setSignatureStatus] = useState<SignatureStatus>(
    SignatureStatus.UNOPENED
  )

  useEffect(() => {
    // practitioner may not be loaded at time of initial render
    // Skip the e-signature step if the practitioner has already provided a signature
    const sig_status = practitioner.has_signature
      ? SignatureStatus.COMPLETED
      : SignatureStatus.UNOPENED
    setSignatureStatus(sig_status)
  }, [practitioner.has_signature])

  const [fullVerificationComplete, setFullVerificationComplete] =
    useState(false)

  const [licenseUploadStatus, setLicenseUploadStatus] =
    useState<LicenseUploadStatus>(LicenseUploadStatus.UNSTARTED)

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const onUploadLicenseStart = (): void => {
    setLicenseUploadStatus(LicenseUploadStatus.STARTED)
  }
  const onUploadLicenseFail = (): void => {
    setLicenseUploadStatus(LicenseUploadStatus.FAILED)
  }

  const onUploadLicenseComplete = () => {
    setLicenseUploadStatus(LicenseUploadStatus.COMPLETED)
    if (onLicenseUpload) {
      onLicenseUpload()
    }
  }

  useVerificationPolling(licenseUploadStatus, () => {
    // Triggered once the verification polling indicates the practitioner is fully verified.
    setFullVerificationComplete(true)
  })

  const onEsignCompleted = () => {
    setHideIntercom(false)
    window.Intercom("trackEvent", "esignature-completed")
    setSignatureStatus(SignatureStatus.COMPLETED)
  }

  const handleEsignOpen = () => {
    if (isMobile) {
      setHideIntercom(true)
    }
    onOpen()
  }
  const handleEsignClose = () => {
    setHideIntercom(false)
    if (fullVerificationComplete) {
      onClose(fullVerificationComplete)
    }
  }
  const handleEsignCancel = () => {
    setHideIntercom(false)
    onClose(fullVerificationComplete)
  }

  const handleEsignFail = (error: Error) => {
    setHideIntercom(false)
    onClose(fullVerificationComplete)
    Sentry.captureException(error)
  }

  const handleUploadLicenseClose = () => {
    onClose(fullVerificationComplete)
  }

  const showEsignature = signatureStatus !== SignatureStatus.COMPLETED
  const showLicenseUpload = signatureStatus === SignatureStatus.COMPLETED

  if (open && showEsignature) {
    return (
      <ESignature
        onEsignCancel={handleEsignCancel}
        onEsignClose={handleEsignClose}
        onEsignCompleted={onEsignCompleted}
        onEsignFail={handleEsignFail}
        onEsignOpen={handleEsignOpen}
      />
    )
  }

  return (
    <UploadLicenseModal
      autoClose={autoClose}
      fullVerificationComplete={fullVerificationComplete}
      onClose={handleUploadLicenseClose}
      onUploadComplete={onUploadLicenseComplete}
      onUploadFail={onUploadLicenseFail}
      onUploadStart={onUploadLicenseStart}
      open={open && showLicenseUpload}
      returnLinkText={returnLinkText}
    />
  )
}

function useVerificationPolling(
  licenseUploadStatus: LicenseUploadStatus,
  onFullVerificationComplete: () => void
) {
  const [pollAttempts, setPollAttempts] = useState<number>(0)
  const [statusPolling, setStatusPolling] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (licenseUploadStatus === LicenseUploadStatus.STARTED) {
      // Once the license upload is started, poll the verification status
      // endpoint to determine when the signature and license are fully completed.
      setStatusPolling(true)
    } else if (licenseUploadStatus === LicenseUploadStatus.FAILED) {
      // If the license upload fails, stop polling and reset the poll attempts
      setPollAttempts(0)
      setStatusPolling(false)
    }
  }, [licenseUploadStatus])

  useInterval(
    async (): Promise<void> => {
      // API request to check esign status
      await API.Practitioner.getVerificationStatus().then((response): void => {
        const hasSignature = response.data.has_completed_esignature
        const hasValidLicense = response.data.has_valid_license
        if (!hasSignature || !hasValidLicense) {
          return
        }

        // Verification is complete - update state
        setStatusPolling(false)

        // Trigger the callback
        onFullVerificationComplete()

        // Show a success message
        dispatch(showSuccessToast({ message: "Verification completed." }))
      })

      // If we've exceeded a certain number of polling attempts,
      // it's possible that there's an issue with backend processing.
      // Stop spamming the server and ask the user to refresh and try again.
      setPollAttempts(pollAttempts + 1)
      if (pollAttempts >= MAX_ATTEMPTS) {
        Sentry.captureMessage(
          "Signature and license verification polling timed out.",
          {
            level: "warning",
          }
        )
        setStatusPolling(false)
        dispatch(
          showSuccessToast({
            message:
              "Unable to process verification information. Please fresh and try again.",
          })
        )
      }
    },
    statusPolling ? POLLING_FREQUENCY : null
  )
}

export default SignatureAndLicenseModal
