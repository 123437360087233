import { ReactComponent as AirplaneIcon } from "app/assets/images/airplane-blue.svg"
import SpecimenIssueDescription from "app/components/SpecimenIssues/SpecimenIssueDescription"
import { ORDERED_TEST_STATUS } from "app/constants.typed"
import formatDateForHuman from "app/utils/format-date-for-human"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/patient-orders-types"
import { PROGRESS_PERCENTAGES } from "../consts"

const Title = ({ orderedTest }: StatusConfigurationProps) => {
  if (orderedTest?.latest_ordered_result?.date_result_reviewed) {
    return <>Results Reviewed</>
  }
  return <>Results In</>
}

const SubTitle = ({
  orderedTest,
  patient,
  specimenIssue,
  specimenIssueShowMore,
  setSpecimenIssueShowMore,
}: StatusConfigurationProps) => {
  return (
    <div>
      {orderedTest?.date_results_sent_to_patient && (
        <div>
          <AirplaneIcon
            fill="currentColor"
            height={13}
            width={13}
            viewBox="0 0 18 18"
          />
          <span>
            {` Results sent to ${patient.full_name} on ${formatDateForHuman(
              orderedTest.date_results_sent_to_patient
            )}`}
          </span>
        </div>
      )}

      {specimenIssue && (
        <SpecimenIssueDescription
          description={specimenIssue.description}
          showMore={specimenIssueShowMore}
          setShowMore={setSpecimenIssueShowMore}
        />
      )}
    </div>
  )
}

const LastUpdatedAt = ({ orderedTest }: StatusConfigurationProps) => {
  const lastUpdatedAt =
    orderedTest.date_results_received_from_lab || orderedTest.updated_at
  return <>{formatDateForHuman(lastUpdatedAt)}</>
}

const progressPercentage = ({ orderedTest }: StatusConfigurationProps) =>
  orderedTest.status === ORDERED_TEST_STATUS.COMPLETE
    ? PROGRESS_PERCENTAGES.COMPLETE
    : PROGRESS_PERCENTAGES.RESULTS_IN

const resultsInStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: { orderedTestStatus: ORDERED_TEST_STATUS.COMPLETE },
    lastUpdatedAt: LastUpdatedAt,
    progressPercentage: progressPercentage,
    title: Title,
    subTitle: SubTitle,
  }

export default resultsInStatusConfiguration
