import { useCallback, useMemo } from "react"

import { isEqual } from "lodash"
import { useSWRConfig } from "swr"

import { isSubscriptionCache } from "app/swr/helpers/cache"
import { getResourceCacheKey } from "app/swr/helpers/keys"
import { Resource, ResourceIdentifier } from "app/swr/types"
import { useSyncExternalStore } from "use-sync-external-store/shim"

export type NullableCollection<R> = (R | undefined)[]

export type NullableUseCachedCollectionOptions = {
  nullable: true
}

export type NonNullableCollection<R> = R[]

export type NonNullableUseCachedCollectionOptions = {
  nullable: false
}

/**
 * Accesses a resource collection from the cache by the given identifiers.
 *
 * Best used once data is fetched from a useResourceSWR hook, for example:
 *
 * ```typescript
 * const { data: labTest } = useResourceSWR<LabTest>("/lab_tests/123", { include: ["lab_test_types"] })
 *
 * // Access the included lab company from the cache.
 * const labTestTypes = useCachedCollection<LabTestType>(labTest?.relationships.lab_test_types.data)
 * ```
 *
 * @param identifiers the resource identifiers
 * @returns the resource collection from cache, if it exists
 */
export function useCachedCollection<R extends Resource>(
  identifiers: ResourceIdentifier[] | undefined
): NonNullableCollection<R>
export function useCachedCollection<R extends Resource>(
  identifiers: ResourceIdentifier[] | undefined,
  options: NullableUseCachedCollectionOptions
): NullableCollection<R>
export function useCachedCollection<R extends Resource>(
  identifiers: ResourceIdentifier[] | undefined,
  options: NonNullableUseCachedCollectionOptions
): NonNullableCollection<R>
export function useCachedCollection<R extends Resource>(
  identifiers: ResourceIdentifier[] | undefined,
  options?:
    | NullableUseCachedCollectionOptions
    | NonNullableUseCachedCollectionOptions
): NullableCollection<R> | NonNullableCollection<R> {
  const { cache } = useSWRConfig()

  const keys = useMemo(() => {
    return (
      identifiers?.map<string>((identifier) =>
        getResourceCacheKey(identifier)
      ) || []
    )
  }, [identifiers])

  const getSnapshot = useMemo(() => {
    const getNewSnapshot = () => {
      const values = keys.map((key) => {
        return cache.get(key)?.data
      })
      if (options?.nullable) {
        return values
      }

      return values.filter(Boolean)
    }

    let memorizedSnapshot = getNewSnapshot()

    return () => {
      const snapshot = getNewSnapshot()
      return isEqual(snapshot, memorizedSnapshot)
        ? memorizedSnapshot
        : (memorizedSnapshot = snapshot)
    }
  }, [cache, keys])

  const cached = useSyncExternalStore(
    useCallback(
      (callback) => {
        if (isSubscriptionCache(cache)) {
          const unsubs = keys.map((key) =>
            cache.subscribe(key, (prev, current) => {
              if (!isEqual(prev, current)) {
                callback()
              }
            })
          )

          return () => unsubs.forEach((unsub) => unsub())
        }
      },
      [cache, keys]
    ),
    getSnapshot,
    getSnapshot
  )

  return cached
}

export default useCachedCollection
