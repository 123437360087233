import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { cn } from "@rupahealth/design"

import SampleTypePill from "app/components/SampleTypePill"
import BodyText from "app/components/design-system/BodyText"
import ResultsOverTimeChartSimple from "app/main/results-over-time/ResultsOverTimeChartSimple"
import { primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import {
  ResultsOverTimeBiomarkerData,
  ResultsOverTimeDateGrouping,
} from "../types/types"

const useStyles = makeAppStyles(() => ({
  biomarkerText: {
    "&:hover p": {
      color: `${primaryColor} !important`,
    },
  },
}))

interface Props {
  biomarker: ResultsOverTimeBiomarkerData
  availableDates: string[]
  groupedBy: ResultsOverTimeDateGrouping
  showSampleTypes: boolean
}

const BiomarkerInfoColumn = ({
  biomarker,
  availableDates,
  groupedBy,
  showSampleTypes,
}: Props) => {
  const classes = useStyles()

  return (
    <div className="flex flex-col sm:flex-row-reverse items-end sm:items-center justify-between hover:cursor-pointer w-full ">
      <div>
        <div
          className={cn(
            "flex flex-row-reverse items-center mb-1 break-all",
            classes.biomarkerText
          )}
        >
          <BodyText weight="semibold" className="mr-2">
            {biomarker.short_name}
          </BodyText>
          <FontAwesomeIcon
            className="fa-light text-primary cursor-pointer flex-grow-0 flex-shrink-0 basis-auto"
            icon={faCircleInfo}
          />
        </div>
        <div className="flex flex-row-reverse items-center flex-wrap">
          {showSampleTypes &&
            biomarker.sample_types?.map((sampleType) => {
              return (
                <SampleTypePill
                  name={sampleType.toLowerCase()}
                  key={sampleType}
                  className="ml-0 mr-[2px] w-fit"
                />
              )
            })}
        </div>
      </div>
      <div className="px-1 w-full sm:max-w-[100px] sm:w-[100px] ml-2">
        <ResultsOverTimeChartSimple
          data={biomarker.results}
          availableDates={availableDates}
          groupedBy={groupedBy}
        />
      </div>
    </div>
  )
}

export default BiomarkerInfoColumn
