import {
  Resource,
  ResourceIdentifier,
  ResourceRelationshipMany,
  ResourceRelationshipSingle,
} from "app/swr/types"
import { OrderingRightsStatus } from "app/types"

export const PAGE_SIZE = 25

export interface OrderTypeIdentifier extends ResourceIdentifier {
  type: "in_office_kit_order"
}

export interface InOfficeKitOrder extends Resource {
  type: "in_office_kit_order"
  attributes: {
    id: string
    status: string
    street: string
    street_2: string
    city: string
    state: string
    zipcode: string
    clinic: string
    ordering_practitioner: string
    singing_practitioner: string
    tracking_number: string
    date_tracking_number_received: string
    date_ordered_by_practitioner: string
    date_ordered_by_rupa: string
  }
  relationships: {
    in_office_kit_lab_company: ResourceRelationshipSingle
  }
}

export interface OrderDetailsType extends Resource {
  attributes: {
    id: string
    status: string
    street: string
    street_2: string
    city: string
    state: string
    zipcode: string
    clinic: string
    ordering_practitioner: string
    singing_practitioner: string
    tracking_number: string
    date_tracking_number_received: string
    date_ordered_by_practitioner: string
    date_ordered_by_rupa: string
  }
  relationships: {
    in_office_kit_lab_company: ResourceRelationshipSingle
  }
}

export interface InOfficeKitOrderedProductType extends Resource {}

export interface InOfficeKitLabCompanyTypeIdentifier
  extends ResourceIdentifier {
  type: "in_office_kit_lab_company"
}

export interface InOfficeKitLabCompanyType extends Resource {
  type: "in_office_kit_lab_company"
  attributes: {
    id: string
    resource_text: string
    disabled: boolean
    lab_company_name: string
  }
  relationships: {
    lab_company: ResourceRelationshipSingle
  }
}

export interface LabCompanyOrderingRightType extends Resource {
  type: "lab_company_ordering_right"
  attributes: {
    disallowed_states: string[]
  }
  relationships: {
    practitioner_types: ResourceRelationshipMany
  }
}

export interface LabCompanyType extends Resource {
  id: string
  name: string
  short_name: string
  key: string
  logo?: string
}

export interface InOfficeKitProductCategoryType {
  id: string
  name: string
  category_type: string
  max_order_quantity: number | null
  sort_order: number | null
}

export interface InOfficeKitType extends Resource {
  type: "in_office_kit"
  id: string
  attributes: {
    activation_id: string
    status: string
  }
  relationships: {
    ordered_tests: ResourceRelationshipMany
    in_office_kit_ordered_product: ResourceRelationshipSingle
  }
}

export interface InOfficeKitProduct extends Resource {
  type: "in_office_kit_product"
  attributes: {
    name: string
    description: string
    category: string
    max_quantity: number | null
    min_quantity: number | null
    inventory_max: number | null
    clinic_current_or_pending_inventory: number
    disabled: boolean
    in_office_kit_product_category: InOfficeKitProductCategoryType | null
    unavailable_reason: string | null
  }
  relationships: {
    in_office_kit_lab_company: ResourceRelationshipSingle
    lab_tests: ResourceRelationshipMany
  }
}

export interface InOfficeKitLabTest extends Resource {
  type: "in_office_kit_lab_test"
  attributes: {
    name: string
    description: string
    ordering_rights_status: OrderingRightsStatus
    has_instant_requisition: boolean
  }
  relationships: {
    lab_test_types: ResourceRelationshipMany
  }
}

export interface InOfficeKitProductInventoryConfigIdentifier
  extends ResourceIdentifier {
  type: "in_office_kit_product_inventory_config"
}

export interface InOfficeKitProductInventoryConfig extends Resource {
  type: "in_office_kit_product_inventory_config"
  attributes: {
    inventory_max?: number
    order_max?: number
    in_office_kit_product_category_type?: string
    config_type: string
  }
  relationships: {
    clinic: ResourceRelationshipSingle
    in_office_kit_product?: ResourceRelationshipSingle
    in_office_kit_product_category?: ResourceRelationshipSingle
  }
}
