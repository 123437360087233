import { useState } from "react"

import { Container } from "@material-ui/core"
import {
  getCoreRowModel,
  getFilteredRowModel,
  OnChangeFn,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table"

import RupaTable from "app/components/design-system/RupaTable/RupaTable"
import useDebounceState from "app/hooks/use-debounce-state"
import isFunction from "lodash/isFunction"

import ClientsListPlaceholder from "../ClientsListPlaceholder"
import usePatientsCollection from "../usePatientsCollection"
import {
  PatientBirthdateColumn,
  PatientDetailsButtonColumn,
  PatientEmailColumn,
  PatientNameColumn,
} from "./ClientsTableColumns"

const ClientsTable: React.FC = () => {
  const [debouncedSearchTerm, searchTerm, setSearchTerm] = useDebounceState("")
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })
  const onPaginationChange: OnChangeFn<PaginationState> = (updater) => {
    setPagination((prev) => (isFunction(updater) ? updater(prev) : updater))
  }

  const { patients, isLoading, isValidating, meta } = usePatientsCollection({
    filters: { search: debouncedSearchTerm },
    pagination,
  })
  const count = meta?.pagination?.count
  const pageCount = count ? Math.ceil(count / pagination.pageSize) : -1

  // There are no patients to show and we're not limiting results by searching
  const noDataForTable =
    patients.length === 0 && !isLoading && !debouncedSearchTerm

  const columns = [
    PatientNameColumn,
    PatientEmailColumn,
    PatientBirthdateColumn,
    PatientDetailsButtonColumn,
  ]

  const table = useReactTable({
    data: patients,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: (row) => row.id,
    meta: { isLoading, isValidating, count },
    pageCount,
    manualPagination: true,
    onPaginationChange,
    state: { pagination },
  })

  return (
    <Container className="mt-6 mb-10" maxWidth="xl">
      {noDataForTable ? (
        <ClientsListPlaceholder />
      ) : (
        <RupaTable
          table={table}
          searchTerm={searchTerm}
          onSearchTermChange={noDataForTable ? undefined : setSearchTerm}
          searchPlaceholder="Filter by name or email..."
        />
      )}
    </Container>
  )
}

export default ClientsTable
