import { useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { CircularProgress, Grid, makeStyles } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import { StorefrontStatus } from "app/constants.typed"
import { StorefrontType } from "app/hooks/use-resource"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { showErrorToast } from "app/utils"

import { StyledFormControlLabel } from "../settings/components/StyledFormControlLabel"
import { StyledSwitch } from "../settings/components/StyledSwitch"
import { PractitionerProfileService } from "./types"

const useStyles = makeStyles((theme) => ({
  headerText: {
    marginTop: theme.spacing(4.25),
  },
}))

export const PractitionerLabshops = ({ mutateProfile }) => {
  const [loadingIds, setLoadingIds] = useState({})
  const dispatch = useDispatch()

  const {
    data: practitionerProfileServiceIdentifiers,
    mutate: mutateServices,
  } = useCollectionSWR<ResourceCollection<PractitionerProfileService>>(
    "/practitioner_profile_services/"
  )

  const cachedServices = useCachedCollection<PractitionerProfileService>(
    practitionerProfileServiceIdentifiers
  )

  const { data: storefrontIdentifiers } =
    useCollectionSWR<ResourceCollection<StorefrontType>>("/storefronts/")

  const storefronts = useCachedCollection<StorefrontType>(storefrontIdentifiers)

  const handleError = () => {
    setLoadingIds({})
    dispatch(
      showErrorToast({
        message:
          "There was an error updating your LabShops, please refresh and try again.",
      })
    )
  }

  async function createService(labshopId) {
    const storefront = storefronts.find(
      (storefront) => storefront.id === labshopId
    )
    const data = {
      type: "PractitionerProfileService",
      attributes: {
        name: storefront?.attributes.name,
        description: storefront?.attributes.description,
      },
      relationships: {
        labshop: {
          data: {
            type: "storefront",
            id: labshopId,
          },
        },
      },
    }

    try {
      await resourceRequest<PractitionerProfileService>({
        url: "/practitioner_profile_services/",
        data: { data },
        method: "POST",
      })
    } catch (error) {
      handleError()
    }

    mutateServices()
    mutateProfile()
    setLoadingIds((prevLoadingIds) => ({
      ...prevLoadingIds,
      [labshopId]: false,
    }))
  }

  async function deleteService(labshopId) {
    const storefrontService = cachedServices.find(
      (service) => service.relationships.labshop?.data?.id === labshopId
    )

    if (!storefrontService) {
      return
    }

    try {
      await resourceRequest<PractitionerProfileService>({
        url: `/practitioner_profile_services/${storefrontService.id}/`,
        method: "DELETE",
      })
    } catch (error) {
      handleError()
    }

    mutateServices()
    mutateProfile()
    setLoadingIds((prevLoadingIds) => ({
      ...prevLoadingIds,
      [labshopId]: false,
    }))
  }

  const storefrontsWithServices = useMemo(() => {
    const activeStorefronts = storefronts.filter(
      (storefront) =>
        storefront.attributes.status === StorefrontStatus.Published
    )

    return activeStorefronts.map((storefront) => {
      const storefrontService = cachedServices.find(
        (service) => service.relationships.labshop?.data?.id === storefront.id
      )

      return {
        ...storefront,
        service: storefrontService,
      }
    })
  }, [cachedServices, storefronts])

  const handleOnChange = (event) => {
    const loadingIdUpdate = {
      [event.target.name]: true,
    }

    setLoadingIds((prevLoadingIds) => ({
      ...prevLoadingIds,
      ...loadingIdUpdate,
    }))

    if (event.target.checked) {
      createService(event.target.name)
    } else {
      deleteService(event.target.name)
    }
  }

  const classes = useStyles()

  if (!storefrontsWithServices?.length) {
    return null
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <BodyText size="lg" weight="semibold" className={classes.headerText}>
          LabShops
        </BodyText>
      </Grid>
      <Grid item container>
        {storefrontsWithServices.map((storefront) => (
          <Grid item xs={12} key={storefront.id}>
            <StyledFormControlLabel
              control={
                <StyledSwitch
                  checked={!!storefront.service}
                  name={storefront.id}
                  onChange={handleOnChange}
                  disabled={loadingIds[storefront.id]}
                />
              }
              label={
                <>
                  <BodyText weight="semibold">
                    {(!!storefront.attributes.name &&
                      storefront.attributes.name) ||
                      "LabShop"}
                    {loadingIds[storefront.id] && (
                      <CircularProgress
                        size={12}
                        aria-label={"loading"}
                        style={{ marginLeft: 12 }}
                      />
                    )}
                  </BodyText>
                </>
              }
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
