import React, { useState } from "react"

import { ReactComponent as FlaskIcon } from "app/assets/icons/flask.svg"
import RupaLoadingButton, {
  RupaLoadingButtonProps,
} from "app/components/RupaLoadingButton"
import useFeatureFlag from "app/hooks/use-feature-flag"
import useResultsUploadPdfModal from "app/main/results-upload/modals/ResultsUploadPdfModal"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { primaryColor } from "app/theme"

import useResultsUploadModal from "./results-upload-modal/ResultsUploadModal"
import { useResultsUpload } from "./results-upload-modal/hooks/use-results-upload"

/**
 * This Button shows modals conditionally while we are in an in-between state with feature flags
 * TODO: PROD-2332 flag removal
 */

interface Props extends Omit<RupaLoadingButtonProps, "loading" | "children"> {
  patientId: string
  existingUserResultId?: string
  existingOrderedResultId?: string
  buttonText?: string
  children?: React.ReactNode
  onClick?: () => void
  onClose?: () => void
}

export const ResultsUploadButton: React.FC<Props> = ({
  patientId,
  existingUserResultId,
  existingOrderedResultId,
  buttonText = "Import Results",
  children,
  onClick,
  onClose,
  ...buttonProps
}) => {
  const [loading, setLoading] = useState(false)
  const [simultaneousUploadsEnabled, flagLoading] = useFeatureFlag(
    FeatureFlag.SimultaneousResultsUploads
  )
  const resultsUploadModal = useResultsUploadModal()
  const { createAndInvokeFromOrderedResult } = useResultsUpload({ patientId })

  const resultsUploadModalLegacy = useResultsUploadPdfModal()

  const showLegacyModal = () => {
    resultsUploadModalLegacy.show({
      onClose: () => {
        resultsUploadModalLegacy.remove()
        onClose?.()
      },
      patientId,
      existingUserResultId,
      existingOrderedResultId: existingUserResultId
        ? undefined
        : existingOrderedResultId,
    })
  }

  const uploadExistingOrderedResult = async (orderedResultId: string) => {
    setLoading(true)
    await createAndInvokeFromOrderedResult(orderedResultId)
    setLoading(false)
  }

  const showModalOrCreateUserResult = async () => {
    if (simultaneousUploadsEnabled) {
      if (existingUserResultId) {
        showLegacyModal()
      } else if (existingOrderedResultId) {
        uploadExistingOrderedResult(existingOrderedResultId)
        return
      } else {
        resultsUploadModal.show({ patientId })
        return
      }
    }

    // Fallthrough to legacy modal
    showLegacyModal()
  }

  const onButtonClick = async () => {
    if (flagLoading || loading) return

    showModalOrCreateUserResult()
    onClick?.()
  }

  return (
    <RupaLoadingButton
      variant={null}
      className="text-primary max-md:px-0 max-md:justify-start hover:bg-slate-100 py-1 px-2 h-auto text-[15px]"
      disabled={flagLoading}
      onClick={onButtonClick}
      loading={loading}
      {...buttonProps}
    >
      {children ?? (
        <>
          <FlaskIcon className="mr-2" fill={primaryColor} /> {buttonText}
        </>
      )}
    </RupaLoadingButton>
  )
}
