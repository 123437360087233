import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"

import useResultVisualizationNotifications from "../hooks/use-result-visualization-notifications"
import ResultVisualizationNotificationsList from "./ResultVisualuzationNotificationsList"

interface Props {
  patientId: string
}

const ResultVisualiztionNotifications = ({ patientId }: Props) => {
  const { notifications, acknowledgeNotification, dismissNotification } =
    useResultVisualizationNotifications(patientId)

  if (!notifications?.length) {
    return null
  }

  return (
    <div className="flex flex-col gap-5">
      <div className="flex gap-[6px] items-center">
        <BodyText weight="semibold">
          Prepare results to track in Trends
        </BodyText>
        <Tooltip
          title="Results from these labs aren’t tracked in Trends automatically. Enter the values for these results to visualize them over time."
          placement="bottom"
          arrow
        >
          <FontAwesomeIcon
            icon={faQuestionCircle}
            className="text-slate-500 text-sm13"
          />
        </Tooltip>
      </div>
      <ResultVisualizationNotificationsList
        patientId={patientId}
        notifications={notifications}
        onAcknowledge={acknowledgeNotification}
        onDismiss={dismissNotification}
      />
    </div>
  )
}

export default ResultVisualiztionNotifications
