import { useCallback } from "react"
import { useDispatch } from "react-redux"

import { showErrorToast } from "app/utils"

import { humanFileSize, validateFileMime } from "./helpers"

export interface UploadHandlerProps {
  maxFiles?: number
  maxFileSize?: number
  allowedFileTypes?: string
  onFilesAdded?: (files: File[]) => void
  onError?: (errors: string[] | undefined) => void
}

// Validate a file when it is uploaded
export default function useFileUpload({
  maxFiles,
  maxFileSize,
  allowedFileTypes,
  onFilesAdded,
  onError,
}: UploadHandlerProps) {
  const dispatch = useDispatch()

  const handleErrors = (errors: string[]) => {
    if (onError) {
      onError(errors)
    } else {
      for (const message of errors) {
        dispatch(showErrorToast({ message }))
      }
    }
  }

  const handleUpload = useCallback(
    (fileList: FileList) => {
      const files = Array.from(fileList)
      if (!files.length) return

      if (maxFiles != null && files.length > maxFiles) {
        handleErrors([
          `Maximum of ${maxFiles} file(s) at a time (tried to add ${files.length}.)`,
        ])
        return
      }

      const errors: string[] = []
      files.forEach((file) => {
        if (!validateFileMime(file, allowedFileTypes)) {
          errors.push(
            `"${file.name}" can't be added, only files of type "${allowedFileTypes}" are allowed.`
          )
        }
        if (maxFileSize && file.size > maxFileSize) {
          errors.push(
            `"${file.name}" is too large (${humanFileSize(
              file.size
            )}). Max size is ${humanFileSize(maxFileSize)}.`
          )
        }
      })

      if (errors.length) {
        handleErrors(errors)
      } else {
        onFilesAdded?.(files)
      }
    },
    [allowedFileTypes, maxFiles, maxFileSize, onFilesAdded, onError]
  )

  return { handleUpload }
}
