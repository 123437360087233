import ClientsListPlaceholderImg from "app/assets/icons/clients-list-placeholder.svg"
import useAppSelector from "app/hooks/useAppSelector"

import AddAClientButtonAndModal from "./AddAClientButtonAndModal"

const ClientsListPlaceholder: React.FC = () => {
  const practitioner = useAppSelector(({ practitioner }) => practitioner)

  return (
    <div className="flex flex-col flex-wrap content-center my-6">
      <img
        src={ClientsListPlaceholderImg}
        alt="No clients yet"
        width="264px"
        className="self-center mb-3"
      />

      <p className="mb-3 text-center text-lg17 w-[375px]">
        Create a client to order labs, import past results into Trends, or build
        a Food Plan.
      </p>

      <div className="flex self-center w-[342px]">
        <AddAClientButtonAndModal
          icon
          className="grow"
          practitioner={practitioner}
        />
      </div>
    </div>
  )
}

export default ClientsListPlaceholder
