import { useMemo } from "react"

import clsx from "clsx"

import BodyText from "app/components/design-system/BodyText"
import BloodLabDashboardsBiomarkerStatusIndicator from "app/main/blood-lab-dashboards/BloodLabDashboardsPatientPreview/statuses/BloodLabDashboardsBiomarkerStatusIndicator"
import useBloodLabDashboardsBloodReportResultDetailModal from "app/main/blood-lab-dashboards/hooks/use-blood-lab-dashboards-blood-report-result-modal"
import { BiomarkerStatus } from "app/patient-portal/blood-lab-dashboard/constants"
import { colors, navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import { MAX_DATA_COLUMNS } from "../constants/constants"
import {
  ResultsOverTimeBiomarkerData,
  ResultsOverTimeDateGrouping,
  ResultsOverTimeResultData,
} from "../types/types"

const RESULT_BIOMARKER_STATUS_TO_COLORS = {
  [BiomarkerStatus.OPTIMAL]: "emerald",
  [BiomarkerStatus.NORMAL]: "emerald",
  [BiomarkerStatus.ABOVE_OPTIMAL]: "lime",
  [BiomarkerStatus.BELOW_OPTIMAL]: "lime",
  [BiomarkerStatus.HIGH]: "rose",
  [BiomarkerStatus.LOW]: "rose",
  [BiomarkerStatus.ABNORMAL]: "rose",
}

const useStyles = makeAppStyles<{
  status?: BiomarkerStatus
  isNeutralValueDisplay: boolean
}>(() => ({
  resultCardBackgroundAndBorder: {
    backgroundColor: ({ status, isNeutralValueDisplay }) =>
      isNeutralValueDisplay
        ? colors.blueGray[200]
        : status
        ? colors[RESULT_BIOMARKER_STATUS_TO_COLORS[status]][100]
        : colors.blueGray[50],
    border: "1px solid transparent",
    "&:hover": {
      borderColor: ({ status, isNeutralValueDisplay }) =>
        isNeutralValueDisplay
          ? colors.blueGray[400]
          : status
          ? colors[RESULT_BIOMARKER_STATUS_TO_COLORS[status]][400]
          : colors.blueGray[400],
    },
  },
  resultCardValueText: {
    color: ({ status, isNeutralValueDisplay }) =>
      isNeutralValueDisplay
        ? navy
        : status
        ? colors[RESULT_BIOMARKER_STATUS_TO_COLORS[status]][800]
        : colors.blueGray[800],
    // needed or else special characters (<, >) will get flipped in sequence
    direction: "ltr",
  },
  resultCardUnitText: {
    color: ({ status, isNeutralValueDisplay }) =>
      isNeutralValueDisplay
        ? navy
        : status
        ? colors[RESULT_BIOMARKER_STATUS_TO_COLORS[status]][600]
        : colors.blueGray[600],
    marginLeft: 3,
  },
}))

interface Props {
  sourceBiomarker: ResultsOverTimeBiomarkerData
  result: ResultsOverTimeResultData
  clinicName?: string
  groupedBy: ResultsOverTimeDateGrouping
  showHighLowDescriptions: boolean
}

const ResultCard = ({
  sourceBiomarker,
  result,
  clinicName,
  groupedBy,
  showHighLowDescriptions,
}: Props) => {
  const biomarkerStatus = result.status

  const isNeutralValueDisplay =
    !result.display_value ||
    (!result.standard_range_min && !result.standard_range_max)

  const classes = useStyles({
    status: biomarkerStatus,
    isNeutralValueDisplay,
  })

  const biomarkerDetailModal =
    useBloodLabDashboardsBloodReportResultDetailModal()

  // We want to ensure that we are only ever sending at most, MAX_DATA_COLUMNS in length
  // of results to the Biomarker Detail Modal. If the result is within the first MAX_DATA_COLUMNS results,
  // we send the first MAX_DATA_COLUMNS results. If the result is within the 6th to nth results, we send
  // from that index + MAX_DATA_COLUMNS - 1 of the latest results.
  // ie - index 5 would return [1, 6]
  const [resultsOverTimeStartingIndex, resultsOverTimeEndingIndex] =
    useMemo(() => {
      if (sourceBiomarker.results.length <= MAX_DATA_COLUMNS) {
        return [0, MAX_DATA_COLUMNS]
      } else {
        const maxIndex = MAX_DATA_COLUMNS - 1
        const indexOfCurrResult = sourceBiomarker.results.findIndex(
          (res) => res.id === result.id
        )

        if (indexOfCurrResult > maxIndex) {
          const offset = indexOfCurrResult - maxIndex

          return [offset, indexOfCurrResult + 1]
        } else {
          return [0, 5]
        }
      }
    }, [sourceBiomarker])

  return (
    <div
      className={clsx(
        classes.resultCardBackgroundAndBorder,
        "flex flex-row-reverse items-center justify-center py-3 px-1 rounded-lg cursor-pointer"
      )}
      onClick={() => {
        biomarkerDetailModal.show({
          biomarkerId: sourceBiomarker.id,
          biomarkerShortName: sourceBiomarker.short_name,
          biomarkerLongName: sourceBiomarker.long_name,
          biomarkerDescription: sourceBiomarker.description,
          biomarkerShortDescription: sourceBiomarker.short_description,
          biomarkerLowDescription: sourceBiomarker.low_description,
          biomarkerHighDescription: sourceBiomarker.high_description,
          biomarkerLowSymptoms: sourceBiomarker.low_symptoms,
          biomarkerHighSymptoms: sourceBiomarker.high_symptoms,
          optimalRangeMin: result.optimal_range_min,
          optimalRangeMax: result.optimal_range_max,
          standardRangeMin: result.standard_range_min,
          standardRangeMax: result.standard_range_max,
          value: result.display_value || undefined,
          alternateValue: result.alternate_value,
          unit: result.unit,
          status: biomarkerStatus,
          isRangeValue: result.is_range_value,
          clinicName: clinicName || "Practitioner's Clinic",
          resultsOverTime: sourceBiomarker.results.slice(
            resultsOverTimeStartingIndex,
            resultsOverTimeEndingIndex
          ),
          resultsGroupedBy: groupedBy,
          activeResultDate: result.created_at,
          labCompanyName: result.lab_company_name,
          onClose: () => biomarkerDetailModal.hide(),
          showHighLowDescriptions,
        })
      }}
    >
      {!isNeutralValueDisplay && (
        <BloodLabDashboardsBiomarkerStatusIndicator
          biomarkerStatus={biomarkerStatus}
        />
      )}
      <div className="flex flex-row-reverse g-0 ml-1 items-end">
        <BodyText weight="semibold" className={classes.resultCardValueText}>
          {result.display_value ?? result.alternate_value}
        </BodyText>

        <BodyText size="xs" className={classes.resultCardUnitText}>
          {result.unit}
        </BodyText>
      </div>
    </div>
  )
}

export default ResultCard
