import { ReactComponent as ResultsVisualizationIcon } from "app/assets/icons/dashboard/results-visualization.svg"
import { ReactComponent as AirplaneIcon } from "app/assets/images/airplane-blue.svg"
import SpecimenIssueDescription from "app/components/SpecimenIssues/SpecimenIssueDescription"
import { ORDERED_TEST_STATUS } from "app/constants.typed"
import formatDateForHuman from "app/utils/format-date-for-human"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/patient-orders-types"
import { PROGRESS_PERCENTAGES } from "../consts"

const Title = () => {
  return <>Preliminary Results Available</>
}

const SubTitle = ({
  orderedTest,
  patient,
  specimenIssue,
  specimenIssueShowMore,
  setSpecimenIssueShowMore,
}: StatusConfigurationProps) => {
  return (
    <div>
      {orderedTest?.date_results_sent_to_patient && (
        <div>
          <AirplaneIcon
            fill="currentColor"
            height={13}
            width={13}
            viewBox="0 0 18 18"
          />
          <span>
            {` Preliminary results sent to ${
              patient.full_name
            } on ${formatDateForHuman(
              orderedTest.date_results_sent_to_patient
            )}`}
          </span>
        </div>
      )}

      {orderedTest?.date_results_expected_from_lab && (
        <div>
          <ResultsVisualizationIcon
            fill="currentColor"
            height={13}
            width={13}
            viewBox="0 0 18 18"
          />
          <span>
            {` Final results are expected by ${formatDateForHuman(
              orderedTest.date_results_expected_from_lab
            )}`}
          </span>
        </div>
      )}

      {specimenIssue && (
        <SpecimenIssueDescription
          description={specimenIssue.description}
          showMore={specimenIssueShowMore}
          setShowMore={setSpecimenIssueShowMore}
        />
      )}
    </div>
  )
}

const LastUpdatedAt = ({ orderedTest }: StatusConfigurationProps) => {
  const lastUpdatedAt =
    orderedTest.date_results_received_from_lab || orderedTest.updated_at
  return <>{formatDateForHuman(lastUpdatedAt)}</>
}

const preliminaryResultsInStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: {
      orderedTestStatus: ORDERED_TEST_STATUS.PRELIMINARY_RESULTS_AVAILABLE,
    },
    lastUpdatedAt: LastUpdatedAt,
    progressPercentage: PROGRESS_PERCENTAGES.PRELIMINARY_RESULTS_AVAILABLE,
    title: Title,
    subTitle: SubTitle,
  }

export default preliminaryResultsInStatusConfiguration
