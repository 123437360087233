import { ReactNode } from "react"

import axios from "axios"
import { parsePhoneNumberFromString } from "libphonenumber-js"
import _ from "lodash"
import moment from "moment"
import { matchPath } from "react-router-dom"

import * as Sentry from "@sentry/react"

import { ORDER_TERMINOLOGY, orderStatuses } from "app/constants"
import { showMessage } from "app/store/actions/fuse"
import { colors } from "app/theme"

import { UserPaths } from "./Routes/paths"
import { PatientPortalPatientPaths } from "./Routes/paths/patient-portal-paths"
import { PatientPortalUserPaths } from "./Routes/paths/patient-portal-user-paths"
import { isUserPatient, isUserPractitioner } from "./auth/util"
import { isDebug, localExposedBackendUrl } from "./settings"

/**
 * Performs a shallow comparison between two objects to determine if they are equivalent.
 *
 * A shallow comparison only compares direct keys of the object. This comparison is
 * more efficient that a deep comparison, but will return false in cases where a
 * direct property reference changes even if the value is the same.
 *
 * @param {*} value the object
 * @param {*} other the other object
 * @returns {boolean} true if the objects equivalent
 * @example
 *
 * const object = { a: 1 }
 * const other = { a: 1 }
 *
 * _.isShallowEqual(object, other);
 * // => true
 *
 * object === other;
 * // => false
 *
 * @example
 *
 * const object = { a: { b: 1 }}
 * const object = { a: { b: 1 }}
 *
 * _.isShallowEqual(object, other)
 * // => false
 *
 * _.isShallowEqual(object.a, other.a)
 * // => true
 */
export function isShallowEqual(value, other) {
  for (let key in value) {
    if (!(key in other) || value[key] !== other[key]) {
      return false
    }
  }
  for (let key in other) {
    if (!(key in value) || value[key] !== other[key]) {
      return false
    }
  }

  return true
}

export const formatDollars = (value) => {
  const floatValue = parseFloat(value)
  const sign = floatValue < 0 ? "-" : ""
  return `${sign}$${Math.abs(floatValue)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
}

export const getRupaPaymentPlanPrice = (value) => {
  return formatDollars(parseFloat(value) / 3)
}

/**
 * Return a formatted phone number if possible. Otherwise just return the phone number as was passed in.
 * @param phone
 * @returns {*|string}
 */
export const formatPhone = (phone: string | null) => {
  if (!phone) return ""
  const parsedPhoneNumber = parsePhoneNumberFromString(phone, "US")
  return parsedPhoneNumber?.formatNational() ?? phone
}

export const isPractitionerPayingOrder = (order) => order.is_practitioner_paying

export function getOrderStatusMetadata(orderStatus) {
  if (orderStatus in orderStatuses) {
    return {
      ...orderStatuses[orderStatus],
      value: orderStatus,
    }
  }

  return {
    value: orderStatus,
    label: orderStatus,
    background: colors.gray[100],
    text: colors.gray[900],
  }
}

interface ToastProps {
  message: ReactNode
  variant: "success" | "warning" | "error"
  options?: any
}

export const showToast = ({ message, variant, options = {} }: ToastProps) =>
  showMessage({
    message: message,
    variant: variant,
    anchorOrigin: {
      vertical: "bottom", //top bottom
      horizontal: "left", //left center right
    },
    ...options,
  })

export const showSuccessToast = (props: Omit<ToastProps, "variant">) =>
  showToast({ variant: "success", ...props })

export const showErrorToast = (props: Omit<ToastProps, "variant">) =>
  showToast({ variant: "error", ...props })

export const handleApiSuccess = (
  message: ReactNode,
  autoHideDuration = 6000
) => {
  return (dispatch) =>
    dispatch(showSuccessToast({ message, options: { autoHideDuration } }))
}

export class APIError extends Error {
  constructor(message) {
    super(message)
    this.name = "APIError"
  }
}

export const handleApiError = (error: any) => {
  return (dispatch) => {
    if (axios.isCancel(error)) {
      return
    }

    const errorData = error.response && error.response.data
    const errorStatus = error.response && error.response.status

    // Only report 500s to Sentry
    if (errorStatus === 500) {
      Sentry.withScope(function (scope) {
        scope.setExtra("response", JSON.stringify(errorData))

        Sentry.captureException(error)
      })
    }

    let errorMessage: string
    if (errorData?.error && Array.isArray(errorData.error)) {
      errorMessage = errorData.error.join(" ")
    } else if (isNormalizedApiError(errorData)) {
      errorMessage = convertNormalizedApiErrorToString(errorData)
    } else if (errorData?.detail) {
      errorMessage = errorData.detail
    } else if (
      errorData &&
      !_.isEmpty(errorData) &&
      (!error.response.status as any as number) === 404
    ) {
      errorMessage = convertApiErrorToString(errorData)
    } else {
      errorMessage = error.message
    }
    dispatch(showErrorToast({ message: errorMessage }))
  }
}

const isNormalizedApiError = (errorData) => {
  return Array.isArray(errorData?.errors)
}

const convertNormalizedApiErrorToString = (errorData) => {
  return errorData.errors
    .map((error) => (error.detail ? error.detail : error))
    .join(" ")
}

const convertApiErrorToString = (data) => {
  return _.keys(
    _.mapKeys(data, (key, value) => _.upperCase(value) + ": " + key)
  ).join(" ")
}

export function isNetworkError(err) {
  return !!err.isAxiosError && !err.response
}

export const getApiBaseUrl = () => {
  return getBaseUrl().replace("3000", "8000")
}

export const getBaseUrl = () => {
  const { host, protocol } = window.location
  return `${protocol}//${host}`
}

/**
 * Gets the public URL for the app.
 *
 * Similar to getBaseUrl, but will return a locally exposed URL in dev environmnts.
 * @returns {string} Base URL or locally exposed base URL.
 */
export const getPublicUrl = () => {
  if (isDebug && localExposedBackendUrl) {
    // Local environments can expose an ngrok URL for public webhook calls
    return localExposedBackendUrl
  }
  return getBaseUrl()
}

/**
 * Gets the normalized API base url.
 *
 * @returns the url base
 */
export const getNormalizedApiUrl = () => {
  return `${getApiBaseUrl()}/api/normalized`
}

/**
 * @param pathname: The pathname to check.
 * @returns - True if the path can be navigated to after successful login.
 */
export const isNextPathAllowed = (realPath, user) => {
  const patientAndPractitionerPaths = [
    ...Object.values(UserPaths),
    Object.values(PatientPortalPatientPaths),
    Object.values(PatientPortalUserPaths),
  ]

  // If the user is only a patient and not a prac, we only want to allow redirects to patient portal urls
  if (isUserPatient(user) && !isUserPractitioner(user)) {
    return [
      ...Object.values(PatientPortalPatientPaths),
      Object.values(PatientPortalUserPaths),
    ].some((path) => matchPath(realPath, { path, exact: true }))
  }

  return patientAndPractitionerPaths.some((path) =>
    matchPath(realPath, { path, exact: true })
  )
}

/**
 * Converts dateTimeString to month-name date, year
 * @param {*} dateTimeString - The dateTimeString
 * @returns - Formatted date in month-name date, year format eg: July 12, 2021
 */
export const formatDate = (dateTimeString) => {
  return moment(dateTimeString).format("MMMM DD, YYYY")
}

/**
 * Creates a script element to load a dynamic script into the DOM.
 * @param scriptId: The ID that should be set on the script element
 * @param url: URL for the third-party library being loaded.
 * @param callback: An optional callback function that will be called once the script is loaded. If the script is
 * already loaded the callback is called instantly.
 */
export const loadScript = (scriptId, url, callback) => {
  const existingScript = document.getElementById(scriptId)

  if (!existingScript) {
    const script = document.createElement("script")
    script.src = url
    script.id = scriptId
    document.body.appendChild(script)

    script.onload = () => {
      if (callback) {
        callback()
      }
    }
  }

  if (existingScript && callback) {
    callback()
  }
}

/**
 * Compares two version strings and returns true if versionA is greater than version B.
 * A version string can look like: '12', '1.3', '1.2.53'
 * @param versionA
 * @param versionB
 * @returns boolean
 */
export const compareVersions = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g)

  const versionsB = versionB.split(/\./g)
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift())

    const b = Number(versionsB.shift())
    // eslint-disable-next-line no-continue
    if (a === b) continue
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b)
  }
  return false
}

/*
 * On mobile safari, 100vh doesn't work because Safari includes the app toolbar within
 * that height. So setting anything too 100vh will be clipped. To fix this we need to set
 * the height (or min-height) to -webkit-fill-available. But if we did that for all browsers
 * it breaks on Chrome. So we use @support to target a property only supported on mobile safari.
 */
export const safariFullViewportHeightHack = (property) => ({
  "@supports (-webkit-touch-callout: none)": {
    [property]: "-webkit-fill-available",
  },
})

/**
 * Bundle utils
 */

// Generate the public, shareable link for share bundles
export const generateShareLink = (sharebundleId) => {
  return `${getPublicUrl()}/bundle/${sharebundleId}`
}

// Append "Bundle" if the bundle does not have bundle (case-insensitive) in the name.
export const bundleDisplayName = (bundleName) =>
  bundleName.toLocaleLowerCase().match(/\bbundle\b/)
    ? bundleName
    : `${bundleName.trim()} Bundle`

/**
 * Finds age from dob
 * @param {*} dob in date format
 * @returns age
 */
export const getAge = (dob) => {
  return moment().diff(dob, "years", false)
}

/**
 * Gets the appropriate label text for either a normal order, or Physician Services cart.
 * @param practitioner the ordering practitioner
 * @returns a string representing the label text for starting a draft order or physician services cart.
 */
export const getStartDraftText = (practitioner) => {
  if (!practitioner?.order_terminology) {
    return null
  }
  return practitioner.order_terminology === ORDER_TERMINOLOGY.ORDER
    ? "Start an Order"
    : "Start a Cart"
}

/**
 * Gets the appropriate label text for either a normal order, or Physician Services cart.
 * @param practitioner the ordering practitioner
 * @returns a string representing the label text for starting a draft order or cart.
 */
export const getStartDraftTextFromIntention = (practitioner) => {
  if (!practitioner?.physician_services_intention) {
    return null
  }
  return practitioner.physician_services_intention === "self_credential"
    ? "Start an Order"
    : "Start a Cart"
}

/**
 * Gets the appropriate label text for starting a new order or physician services cart for a patient
 * @param practitioner the ordering practitioner
 * @param patient_name the patient name
 * @returns a string representing the label text for a new draft order or cart.
 */
export const getNewDraftText = (practitioner, patient_name) => {
  if (!practitioner?.order_terminology) {
    return null
  }
  return `New ${practitioner.order_terminology} for ${patient_name}`
}

/**
 * Gets the appropriate label text for creating a new order or physician services cart
 * @param practitioner the ordering practitioner
 * @returns a string representing the label text for a new draft order or cart.
 */
export const getCreatingNewText = (practitioner) => {
  if (!practitioner?.order_terminology) {
    return null
  }
  return `Creating New ${practitioner.order_terminology}`
}

export const downloadFileByUrl = (url) => {
  const link = document.createElement("a")
  link.href = url
  link.download = url.substr(url.lastIndexOf("/") + 1)
  link.click()
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
