import { Checkbox } from "@material-ui/core"
import { cn } from "@rupahealth/design"

import { ReactComponent as CheckIcon } from "app/assets/icons/check-circle.svg"
import { ReactComponent as CircleIcon } from "app/assets/icons/empty-circle.svg"
import Tooltip from "app/components/Tooltip"
import { primaryColor, white } from "app/theme"
import { UserClinicAccessSharingStatus } from "app/types"

import ClinicDocumentAccessDocuments from "./ClinicDocumentAccessDocuments"
import ClinicDocumentAccessFutureSharing from "./ClinicDocumentAccessFutureSharing"
import { useClinicDocumentAccessModalContext } from "./ClinicDocumentAccessModalProvider"

const ClinicDocumentAccessOption = ({
  optionSharingStatus,
  text,
  disabledReason,
}: {
  optionSharingStatus: UserClinicAccessSharingStatus
  text: string
  disabledReason?: string
}) => {
  const { sharingStatus, setSharingStatus, documents } =
    useClinicDocumentAccessModalContext()

  const onClick = () => {
    if (disabled) return

    setSharingStatus(optionSharingStatus)
  }

  const disabled =
    optionSharingStatus === UserClinicAccessSharingStatus.SPECIFIC &&
    documents.length === 0

  const selected = sharingStatus === optionSharingStatus

  return (
    <div
      className={cn("my-2 p-1 rounded-xl border cursor-pointer", {
        "border-slate-300 bg-white opacity-50 cursor-not-allowed": disabled,
        "border-primary bg-blue-50": selected && !disabled,
        "border-slate-300 hover:border-primary bg-white":
          !selected && !disabled,
      })}
      onClick={onClick}
    >
      <Tooltip
        interactive
        title={disabled ? disabledReason ?? "" : ""}
        placement="bottom"
        arrow
      >
        <div className="flex flex-row">
          <Checkbox
            icon={
              <CircleIcon
                height={20}
                width={20}
                fill={white}
                className="border-slate-300 border rounded-full"
              />
            }
            checkedIcon={
              <CheckIcon
                fill={primaryColor}
                viewBox="0 0 14 14"
                width={20}
                height={20}
              />
            }
            checked={selected}
            disabled={disabled}
          />
          <p
            className={cn("self-center font-semibold", {
              "text-primary": selected,
            })}
          >
            {text}
          </p>
        </div>
      </Tooltip>
      {optionSharingStatus === UserClinicAccessSharingStatus.SPECIFIC &&
        selected && (
          <div className="bg-white m-2 p-4">
            <ClinicDocumentAccessDocuments />
            <ClinicDocumentAccessFutureSharing />
          </div>
        )}
    </div>
  )
}

export default ClinicDocumentAccessOption
