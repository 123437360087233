import { useCallback } from "react"

import { Grid } from "@material-ui/core"
import { Skeleton } from "@rupahealth/design"

import BodyText from "app/components/design-system/BodyText"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import { ResultsUploadButton } from "app/main/results-upload-v2/ResultsUploadButton"
import { Patient } from "app/types"
import {
  PatientDocument,
  PatientDocumentType,
} from "types/patient-with-documents"

import ResultVisualiztionNotifications from "../trends/components/ResultVisualizationNotifications"
import DocumentTypeSelect from "./DocumentTypeSelect"
import FoodPlanCreateButton from "./FoodPlanCreateButton"
import FoodPlanDocumentCard from "./FoodPlanDocumentCard"
import OrderedResultDocumentCard from "./OrderedResultDocumentCard"
import PatientDocumentsListEmpty from "./PatientDocumentsListEmpty"
import PatientResultDocumentCard from "./PatientResultDocumentCard"
import ResultsSummaryDocumentCard from "./ResultsSummaryDocumentCard"
import { usePatientDocuments } from "./hooks/use-patient-documents"

interface Props {
  patient: Patient
}

const PatientDocumentsList: React.FC<Props> = ({ patient }) => {
  const [isResultsOverTimeManualUploadEnabled] = useFeatureFlag(
    FeatureFlag.ResultsOverTimeManualResultsUpload
  )
  const [showResultVisualizationNotifications] = useFeatureFlag(
    FeatureFlag.ShowResultVisualizationNotifications
  )

  const {
    data: sortedPatientDocuments,
    isLoading,
    totalDocumentCount,
    selectedDocumentType,
    onCreateFoodPlan,
    onDocumentDelete,
  } = usePatientDocuments({ patient })

  const DocumentCard = useCallback(
    (document: PatientDocument) => {
      switch (document.type) {
        case PatientDocumentType.FOOD_PLAN:
          return (
            <FoodPlanDocumentCard
              patient={patient}
              foodPlan={document}
              onDelete={onDocumentDelete}
            />
          )
        case PatientDocumentType.ORDERED_RESULT:
          return <OrderedResultDocumentCard orderedResult={document} />
        case PatientDocumentType.RESULTS_SUMMARY:
          return (
            <ResultsSummaryDocumentCard
              resultsSummary={document}
              onDelete={onDocumentDelete}
            />
          )
        case PatientDocumentType.PATIENT_RESULT:
          return (
            <PatientResultDocumentCard
              patientResult={document}
              onDelete={onDocumentDelete}
            />
          )
      }
    },
    [onDocumentDelete, patient]
  )

  return (
    <div className="flex flex-col gap-5">
      {showResultVisualizationNotifications && (
        <ResultVisualiztionNotifications patientId={patient.id} />
      )}
      <div className="rounded-lg p-7 bg-white shadow mb-2">
        <div className="flex md:flex-row flex-col md:justify-between md:items-center max-md:gap-2 mb-4 pb-2 border-b-[1px] border-slate-200">
          <div>
            <BodyText size="lg" weight="semibold">
              {patient.first_name}'s Documents
            </BodyText>
          </div>
          <div className="max-md:justify-start max-md:-ml-1 md:-mr-1 flex items-center">
            <FoodPlanCreateButton onCreate={onCreateFoodPlan} />
            {isResultsOverTimeManualUploadEnabled && (
              <>
                {/* Divider */}
                <div className="bg-slate-200 h-[25px] w-[2px] mx-2"></div>
                <ResultsUploadButton patientId={patient.id} />
              </>
            )}
          </div>
        </div>

        {(isLoading || totalDocumentCount > 0) && (
          <div className="mb-4">
            <DocumentTypeSelect disabled={isLoading} />
          </div>
        )}

        {isLoading ? (
          <LoadingGrid />
        ) : sortedPatientDocuments.length ? (
          <Grid container spacing={2}>
            {sortedPatientDocuments.map((document) => (
              <Grid item key={document.id} xs={12} sm={12} md={6} lg={4}>
                {DocumentCard(document)}
              </Grid>
            ))}
          </Grid>
        ) : (
          <PatientDocumentsListEmpty
            patientFirstName={patient.first_name}
            totalDocumentCount={totalDocumentCount}
            selectedDocumentType={selectedDocumentType}
          />
        )}
      </div>
    </div>
  )
}

const LoadingGrid = () => {
  return (
    <Grid container spacing={2}>
      {Array.from(Array(6).keys()).map((idx) => (
        <Grid item key={idx} xs={12} sm={12} md={6} lg={4}>
          <div className="flex items-center rounded-lg p-3 gap-3.5">
            <Skeleton className="h-[110px] min-w-[90px]" />
            <div className="space-y-5 w-full">
              <Skeleton className="h-4 w-2/5" />
              <div className="space-y-2">
                <Skeleton className="h-4 w-3/4" />
                <Skeleton className="h-3 w-1/2" />
              </div>
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  )
}

export default PatientDocumentsList
