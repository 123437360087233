import { useEffect } from "react"

import { faXmark } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@rupahealth/design"

import Tooltip from "app/components/Tooltip"
import { RupaCircularProgress } from "app/components/design-system/RupaCircularProgress"

interface Props {
  percentComplete: number
  name: string
  onRemove: () => void
  onComplete: () => Promise<void>
}

export const ResultInProgressPill: React.FC<Props> = ({
  percentComplete,
  name,
  onRemove,
  onComplete,
}) => {
  useEffect(() => {
    const complete = async () => {
      await onComplete()
      onRemove()
    }

    if (percentComplete === 100) complete()
  }, [percentComplete])

  return (
    <div className="flex flex-nowrap items-center gap-2 w-full sm:w-52 bg-white border border-slate-200 shadow-sm rounded-md py-1.5 px-2 text-sm">
      <RupaCircularProgress
        value={percentComplete || 5}
        variant={percentComplete === 100 ? "indeterminate" : "determinate"}
        size={18}
        thickness={7}
      />
      <Tooltip
        className="min-w-0 overflow-hidden whitespace-nowrap overflow-ellipsis"
        title={name}
        arrow
      >
        <span>{name}</span>
      </Tooltip>
      <div className="ml-auto flex items-center justify-center">
        <Button
          variant="ghost"
          size="icon"
          className="h-4 w-4 rounded-sm"
          onClick={onRemove}
        >
          <FontAwesomeIcon
            icon={faXmark}
            className="text-slate-400 text-xl19"
          />
        </Button>
      </div>
    </div>
  )
}
