import { useHistory } from "react-router-dom"

import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ColumnDef, createColumnHelper, Row } from "@tanstack/react-table"

import RupaButton from "app/components/design-system/Button"
import { ResourceRelationshipSingle } from "app/swr/types"
import formatDateForHuman from "app/utils/format-date-for-human"
import { DashboardPatient } from "types/dashboard/dashboard-patient"

const columnHelper = createColumnHelper<DashboardPatient>()

// This could probably be used in the DashboardOrdersTable as well, but need to fix some typing
const PatientNameCell = ({ row }: { row: Row<DashboardPatient> }) => {
  const history = useHistory()

  return (
    <div
      onClick={(e) => {
        e.preventDefault()
        history.push(`/patient/${row.original.id}`)
      }}
      className="w-fit hover:cursor-pointer font-semibold text-base15"
    >
      {row.original.attributes.full_name || "---"}{" "}
      <FontAwesomeIcon className="ml-1 text-sm" icon={faChevronRight} />
    </div>
  )
}

export const PatientNameColumn: ColumnDef<
  DashboardPatient,
  ResourceRelationshipSingle
> = columnHelper.display({
  id: "name",
  header: "NAME",
  cell: ({ row }) => <PatientNameCell row={row} />,
})

export const PatientEmailColumn: ColumnDef<DashboardPatient, any> =
  columnHelper.display({
    id: "email",
    header: "EMAIL",
    cell: ({ row }) => (
      <span className="font-semibold text-base15">
        {row.original.attributes.email}
      </span>
    ),
  })

export const PatientBirthdateColumn: ColumnDef<DashboardPatient, any> =
  columnHelper.display({
    id: "birthdate",
    header: "BIRTHDATE",
    cell: ({ row }) => (
      <div className="whitespace-nowrap">
        {formatDateForHuman(row.original.attributes.birthday || "")}
      </div>
    ),
  })

export const PatientDetailsButtonColumn: ColumnDef<DashboardPatient, any> =
  columnHelper.display({
    id: "details_button",
    cell: ({ row }) => (
      <div className="text-right">
        <RupaButton color="secondary" href={`/patient/${row.original.id}`}>
          Details
        </RupaButton>
      </div>
    ),
  })
