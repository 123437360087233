import { useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { Box, CircularProgress, makeStyles } from "@material-ui/core"

import DesignSystemButton from "app/components/design-system/Button"
import AutoUpload from "app/components/upload/AutoUpload"
import Dropzone from "app/components/upload/dropzone/Dropzone"
import { Theme } from "app/theme"
import {
  capitalizeFirstLetter,
  showErrorToast,
  showSuccessToast,
} from "app/utils"

enum UploadStatus {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  PENDING = "PENDING",
}

const LOGO_ALLOWED_FILE_TYPES = ".jpg,.jpeg,.png,.gif,.svg,.webp"

const styles = (theme: Theme) => ({
  container: {
    display: "flex",
    "flex-direction": "row",
    [theme.breakpoints.down("md")]: {
      "flex-direction": "column",
    },
    width: "100%",
    marginTop: 15,
  },
  uploadContainer: {
    width: "30%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginRight: 0,
    },
    marginRight: 24,
  },
  replaceRemoveLogoContainer: {
    display: "flex",
    "flex-direction": "row",
    width: "70%",
    alignSelf: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: 8,
      justifyContent: "end",
    },
  },
  uploadLoader: {
    marginRight: 4,
  },
  imageLogo: {
    width: "100%",
    marginTop: 4,
    padding: 40,
  },
  divider: {
    marginLeft: 8,
    marginRight: 8,
    color: "#2B455F7A",
    fontWeight: 600,
  },
})

const useStyles = makeStyles(styles)

interface Props {
  uploadUrl: string
  logoUrl: string | null
  onLogoUploadSuccess: (object) => void
  onRemoveLogo: () => void
  subject?: "logo" | "photo"
}

const PhotoAutoUpload = ({
  uploadUrl,
  logoUrl,
  onLogoUploadSuccess,
  onRemoveLogo,
  subject = "logo",
}: Props) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const [uploadStarted, setUploadStarted] = useState(false)
  const [uploadStatus, setUploadStatus] = useState<UploadStatus | null>(null)

  const handleLogoUploadFail = () => {
    setUploadStatus(UploadStatus.FAILED)
    setUploadStarted(false)
    dispatch(
      showErrorToast({
        message: `Failed to upload ${subject}. Please try again.`,
      })
    )
  }

  const handleLogoUploadSuccess = (responses) => {
    const response = JSON.parse(responses[0])
    onLogoUploadSuccess(response)
    setUploadStatus(UploadStatus.SUCCESS)
    setUploadStarted(false)
    dispatch(
      showSuccessToast({
        message: `Successfully uploaded ${subject}`,
        options: { autoHideDuration: 5000 },
      })
    )
  }

  const handleLogoUploadStart = () => {
    setUploadStatus(UploadStatus.PENDING)
    setUploadStarted(true)
  }

  const removeLogo = () => {
    setUploadStatus(null)
    onRemoveLogo()
  }

  const hideUploadLogo = useMemo(() => {
    return uploadStarted || uploadStatus === UploadStatus.SUCCESS || !!logoUrl
  }, [uploadStarted, uploadStatus, logoUrl])

  return (
    <Box className={classes.container}>
      <Box className={classes.uploadContainer}>
        <AutoUpload
          fileContentFieldName="file"
          handleUploadFail={handleLogoUploadFail}
          handleUploadStart={handleLogoUploadStart}
          handleUploadSuccess={handleLogoUploadSuccess}
          uploadUrl={uploadUrl}
          allowedFileTypes={LOGO_ALLOWED_FILE_TYPES}
        >
          {(props) => (
            <Dropzone
              {...props}
              disabled={uploadStarted || uploadStatus === UploadStatus.SUCCESS}
              children={
                uploadStarted ? (
                  <>
                    <CircularProgress
                      ingaria-label="Loading"
                      size={16}
                      className={classes.uploadLoader}
                    />
                    {" Uploading"}
                  </>
                ) : Boolean(
                    uploadStatus === UploadStatus.SUCCESS || logoUrl
                  ) ? (
                  <img
                    src={logoUrl ?? undefined}
                    alt={subject}
                    className={classes.imageLogo}
                  />
                ) : (
                  `Upload ${capitalizeFirstLetter(subject)}`
                )
              }
              hideUploadLogo={hideUploadLogo}
            />
          )}
        </AutoUpload>
      </Box>
      {Boolean(
        uploadStatus === UploadStatus.SUCCESS ||
          uploadStatus === UploadStatus.PENDING ||
          logoUrl
      ) && (
        <Box className={classes.replaceRemoveLogoContainer}>
          <AutoUpload
            fileContentFieldName="file"
            handleUploadFail={handleLogoUploadFail}
            handleUploadStart={handleLogoUploadStart}
            handleUploadSuccess={handleLogoUploadSuccess}
            uploadUrl={uploadUrl}
            allowedFileTypes={LOGO_ALLOWED_FILE_TYPES}
            disabled={uploadStarted}
          >
            <DesignSystemButton
              color="text"
              disabled={uploadStatus === UploadStatus.PENDING}
            >
              Replace
            </DesignSystemButton>
          </AutoUpload>
          <div className={classes.divider}>|</div>
          <DesignSystemButton
            color="destructiveText"
            onClick={removeLogo}
            disabled={uploadStatus === UploadStatus.PENDING}
          >
            Remove
          </DesignSystemButton>
        </Box>
      )}
    </Box>
  )
}

export default PhotoAutoUpload
