import Tooltip from "app/components/Tooltip"
import DesignSystemButton from "app/components/design-system/Button"

import { CategoryValidation } from "../order-history/ConfirmOrderForm"
import { LargeOrderWarning } from "../order-history/LargeOrderWarning"
import { InOfficeKitLabCompanyType } from "../types"

interface Props {
  buttonText: string
  loading?: boolean
  cannotOrderWarning?: string
  onSubmit: () => Promise<void> | void
  secondaryButtonText?: string
  onSecondaryClick?: () => void
  selectedLabCompany?: InOfficeKitLabCompanyType
  categoriesOverMax?: CategoryValidation[]
  isOverValidOrderQuantities?: boolean
  setIsOverValidOrderQuantities?: (isOverValidOrderQuantities: boolean) => void
}
const FormFooter = ({
  buttonText,
  loading = false,
  cannotOrderWarning,
  onSubmit,
  secondaryButtonText,
  onSecondaryClick,
  selectedLabCompany,
  categoriesOverMax,
  isOverValidOrderQuantities,
  setIsOverValidOrderQuantities,
}: Props) => {
  return (
    <div className="flex flex-row-reverse items-center justify-start w-full bg-white mt-4 border-t-slate-300">
      {cannotOrderWarning ? (
        <Tooltip title={cannotOrderWarning}>
          <span className="p-4">
            <DesignSystemButton
              variant="contained"
              color="primary"
              type="submit"
              disabled
              loading={loading}
              size="xsmall"
              onClick={onSubmit}
            >
              {buttonText}
            </DesignSystemButton>
          </span>
        </Tooltip>
      ) : (
        <div className="flex flex-col gap-4">
          {!!categoriesOverMax?.length &&
            !!selectedLabCompany &&
            !!setIsOverValidOrderQuantities &&
            isOverValidOrderQuantities !== undefined && (
              <LargeOrderWarning
                categoriesOverMax={categoriesOverMax}
                selectedLabCompany={selectedLabCompany}
                isOverValidOrderQuantities={isOverValidOrderQuantities}
                setIsOverValidOrderQuantities={setIsOverValidOrderQuantities}
              />
            )}
          <div className="flex flex-row-reverse items-center justify-start w-full p-4 border-t-slate-300 gap-2">
            <DesignSystemButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={isOverValidOrderQuantities}
              loading={loading}
              size="xsmall"
              onClick={onSubmit}
            >
              {buttonText}
            </DesignSystemButton>
            {secondaryButtonText && (
              <DesignSystemButton
                variant="contained"
                color="secondary"
                size="xsmall"
                onClick={onSecondaryClick}
              >
                {secondaryButtonText}
              </DesignSystemButton>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default FormFooter
