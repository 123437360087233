import { TableRow, TableCell } from "@material-ui/core"

import { BaseTableProps } from "./RupaTable"

interface Props<TData> extends BaseTableProps<TData> {
  title?: string | JSX.Element
}

function TableEmptyRow<TData>({ table, title }: Props<TData>) {
  return (
    <TableRow>
      <TableCell
        align="center"
        className="px-2 py-8 break-words"
        colSpan={table.getAllColumns().length}
      >
        <div className="font-semibold text-base15">{title || "No results"}</div>
      </TableCell>
    </TableRow>
  )
}

export default TableEmptyRow
