import { ClinicAccessModalDocument } from "app/types"

import ClinicDocumentAccessDocument from "./ClinicDocumentAccessDocument"

export interface ClinicDocumentAccessDocumentsSectionProps {
  title: string
  documents: ClinicAccessModalDocument[]
}

const ClinicDocumentAccessDocumentsSection = ({
  title,
  documents,
}: ClinicDocumentAccessDocumentsSectionProps) => {
  if (documents.length === 0) return null
  return (
    <div className="flex flex-col gap-4">
      <p className="font-bold text-blue-gray-500 text-sm">{title}</p>
      {documents.map((document) => (
        <ClinicDocumentAccessDocument key={document.id} document={document} />
      ))}
      <div className="bg-gray-200 h-[1px] w-full" />
    </div>
  )
}

export default ClinicDocumentAccessDocumentsSection
