import { useMemo } from "react"

import { PaginationState } from "@tanstack/react-table"

import { convertPaginationToParams } from "app/dashboard/utils"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { DashboardPatient } from "types/dashboard/dashboard-patient"

interface PatientsCollectionFilters {
  search?: string
  orderType?: "storefront" | "non-storefront"
  idIn?: string
}

interface Props {
  orderBy?: string
  filters?: PatientsCollectionFilters
  pagination?: PaginationState
}

function usePatientsCollection({
  filters,
  orderBy = "first_name,last_name",
  pagination,
}: Props) {
  const params = useMemo(() => {
    let params = { order_by: orderBy }
    if (filters?.search) {
      params["filter[search]"] = filters.search
    }
    if (filters?.orderType) {
      params["filter[order_type]"] = filters.orderType
    }
    if (filters?.idIn) {
      params["filter[id.in]"] = filters.idIn
    }
    if (pagination) {
      params = { ...params, ...convertPaginationToParams(pagination) }
    }
    return params
  }, [filters, pagination, orderBy])

  const { data, ...swrProps } = useCollectionSWR("/dashboard/patients/", {
    params,
  })

  const patients = useCachedCollection<DashboardPatient>(data)

  return {
    patients,
    ...swrProps,
  }
}

export default usePatientsCollection
