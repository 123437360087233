import { TableRow, TableCell } from "@material-ui/core"
import { flexRender } from "@tanstack/react-table"

import { BaseTableProps } from "./RupaTable"

function TableHeaderRow<TData>({ table }: BaseTableProps<TData>) {
  return (
    <>
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <TableCell
              key={header.id}
              className="break-words text-slate-500 text-sm13 font-semibold py-[10px]"
              colSpan={header.colSpan}
              style={{
                ...header.column.columnDef.meta?.cellStyle,
                ...header.column.columnDef.meta?.headerCellStyle,
                maxWidth: header.column.columnDef.maxSize,
                minWidth: header.column.columnDef.minSize,
                width: header.getSize(),
              }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}

export default TableHeaderRow
