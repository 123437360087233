import { useState } from "react"
import { useDispatch } from "react-redux"

import useEventCallback from "app/hooks/use-event-callback"
import useAppSelector from "app/hooks/useAppSelector"
import { replaceBundle } from "app/store/actions/practitionerBundles.actions"
import * as Actions from "app/store/actions/shareBundles.actions"
import { PractitionerLabTestBundle } from "app/types"
import { showSuccessToast } from "app/utils"

/**
 * Provide bundles a set of mutators for sharing and unsharing a bundle with their clinic.
 *
 * @returns mutators to share and unshare a bundle with a clinic and the loading state of the requests.
 * @example
 *
 * const { shareWithClinic, clinicShareIsLoading } = useClinicSharedBundles()
 *
 * function onClick(bundle: PractitionerLabTestBundle) {
 *   shareWithClinic(bundle)
 * }
 *
 * <Button onClick={onClick} disabled={clinicShareIsLoading} loading={clinicShareIsLoading}>Share with Clinic</Button>
 */
export default function useClinicSharedBundles() {
  const [clinicShareIsLoading, setClinicShareIsLoading] = useState(false)
  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const dispatch = useDispatch()

  const shareWithClinic = useEventCallback(
    async (bundle: PractitionerLabTestBundle) => {
      setClinicShareIsLoading(true)
      await dispatch(
        Actions.shareBundleWithClinic(bundle.id, async (responseData) => {
          dispatch(replaceBundle(responseData, bundle.lab_tests))
          setClinicShareIsLoading(false)
          dispatch(
            showSuccessToast({
              message: "Bundle successfully shared with Clinic.",
            })
          )
        })
      )
    }
  )

  const unshareWithClinic = useEventCallback(
    async (bundle: PractitionerLabTestBundle) => {
      if (bundle.shared_by !== practitioner.id) {
        dispatch(
          showSuccessToast({
            message:
              "You cannot remove a bundle from the Clinic if you did not create it.",
          })
        )
        return
      }

      setClinicShareIsLoading(true)
      await dispatch(
        Actions.unshareBundleWithClinic(bundle.id, async (responseData) => {
          dispatch(replaceBundle(responseData, bundle.lab_tests))
          setClinicShareIsLoading(false)
          dispatch(
            showSuccessToast({
              message: "Bundle successfully removed from Clinic.",
            })
          )
        })
      )
    }
  )

  return {
    shareWithClinic,
    unshareWithClinic,
    clinicShareIsLoading,
  }
}
