import { useCallback } from "react"
import { useDispatch } from "react-redux"

import { keys, omit, pick } from "lodash"

import { API } from "app/api"
import resourceRequest from "app/swr/utils/resource-request"
import {
  PatientCheckoutFormData,
  PatientCheckoutPayload,
  PaymentMethodDetails,
  PaymentMethodType,
} from "app/types"
import { handleApiError, showErrorToast } from "app/utils"

import CheckoutValidationError from "../errors/CheckoutValidationError"
import { isEmptyErrors, normalizeData } from "../utils/checkout-utils"

const createOrderInsurance = async (
  formData: PatientCheckoutFormData,
  orderId: string,
  checkoutToken: string
) => {
  const orderInsuranceInfo = formData.order_insurance
  if (!orderInsuranceInfo) {
    return
  }
  await resourceRequest({
    url: `/order/patient_insurance/`,
    method: "post",
    data: {
      data: {
        type: "patient_insurance",
        attributes: orderInsuranceInfo,
        relationships: {
          order: { data: { id: orderId, type: "order" } },
        },
      },
    },
    params: {
      token: checkoutToken,
    },
  })
}

/**
 * Submits the patient checkout request using the provided form data and payment method details.
 *
 * @param checkoutToken the checkout token string
 * @returns a promise
 */
export default function useSubmitCheckout(
  checkoutToken: string,
  orderId: string,
  orderHasInsuranceTests: boolean
) {
  const dispatch = useDispatch()
  return useCallback(
    async (
      formData: PatientCheckoutFormData,
      paymentMethod?: PaymentMethodDetails
    ) => {
      const payload = createPatientCheckoutPayload(formData, paymentMethod)

      try {
        if (orderHasInsuranceTests) {
          await createOrderInsurance(formData, orderId, checkoutToken)
        }
        await API.PatientCheckout.post(checkoutToken, normalizeData(payload))
      } catch (error: any) {
        const errorData = error?.response?.data
        const submitErrors = pick(errorData?.errors, keys(formData))

        if (!isEmptyErrors(submitErrors)) {
          throw new CheckoutValidationError(
            "Validation error in checkout submission",
            submitErrors
          )
        } else if (errorData?.errors?.submitError) {
          dispatch(showErrorToast({ message: errorData?.errors?.submitError }))
        } else {
          dispatch(handleApiError(error))
          throw error
        }
      }
    },
    [checkoutToken, orderId, orderHasInsuranceTests]
  )
}

function createPatientCheckoutPayload(
  formData: PatientCheckoutFormData,
  paymentMethod?: PaymentMethodDetails
): PatientCheckoutPayload {
  const payload: PatientCheckoutPayload = {
    ...omit(formData, ["payment_method"]),
  }

  if (paymentMethod) {
    payload.payment_method = { type: paymentMethod.type }

    switch (paymentMethod.type) {
      case PaymentMethodType.CARD: {
        if (paymentMethod.card) {
          payload.payment_method.card = {
            payment_method_id: paymentMethod.card.payment_method_id,
          }
          // @deprecated
          payload.payment_method_id = paymentMethod.card.payment_method_id
        }
        break
      }
      case PaymentMethodType.ACH: {
        if (paymentMethod.ach) {
          payload.payment_method.ach = paymentMethod.ach
        }
        break
      }
    }
  }

  return payload
}
