import { useEffect, useState } from "react"

import { FormProvider, useForm, useFormContext } from "react-hook-form"

import { API } from "app/api"
import { Dialog } from "app/components/modals"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection } from "app/swr/types"
import { Practitioner } from "app/types"

import {
  InOfficeKitLabCompanyType,
  InOfficeKitProduct,
  InOfficeKitProductInventoryConfig,
  InOfficeKitProductInventoryConfigIdentifier,
} from "../types"
import { ConfirmOrderForm } from "./ConfirmOrderForm"
import { StartOrderForm } from "./StartOrderForm"

export interface InOfficeKitOrderFormInputs {
  streetAddress1: string
  streetAddress2: string
  city: string
  state: string
  zipCode: string
  signing_practitioner: string
  [key: string]: string | undefined
}

// All components inside the Modal can use the form context
export const useIOKOrderFormContext = () =>
  useFormContext<InOfficeKitOrderFormInputs>()

export interface StartOrderModalProps {
  open: boolean
  onClose: () => void
  practitioner: Practitioner
  // TODO: type me
  initialValues?: Object
  // Callback for success, used to invalidate caches which results in full refetch.
  // What we want to do: pass the created objects to onSuccess so we can update the cache
  // There are 2 API calls that happen onSubmit:
  // 1. Creates InOfficeKitOrder
  // 2. Creates InOfficeKitOrderedProducts, InOfficeKits, and BotInvocation
  onSuccess?: () => void
}
export const IokStartOrderModal = ({
  open,
  onClose,
  practitioner,
  initialValues,
  onSuccess,
}: StartOrderModalProps) => {
  const methods = useForm<InOfficeKitOrderFormInputs>()
  const clinic = practitioner.clinic

  const { data: iokInventoryConfigIdentifiers } = useCollectionSWR<
    ResourceCollection<InOfficeKitProductInventoryConfigIdentifier>
  >("/in_office_kit_product_inventory_config/")
  const iokInventoryConfigs =
    useCachedCollection<InOfficeKitProductInventoryConfig>(
      iokInventoryConfigIdentifiers
    )

  const [initialFormValues, setInitialFormValues] = useState<
    Object | undefined
  >(initialValues)

  const [selectedPractitioner, setSelectedPractitioner] =
    useState<Practitioner | null>(
      practitioner.has_full_verification_info ? practitioner : null
    )

  const [selectedLabCompany, setSelectedLabCompany] =
    useState<InOfficeKitLabCompanyType | null>(null)

  const { data: iokProductIdentifiers, isLoading: areProductsLoading } =
    useCollectionSWR<ResourceCollection<InOfficeKitProduct>>(
      selectedLabCompany ? "/in_office_kit_product/" : null,
      {
        params: {
          in_office_kit_lab_company__id: selectedLabCompany?.id,
        },
      }
    )
  const iokProducts = useCachedCollection<InOfficeKitProduct>(
    iokProductIdentifiers
  )

  const [verifiedPractitioners, setVerifiedPractitioners] = useState<
    Practitioner[]
  >([])

  useEffect(() => {
    const fetchPractitioners = async () => {
      const response = await API.Clinic.getPractitioners(String(clinic.id))
      // Filter practitioners with full_verification_info set to true
      const verifiedPractitioners = response.data.filter(
        (prac: Practitioner) => prac.has_full_verification_info
      )
      setVerifiedPractitioners(verifiedPractitioners)
    }
    fetchPractitioners()
  }, [])

  const [isConfirming, setIsConfirming] = useState<boolean>(false)

  const onCloseModal = () => {
    methods.reset({
      streetAddress1: clinic.street,
      streetAddress2: "",
      city: clinic.city,
      state: clinic.state,
      zipCode: clinic.zipcode,
    })
    onClose()
  }

  return (
    <FormProvider {...methods}>
      <Dialog
        open={open}
        onClose={onCloseModal}
        maxWidth="sm"
        PaperProps={{
          className: "mx-auto rounded-lg w-[700px] max-h-[75vh] bg-gray-100",
        }}
      >
        {!isConfirming ? (
          <StartOrderForm
            onCloseModal={onCloseModal}
            practitioner={practitioner}
            verifiedPractitioners={verifiedPractitioners}
            iokInventoryConfigs={iokInventoryConfigs}
            initialValues={initialFormValues}
            setInitialValues={setInitialFormValues}
            selectedLabCompany={selectedLabCompany}
            setSelectedLabCompany={setSelectedLabCompany}
            iokProducts={iokProducts}
            areProductsLoading={areProductsLoading}
            selectedPractitioner={selectedPractitioner}
            setSelectedPractitioner={setSelectedPractitioner}
            setIsConfirming={setIsConfirming}
          />
        ) : (
          <ConfirmOrderForm
            onCloseModal={onCloseModal}
            selectedLabCompany={selectedLabCompany as InOfficeKitLabCompanyType}
            iokProducts={iokProducts}
            selectedPractitioner={selectedPractitioner as Practitioner}
            iokInventoryConfigs={iokInventoryConfigs}
            orderFormValues={initialFormValues}
            setOrderFormValues={setInitialFormValues}
            onSuccess={onSuccess}
            setIsConfirming={setIsConfirming}
            methods={methods}
          />
        )}
      </Dialog>
    </FormProvider>
  )
}
