import SpecimenIssueDescription from "app/components/SpecimenIssues/SpecimenIssueDescription"
import { ORDERED_TEST_STATUS } from "app/constants.typed"
import formatDateForHuman from "app/utils/format-date-for-human"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/patient-portal-types"
import { PROGRESS_PERCENTAGES } from "../consts"

const SubTitle = ({
  orderedTest,
  practitioner,
  specimenIssue,
  specimenIssueShowMore,
  setSpecimenIssueShowMore,
}: StatusConfigurationProps) => {
  return (
    <div>
      <span>
        {orderedTest.attributes.lab_results
          ? orderedTest.attributes.date_results_sent_to_patient
            ? `Preliminary results released to you on ${formatDateForHuman(
                orderedTest.attributes.date_results_sent_to_patient
              )}.`
            : `Preliminary results released to you.`
          : `Reach out to ${
              practitioner?.attributes.titled_full_name || "your practitioner"
            } to release your preliminary test results.`}
      </span>
      {specimenIssue && (
        <SpecimenIssueDescription
          description={specimenIssue.attributes.description}
          showMore={specimenIssueShowMore}
          setShowMore={setSpecimenIssueShowMore}
        />
      )}
    </div>
  )
}

const LastUpdatedAt = ({ orderedTest }: StatusConfigurationProps) => {
  const lastUpdatedAt =
    orderedTest.attributes.date_results_received_from_lab ||
    orderedTest.attributes.updated_at
  return <>{formatDateForHuman(lastUpdatedAt)}</>
}

const preliminaryResultsInStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: {
      orderedTestStatus: ORDERED_TEST_STATUS.PRELIMINARY_RESULTS_AVAILABLE,
    },
    lastUpdatedAt: LastUpdatedAt,
    progressPercentage: PROGRESS_PERCENTAGES.PRELIMINARY_RESULTS_AVAILABLE,
    title: "Preliminary Results Available",
    subTitle: SubTitle,
  }

export default preliminaryResultsInStatusConfiguration
