import { useState } from "react"
import { useDispatch } from "react-redux"

import { makeStyles } from "@material-ui/core"

import PlayIcon from "app/assets/icons/play.svg"
import DesignSystemButton from "app/components/design-system/Button"
import {
  PractitionerProfileApplicationStatus,
  PractitionerProfilePublishingStatus,
} from "app/constants.typed"
import resourceRequest from "app/swr/utils/resource-request"
import { colors } from "app/theme"
import { showErrorToast, showSuccessToast } from "app/utils"
import setIn from "app/utils/set-in"

import PractitionerProfileTooltip from "./PractitionerProfileTooltip"
import useSubmitProfileApplication from "./hooks/use-submit-profile-application"
import { PractitionerProfile, PractitionerProfileApplication } from "./types"

const styles = (theme) => ({
  publishButtonDisabled: {
    background: colors.blueGray[400],
    color: "white",
    "&:hover": {
      background: colors.blueGray[400],
      color: "white",
      cursor: "unset",
    },
  },
  tutorialButton: {
    marginTop: theme.spacing(1.0),
  },
})

const useStyles = makeStyles(styles)

interface Props {
  profile: PractitionerProfile | undefined
  mutateProfile: (mutator, options) => Promise<any>
  profileApplication: PractitionerProfileApplication
}

const PractitionerProfilePublishButton = ({
  profile,
  mutateProfile,
  profileApplication,
}: Props) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const { isPatching: isSubmittingApplication, submitProfileApplication } =
    useSubmitProfileApplication()

  async function submitApplication() {
    submitProfileApplication()
    mutateProfile(undefined, undefined)
  }

  async function publishProfile() {
    if (!profile) return

    setLoading(true)

    try {
      await mutateProfile(
        resourceRequest<PractitionerProfile>({
          url: `/practitioner_profiles/${profile.id}/publish/`,
          method: "POST",
        }),
        {
          optimisticData: (currentData) =>
            setIn(
              currentData,
              "attributes.publishing_status",
              PractitionerProfilePublishingStatus.PUBLISHED
            ),
          rollbackOnError: true,
          throwOnError: true,
        }
      )

      dispatch(
        showSuccessToast({
          message: "Your profile has been published.",
          options: { autoHideDuration: 5000 },
        })
      )

      setLoading(false)
    } catch (e) {
      dispatch(
        showErrorToast({
          message:
            "There was an error publishing your profile. Please try again.",
          options: { autoHideDuration: 5000 },
        })
      )

      setLoading(false)
    }
  }

  const profileIsPublished =
    profile?.attributes.publishing_status ===
    PractitionerProfilePublishingStatus.PUBLISHED

  const profileIsComplete = profile?.attributes.has_required_fields

  const applicationIsPending =
    profileApplication?.attributes?.status ===
    PractitionerProfileApplicationStatus.PENDING

  const applicationIsApproved =
    profileApplication?.attributes?.status ===
    PractitionerProfileApplicationStatus.APPROVED

  const profileIsDenied =
    profileApplication?.attributes?.status ===
    PractitionerProfileApplicationStatus.DENIED

  if (applicationIsPending) {
    return (
      <PractitionerProfileTooltip profile={profile}>
        <DesignSystemButton
          color="secondary"
          className={classes.publishButtonDisabled}
          startIcon={PlayIcon}
          disabled
        >
          Published for Review
        </DesignSystemButton>
      </PractitionerProfileTooltip>
    )
  }

  if (applicationIsApproved) {
    return (
      <PractitionerProfileTooltip profile={profile}>
        <DesignSystemButton
          color="success"
          startIcon={PlayIcon}
          onClick={() => publishProfile()}
          loading={loading}
          disabled={loading || profileIsPublished || !profileIsComplete}
        >
          Publish
        </DesignSystemButton>
      </PractitionerProfileTooltip>
    )
  }

  return (
    <PractitionerProfileTooltip profile={profile}>
      <DesignSystemButton
        color={profileIsComplete ? "success" : "secondary"}
        className={!profileIsComplete ? classes.publishButtonDisabled : ""}
        loading={isSubmittingApplication}
        disabled={
          !profileIsComplete || (!!profileApplication && !profileIsDenied)
        }
        startIcon={PlayIcon}
        onClick={() => {
          if (profileIsComplete && (!profileApplication || profileIsDenied)) {
            submitApplication()
          }
        }}
      >
        Publish for Review
      </DesignSystemButton>
    </PractitionerProfileTooltip>
  )
}

export default PractitionerProfilePublishButton
