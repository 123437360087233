import { useState } from "react"

import { CircularProgress } from "@material-ui/core"
import { Button } from "@rupahealth/design"

import TrendsLogo from "app/assets/images/results-over-time/trends-empty.png"
import RupaCheckbox from "app/components/RupaCheckbox"

import { useResultsUpload } from "../hooks/use-results-upload"
import { ResultsUploadFileGroups } from "./ResultsUploadForm"

interface Props {
  patientId: string
  onClose: (noConfirm?: boolean) => void
  fileGroups: ResultsUploadFileGroups
}

export const Step2Confirm: React.FC<Props> = ({
  patientId,
  onClose,
  fileGroups,
}) => {
  const { uploadAndInvokeLabResults } = useResultsUpload({ patientId })

  const [confirmChecked, setConfirmChecked] = useState(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    setLoading(true)

    const fileArrays = Object.values(fileGroups).map((fileOrGroup) => {
      return fileOrGroup instanceof File
        ? [fileOrGroup]
        : Object.values(fileOrGroup)
    })

    await uploadAndInvokeLabResults(fileArrays)

    setLoading(false)
    onClose(true)
  }

  return (
    <div
      className="px-[69px] py-12 rounded-xl flex flex-col items-center gap-5"
      style={{
        background:
          "linear-gradient(184.56deg, #E2F4FC 26.04%, #FFFFFF 90.73%)",
      }}
    >
      <div className="flex flex-col items-center">
        <div className="w-44">
          <img src={TrendsLogo} alt="trends logo" />
        </div>
        <div className="bg-white rounded-lg border border-slate-200 shadow-sm py-3 px-5 flex flex-col gap-3">
          <div className="font-semibold">
            We do our best to automatically extract data from the original
            report.
          </div>
          <hr className="h-[1px] bg-slate-200 w-full" />
          <div
            className="group cursor-pointer flex items-center gap-2"
            onClick={() => setConfirmChecked((prev) => !prev)}
          >
            <RupaCheckbox
              checked={confirmChecked}
              disabled={loading}
              className="rounded-[4px] text-xs p-[2px] pt-[1px]"
            />

            <div>
              I will verify that all values are accurate.{" "}
              <span className="text-destructive">*</span>
            </div>
          </div>
        </div>
      </div>
      <div className="px-1.5 w-full">
        {loading ? (
          <Button
            variant="outline-primary"
            className="w-full rounded-[55px] border-primary bg-light-blue-50 gap-2 cursor-default"
          >
            <CircularProgress size={16} thickness={5} /> Uploading Files...
          </Button>
        ) : (
          <Button
            variant="primary"
            className="w-full"
            disabled={!confirmChecked}
            onClick={onSubmit}
          >
            Import Results
          </Button>
        )}
      </div>
    </div>
  )
}
