import { useHistory } from "react-router-dom"

import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CellContext } from "@tanstack/react-table"
import { HeaderContext } from "@tanstack/react-table"

import BodyText from "app/components/design-system/BodyText"
import DashboardPatientFilter from "app/main/dashboard/components/DashboardPatientFilter"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { DashboardOrder } from "types/dashboard/dashboard-order"
import { DashboardPatient } from "types/dashboard/dashboard-patient"

import columnHelper from "./column-helper"

export const PatientCell = ({
  getValue,
}: CellContext<DashboardOrder, DashboardOrder["relationships"]["patient"]>) => {
  const history = useHistory()
  const patient = useCachedResource<DashboardPatient>(getValue().data)

  const handlePatientNameClick = (event: any) => {
    if (patient) {
      event.preventDefault()
      history.push(`/patient/${patient.id}`)
    }
  }

  return (
    <BodyText
      weight="semibold"
      variant="body1"
      onClick={handlePatientNameClick}
      className="w-fit hover:cursor-pointer"
    >
      {patient?.attributes.full_name || "---"}{" "}
      <FontAwesomeIcon className="ml-1 text-sm" icon={faChevronRight} />
    </BodyText>
  )
}

export const PatientHeader = ({
  column,
}: HeaderContext<
  DashboardOrder,
  DashboardOrder["relationships"]["patient"]
>) => {
  return (
    <DashboardPatientFilter
      onFilterChange={column.setFilterValue}
      value={column.getFilterValue() as string}
    />
  )
}

export const PATIENT_COLUMN_ID = "patient"

const patientColumn = columnHelper.accessor("relationships.patient", {
  id: PATIENT_COLUMN_ID,
  cell: (info) => <PatientCell {...info} />,
  enableColumnFilter: true,
  header: (info) => <PatientHeader {...info} />,
  minSize: 200,
  size: 300,
  meta: {
    cellStyle: {
      paddingLeft: 0,
    },
  },
})

export default patientColumn
