import { createContext, useCallback, useContext, useState } from "react"

import axios from "axios"
import { useSWRConfig } from "swr"

import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import { BiomarkerResultFormData } from "app/main/results-upload/hooks/use-results-upload-manual-entry-form"
import { writeToCache } from "app/swr/helpers/swr"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR, {
  UseCollectionSwrReturn,
} from "app/swr/hooks/use-collection-swr"
import useMutateResource from "app/swr/hooks/use-mutate-resource"
import useResourceSWR, {
  UseResourceSwrReturn,
} from "app/swr/hooks/use-resource-swr"
import {
  ResourceCollection,
  ResourceIdentifier,
  ResourceResponse,
} from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { getNormalizedApiUrl } from "app/utils"
import {
  UserBiomarkerResult,
  UserBiomarkerResultCreate,
  UserBiomarkerResultPostData,
} from "types/user-biomarker-result"
import { UserResult } from "types/user-result"

// Data provided by the context
export interface UserResultContextProps {
  userResult: UserResult | undefined
  userResultSWR: UseResourceSwrReturn<UserResult>

  userBiomarkerResults: UserBiomarkerResult[]
  userBiomarkerResultsSWR: UseCollectionSwrReturn<
    ResourceCollection<UserBiomarkerResult>
  >

  createUserBiomarkerResult(
    payload: UserBiomarkerResultPostData
  ): Promise<UserBiomarkerResult>
  isCreateUserBiomarkerResultLoading: boolean
  updateUserBiomarkerResult(
    id: string,
    payload: BiomarkerResultFormData
  ): Promise<UserBiomarkerResult | undefined>
  onDeleteUserBiomarkerResult(userBiomarkerResultId: string): Promise<void>
  bulkUpdateUserBiomarkerResultsSampleType(sampleTypeId: string): Promise<void>
}

const UserResultContext = createContext<UserResultContextProps>(
  undefined as never
)
interface Props {
  userResultId?: string
  children: React.ReactNode
}

function UserResultProvider({ userResultId, children }: Props) {
  const handleApiError = useHandleApiError()
  const globalConfig = useSWRConfig()
  const mutateResource = useMutateResource()

  const [isCreateLoading, setIsCreateLoading] = useState(false)

  const userResultSWR = useResourceSWR<UserResult>(
    userResultId ? `/user_results/${userResultId}/` : null,
    {
      include: ["lab_company"],
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
    }
  )

  const userBiomarkerResultsSWR = useCollectionSWR<
    ResourceCollection<UserBiomarkerResult>
  >(
    `/user_biomarker_results/`,
    {
      include: ["biomarker"],
      params: {
        "filter[user_result__id]": userResultId,
      },
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
    }
  )

  const mutateUserBiomarkerResult = userBiomarkerResultsSWR.mutate

  const bulkUpdateUserBiomarkerResultsSampleType = useEventCallback(
    async (sampleTypeId: string) => {
      try {
        const userBiomarkerResultsIds = userBiomarkerResultsSWR.data.map(
          (userBiomarkerResult) => userBiomarkerResult.id
        )

        if (!userBiomarkerResultsIds) {
          return
        }
        await axios.post(
          getNormalizedApiUrl() +
            `/user_biomarker_results/bulk_update_sample_type/`,
          {
            ids: userBiomarkerResultsIds,
            sampleTypeId,
          }
        )
        await userBiomarkerResultsSWR.mutate()
      } catch (error) {
        handleApiError(error)
      }
    }
  )

  const userBiomarkerResults = useCachedCollection<UserBiomarkerResult>(
    userBiomarkerResultsSWR.data
  )

  const createUserBiomarkerResult = useCallback(
    async (payload: UserBiomarkerResultPostData) => {
      try {
        setIsCreateLoading(true)

        const relationships = {
          user_result: {
            data: {
              type: "user_result",
              id: payload.userResultId,
            },
          },
          biomarker: {
            data: {
              type: "biomarker",
              id: payload.biomarkerId,
            },
          },
        }

        if (payload.sampleTypeId) {
          relationships["sample_type"] = {
            data: {
              type: "lab_test_type",
              id: payload.sampleTypeId,
            },
          }
        }

        const { data: responseData } = await resourceRequest<
          ResourceResponse<UserBiomarkerResult>,
          UserBiomarkerResultCreate
        >({
          method: "post",
          url: `/user_biomarker_results/`,
          data: {
            data: {
              type: "user_biomarker_result",
              attributes: {
                value: "0.0",
                value_type: "numeric",
              },
              relationships,
            },
          },
        })

        await mutateUserBiomarkerResult(
          async (previousData) => {
            await writeToCache(globalConfig, responseData)

            let newData: UserBiomarkerResult[] = []
            if (!previousData) {
              newData = [responseData]
            } else {
              newData = [...previousData.data, responseData]
            }

            return {
              data: newData,
              meta: {
                count: (previousData?.meta?.count || 0) + 1,
              },
            }
          },
          {
            revalidate: false,
            rollbackOnError: true,
            throwOnError: true,
          }
        )

        setIsCreateLoading(false)

        return responseData
      } catch (error) {
        setIsCreateLoading(false)
        handleApiError(error)

        // re-throw so that the caller can handle the error
        throw error
      }
    },
    [mutateUserBiomarkerResult]
  )

  const _normalizeSampleTypeData = (data: ResourceIdentifier) => {
    if (!data.type) {
      data.type = "lab_test_type"
    }

    return data
  }

  const updateUserBiomarkerResult = useEventCallback(
    async (id: string, payload: BiomarkerResultFormData) => {
      if (!payload.relationships.sample_type?.data?.id) {
        handleApiError(new Error("Sample type is required"))
        return
      } else {
        payload.relationships.sample_type.data = _normalizeSampleTypeData(
          payload.relationships.sample_type.data
        )
      }

      const identifier = { id: id, type: "user_biomarker_result" }

      // React hook form overrides our ids so we need to preserve them here
      payload.id = id

      const sendPatchRequest = async () => {
        try {
          const { data } = await resourceRequest<
            ResourceResponse<UserBiomarkerResult>
          >({
            method: "patch",
            url: `/user_biomarker_results/${id}/`,
            data: {
              data: {
                ...payload,
              },
            },
          })

          return data
        } catch (error) {
          handleApiError(error)
        }
      }

      return mutateResource<UserBiomarkerResult>(identifier, sendPatchRequest, {
        optimisticData(currentData) {
          if (!currentData) {
            // should never be reached, but type error without
            return undefined as any
          }

          return {
            ...currentData,
            attributes: {
              ...currentData.attributes,
              ...payload.attributes,
            },
          }
        },
        rollbackOnError: true,
      })
    }
  )

  const onDeleteUserBiomarkerResult = useEventCallback(
    async (userBiomarkerResultId: string) => {
      try {
        await mutateUserBiomarkerResult(
          async (previousData) => {
            if (!previousData) {
              return previousData
            }

            await resourceRequest({
              url: `/user_biomarker_results/${userBiomarkerResultId}/`,
              method: "delete",
            })

            return {
              data: previousData.data.filter(
                (identifier: ResourceIdentifier) =>
                  identifier.id !== userBiomarkerResultId
              ),
              meta: {
                count: previousData.meta?.count - 1,
              },
            }
          },
          {
            revalidate: false,
            throwOnError: true,
          }
        )
      } catch (error) {
        handleApiError(error)
      }
    }
  )

  return (
    <UserResultContext.Provider
      value={{
        userResult: userResultSWR.data,
        userResultSWR,
        userBiomarkerResults,
        userBiomarkerResultsSWR,
        createUserBiomarkerResult,
        isCreateUserBiomarkerResultLoading: isCreateLoading,
        updateUserBiomarkerResult,
        onDeleteUserBiomarkerResult,
        bulkUpdateUserBiomarkerResultsSampleType,
      }}
    >
      {children}
    </UserResultContext.Provider>
  )
}

export function useUserResultContext() {
  return useContext(UserResultContext)
}

export default UserResultProvider
