import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import SpecimenIssueDescription from "app/components/SpecimenIssues/SpecimenIssueDescription"
import SpecimenIssueRecollectionDetails from "app/components/SpecimenIssues/SpecimenIssueRecollectionDetails"
import { formatSpecimenIssueActionForSubtitle } from "app/components/SpecimenIssues/helpers"
import {
  LAB_COMPANIES_WE_CAN_GENERATE_REQUISITIONS_FOR,
  LAB_COMPANIES_WITH_NEW_RECOLLECTION_REQUISITIONS,
} from "app/constants"
import { ORDERED_TEST_STATUS } from "app/constants.typed"
import formatDateForHuman from "app/utils/format-date-for-human"

import { StatusConfiguration } from "../../types"
import {
  SpecimenIssueProps,
  StatusConfigurationProps,
} from "../../types/patient-portal-types"
import { PROGRESS_PERCENTAGES } from "../consts"

const LastUpdatedAt = ({
  specimenIssue,
  orderedTest,
}: StatusConfigurationProps) => {
  const lastUpdatedAt =
    specimenIssue?.attributes.updated_at || orderedTest.attributes.updated_at
  return <>{formatDateForHuman(lastUpdatedAt)}</>
}

const ActionRequiredSpecimenIssueTitle = () => {
  return (
    <div className="flex flex-row gap-1 items-center">
      <FontAwesomeIcon
        icon={faTriangleExclamation}
        className="text-destructive"
      />
      <span>Specimen Issue</span>
    </div>
  )
}

const ActionRequiredSpecimenIssueSubTitle = ({
  specimenIssue,
  practitioner,
  specimenIssueShowMore,
  setSpecimenIssueShowMore,
}: StatusConfigurationProps) => {
  return (
    <div>
      <span>{`${
        practitioner?.attributes.titled_full_name || "Your practitioner"
      } is taking action to resolve.`}</span>
      {specimenIssue && (
        <SpecimenIssueDescription
          description={
            specimenIssue.attributes.patient_description
              ? specimenIssue.attributes.patient_description
              : specimenIssue.attributes.description
          }
          showMore={specimenIssueShowMore}
          setShowMore={setSpecimenIssueShowMore}
        />
      )}
    </div>
  )
}

const RecollectingSpecimenIssueSubTitle = ({
  specimenIssue,
  specimenIssueShowMore,
  setSpecimenIssueShowMore,
  orderedTest,
  labCompany,
}: StatusConfigurationProps) => {
  const is_requisition_ready =
    orderedTest.attributes.instant_requisition &&
    (!!labCompany &&
    LAB_COMPANIES_WITH_NEW_RECOLLECTION_REQUISITIONS.includes(
      labCompany.attributes.key
    )
      ? !!specimenIssue?.attributes.recollection_requisition
      : Boolean(orderedTest.attributes.requisition) ||
        (!!labCompany &&
          LAB_COMPANIES_WE_CAN_GENERATE_REQUISITIONS_FOR.includes(
            labCompany?.attributes.key
          )))

  return (
    <div className="w-full">
      <div>
        {specimenIssue && (
          <SpecimenIssueDescription
            description={
              specimenIssue.attributes.patient_description
                ? specimenIssue.attributes.patient_description
                : specimenIssue.attributes.description
            }
            showMore={specimenIssueShowMore}
            setShowMore={setSpecimenIssueShowMore}
          />
        )}
      </div>

      <div className="mt-2 pt-2 border-t border-t-slate-200">
        <SpecimenIssueRecollectionDetails
          is_requisition_provided={orderedTest.attributes.instant_requisition}
          is_requisition_ready={is_requisition_ready}
          is_shipped_kit={!orderedTest.attributes.instant_requisition}
          date_recollection_ordered_with_lab={
            specimenIssue?.attributes.date_recollection_ordered_with_lab
          }
          estimated_recollection_arrival_date={
            specimenIssue?.attributes.estimated_recollection_arrival_date
          }
          recollection_shipment_tracking_number={
            specimenIssue?.attributes.recollection_shipment_tracking_number
          }
          date_recollection_sample_received_at_lab={
            specimenIssue?.attributes.date_recollection_sample_received_at_lab
          }
          estimated_recollection_results_date={
            specimenIssue?.attributes.estimated_recollection_results_date
          }
          lab_company_notifies_sample_at_lab={
            labCompany?.attributes.notifies_sample_at_lab
          }
          lab_company_provides_results_eta={
            labCompany?.attributes.provides_results_eta
          }
          lab_company_short_name={labCompany?.attributes.short_name}
        />
      </div>
    </div>
  )
}

const ProcessingSpecimenIssueActionSubTitle = ({
  specimenIssue,
  specimenIssueShowMore,
  setSpecimenIssueShowMore,
}: SpecimenIssueProps) => {
  let subtitle: string
  const formattedActionChosen = formatSpecimenIssueActionForSubtitle(
    specimenIssue?.attributes.action_chosen
  )
  if (formattedActionChosen) {
    subtitle = `We are processing the request to ${formattedActionChosen}.`
  } else {
    subtitle = "We are processing the Specimen Issue."
  }

  if (!specimenIssue) return null

  return (
    <div>
      <span>{subtitle}</span>
      {specimenIssue && (
        <SpecimenIssueDescription
          description={
            specimenIssue.attributes.patient_description
              ? specimenIssue.attributes.patient_description
              : specimenIssue.attributes.description
          }
          showMore={specimenIssueShowMore}
          setShowMore={setSpecimenIssueShowMore}
        />
      )}
    </div>
  )
}

export const actionRequiredSpecimenIssueStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: {
      orderedTestStatus: ORDERED_TEST_STATUS.ACTION_REQUIRED_SPECIMEN_ISSUE,
    },
    progressPercentage: PROGRESS_PERCENTAGES.NEW_SPECIMEN_ISSUE,
    title: ActionRequiredSpecimenIssueTitle,
    subTitle: ActionRequiredSpecimenIssueSubTitle,
    lastUpdatedAt: LastUpdatedAt,
  }

export const recollectingSpecimenIssueStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: { orderedTestStatus: ORDERED_TEST_STATUS.RECOLLECTION_IN_PROGRESS },
    progressPercentage: PROGRESS_PERCENTAGES.RECOLLECTION_IN_PROGRESS,
    title: "Recollection Kit Shipped To You",
    subTitle: RecollectingSpecimenIssueSubTitle,
    lastUpdatedAt: LastUpdatedAt,
  }

export const processingSpecimenIssueStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: {
      orderedTestStatus: ORDERED_TEST_STATUS.PROCESSING_SPECIMEN_ISSUE_ACTION,
    },
    progressPercentage: PROGRESS_PERCENTAGES.PROCESSING_SPECIMEN_ISSUE_ACTION,
    title: "Processing Specimen Issue Action",
    subTitle: ProcessingSpecimenIssueActionSubTitle,
    lastUpdatedAt: LastUpdatedAt,
  }

export const pendingDetailsSpecimenIssueStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: {
      orderedTestStatus: ORDERED_TEST_STATUS.PENDING_DETAILS_SPECIMEN_ISSUE,
    },
    progressPercentage: PROGRESS_PERCENTAGES.NEW_SPECIMEN_ISSUE,
    title: "New Specimen Issue",
    subTitle: "We are gathering details from the lab and will update shortly!",
    lastUpdatedAt: LastUpdatedAt,
  }
