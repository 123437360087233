import { useCallback, useEffect, useState } from "react"

import { loadState, savedStateKey, saveState } from "services/savedState"

export function useLocalStorage<T = string>(
  key: string,
  defaultValue: T
): [T, (value: T | undefined) => void]
export function useLocalStorage<T = string>(
  key: string,
  defaultValue?: T
): [T | undefined, (value: T | undefined) => void] {
  const [storedValue, setStoredValue] = useState(
    () => loadState<T>(key) ?? defaultValue
  )

  // Return a wrapped version of useState's setter that persists the new value to localStorage.
  const setValue = useCallback(
    (newVal: T | undefined) => {
      saveState(key, newVal)
      setStoredValue(newVal)
    },
    [key]
  )

  useEffect(() => {
    const reloadStateValue = (event: StorageEvent) => {
      if (event.key === savedStateKey(key)) {
        setStoredValue(loadState(key) ?? defaultValue)
      }
    }
    window.addEventListener("storage", reloadStateValue)
    return () => window.removeEventListener("storage", reloadStateValue)
  }, [])

  return [storedValue, setValue]
}
