import { useMemo, useState } from "react"

import _ from "lodash"

import { Link, Typography } from "@material-ui/core"
import Divider from "@material-ui/core/Divider"

import { Coupon } from "app/components/Coupon"
import GenovaInsuranceExplanation from "app/components/GenovaInsuranceExplanation"
import Loading from "app/components/Loading"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import {
  LAB_COMPANY_KEY,
  LABCORP_DRAW_SITES_URL,
  QUEST_COMPANY_KEYS,
  QUEST_DRAW_SITES_URL,
} from "app/constants"
import {
  COMBO_GROUP_CARD_KEY_PREFIX,
  groupLineItems,
  LAB_COMPANY_PANELS,
} from "app/dataServices/labTestDataService"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import {
  PatientPortalOrder,
  PatientPortalOrderLineItemLabTest,
} from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import { ORDER_LINE_ITEM_TYPE } from "../../../constants"
import { LabTestLineItem } from "../LabTestLineItem"
import ComboGroupLineItems from "./order-details/ComboGroupLineItems"
import DrawCenterLineItems from "./order-details/DrawCenterLineItems"
import MobileBloodDrawIndicator from "./order-details/MobileBloowDrawIndicator"
import NeedPhlebotomistWarning from "./order-details/NeedPhlebotomistWarning"
import PriceBreakdown from "./order-details/PriceBreakdown"
import ShippedLineItems from "./order-details/ShippedLineItems"
import { CompanyPanel } from "./types"

const CREDIT_CARD_FEE_INFO = (
  <BodyText size="sm">
    In some regions, we charge a fee for accepting credit card payments. This
    fee covers the cost of the payment gateway and merchant services. ACH
    payments are not subject to this fee.
  </BodyText>
)

const LABCORP_BLOOD_DRAW_INFO = (
  <BodyText size="sm">
    Your Labcorp tests require a blood draw. We'll send you an order form that
    you'll need to take to a Labcorp location. You can search Labcorp locations{" "}
    <Link
      href={LABCORP_DRAW_SITES_URL}
      className="font-semibold underline"
      color="textPrimary"
      target="_blank"
      rel="noopener noreferrer"
    >
      here
    </Link>
    .
  </BodyText>
)

const QUEST_BLOOD_DRAW_INFO = (
  <BodyText size="sm">
    Your Quest tests require a blood draw. We'll send you an order form that
    you'll need to take to a Quest location. You can search Quest locations{" "}
    <Link
      href={QUEST_DRAW_SITES_URL}
      className="font-semibold underline"
      color="textPrimary"
      target="_blank"
      rel="noopener noreferrer"
    >
      here
    </Link>
    .
  </BodyText>
)

const shouldShowPrices = (order: PatientPortalOrder) =>
  !order.is_practitioner_paying && parseFloat(order.total_price) > 0

const QUEST_SERVICE_FEE_INFO = (
  <BodyText size="sm">
    Your Quest order is going through Physician Services. The Quest Service fee
    includes the costs for an independent clinician to review your information,
    determine whether testing is appropriate, and provide follow-up on your
    results, plus fees for other administrative and operational support.  The
    Quest Service fee is Non-Refundable after the order has been placed or if
    the order is cancelled.
  </BodyText>
)

// Informational popups for line items
export const LINE_ITEM_INFO_CONFIG = {
  [ORDER_LINE_ITEM_TYPE.CREDIT_CARD_FEE]: {
    info: CREDIT_CARD_FEE_INFO,
  },
  [ORDER_LINE_ITEM_TYPE.LABCORP_BLOOD_DRAW_FEE]: {
    info: LABCORP_BLOOD_DRAW_INFO,
  },
  [ORDER_LINE_ITEM_TYPE.QUEST_SERVICE_FEE]: {
    info: QUEST_SERVICE_FEE_INFO,
  },
  [ORDER_LINE_ITEM_TYPE.QUEST_BLOOD_DRAW_FEE]: {
    info: QUEST_BLOOD_DRAW_INFO,
  },
}

interface OrderDetailsProps {
  checkoutToken: string
  className?: string
  order: PatientPortalOrder
  setCancelModalOpenWithOptions: (options: any) => void
  isOrderBeingUpdated?: boolean
  updatingOrderedTestIds: string[]
  isUpdatingLineItems: boolean
  isUpdatingOrder: boolean
  enableLineItems: (orderedTestIds: string[]) => void
  disableLineItems: (orderedTestIds: string[]) => void
}

const useStyles = makeAppStyles({
  phlebInfoWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
  },
  infoIcon: {
    marginLeft: 4,
  },
  vibrantWarningContainer: {
    marginTop: 10,
  },
  editCartButton: {
    minWidth: 110,
    padding: "10px 20px",
  },
})

export function OrderDetails({
  checkoutToken,
  className,
  order,
  setCancelModalOpenWithOptions,
  isOrderBeingUpdated,
  updatingOrderedTestIds,
  isUpdatingLineItems,
  isUpdatingOrder,
  enableLineItems,
  disableLineItems,
}: OrderDetailsProps) {
  const showPrices = shouldShowPrices(order)
  const classes = useStyles()

  const [isComboGroupsEnabled] = useFeatureFlag(FeatureFlag.ComboGroupsEnabled)
  const [axlePhlebotomyEnabledFlag] = useFeatureFlag(FeatureFlag.AxlePhlebotomy)

  const [isEditing, setIsEditing] = useState(false)

  const removeDisabled = !isEditing
  const groupedLineItemsWithoutDisabled = groupLineItems(
    order.line_items,
    isComboGroupsEnabled,
    true
  )
  const groupedLineItemsIncludingDisabled = groupLineItems(
    order.line_items,
    isComboGroupsEnabled,
    false
  )

  const drawCenterPanels: CompanyPanel[] = []
  const shippedPanels: CompanyPanel[] = []
  const individualShippedLineItems: any[] = []
  const individualIOKLineItems: any[] = []
  const comboGroupLineItems: PatientPortalOrderLineItemLabTest[] = []

  for (const [groupKey, lineItems] of Object.entries(
    removeDisabled
      ? groupedLineItemsWithoutDisabled
      : groupedLineItemsIncludingDisabled
  )) {
    // Find the lab company from any of the line items in the group
    const labCompany = lineItems.find(
      (lineItem) => lineItem?.ordered_test?.lab_test?.lab_company
    )?.ordered_test?.lab_test?.lab_company

    // It's unusual (impossible at the time of writing) for a group to not have a
    // `LabTest` line item, but we still need to check.
    if (!labCompany) {
      continue
    }

    const companyKey = labCompany.key
    const canToggleInsurance =
      Boolean(order.insurance_enabled_for) &&
      labCompany.key in order.insurance_enabled_for
    const willHaveKitShipped = lineItems.some(
      (lineItem) =>
        lineItem.ordered_test &&
        !lineItem.ordered_test.instant_requisition &&
        !lineItem.ordered_test.lab_test.lab_company.only_in_house_phlebotomy
    )
    const companyPanel = {
      groupKey,
      companyDisplayName: labCompany.name,
      companyKey,
      onlyInHousePhlebotomy: labCompany.only_in_house_phlebotomy,
      lineItems,
      displayAsPanel: LAB_COMPANY_PANELS.includes(groupKey),
      canToggleInsurance,
      willHaveKitShipped,
      scarletWillComeToYou: companyKey === LAB_COMPANY_KEY.BIOREFERENCE,
      showLineItemPrices: true,
    }

    if (QUEST_COMPANY_KEYS.includes(labCompany.key)) {
      companyPanel.showLineItemPrices = false
    }

    if (!companyPanel.willHaveKitShipped && companyPanel.displayAsPanel) {
      drawCenterPanels.push(companyPanel)
    } else if (companyPanel.willHaveKitShipped && companyPanel.displayAsPanel) {
      shippedPanels.push(companyPanel)
    } else if (!companyPanel.willHaveKitShipped) {
      individualIOKLineItems.push(...companyPanel.lineItems)
    } else if (groupKey.startsWith(COMBO_GROUP_CARD_KEY_PREFIX)) {
      comboGroupLineItems.push(
        ...companyPanel.lineItems.flatMap((lineItem) =>
          lineItem.type === ORDER_LINE_ITEM_TYPE.LAB_TEST ? lineItem : []
        )
      )
    } else {
      individualShippedLineItems.push(...companyPanel.lineItems)
    }
  }

  const title = order.requires_vendor_physician_authorization
    ? "Lab Cart"
    : "Order"

  const customFeeLineItems = order.line_items.filter(
    (li) => li.type === ORDER_LINE_ITEM_TYPE.CUSTOM_FEE
  )

  const allowEditingCart = useMemo(() => {
    return (
      order.requires_vendor_physician_authorization &&
      !order.is_practitioner_paying &&
      // only allow editing if more than one line item has an ordered test
      Object.keys(groupedLineItemsIncludingDisabled).length > 1
    )
  }, [
    groupedLineItemsIncludingDisabled,
    order.is_labshops_order,
    order.requires_vendor_physician_authorization,
  ])

  const saveCartDisabledNoLineItems = useMemo(() => {
    return (
      isEditing &&
      // don't allow saving if there are no enabled line items
      Object.keys(groupedLineItemsWithoutDisabled).length === 0
    )
  }, [isEditing, groupedLineItemsWithoutDisabled])

  const saveCartDisabledUpdating = useMemo(() => {
    return isEditing && isUpdatingLineItems
  }, [isEditing, isUpdatingLineItems])

  const saveCartDisabled =
    saveCartDisabledNoLineItems || saveCartDisabledUpdating

  const DetailsWrapper = ({ children }) => (
    <div className={className}>
      <div className="flex items-center justify-between">
        <Typography variant="h4" color="textPrimary" style={{ fontSize: 24 }}>
          {title} Details
        </Typography>
        {allowEditingCart && (
          <Tooltip
            title={
              saveCartDisabledNoLineItems
                ? "Please keep at least one test in your cart to save."
                : "Updating cart, please wait."
            }
            placement="bottom"
            arrow
            disableHoverListener={!saveCartDisabled}
          >
            <div>
              <DesignSystemButton
                className={classes.editCartButton}
                color={isEditing && !saveCartDisabled ? "primary" : "secondary"}
                onClick={() => setIsEditing(!isEditing)}
                disabled={saveCartDisabled}
                variant="text"
              >
                {isEditing ? "Save Cart" : "Edit Cart"}
              </DesignSystemButton>
            </div>
          </Tooltip>
        )}
      </div>
      {children}
    </div>
  )

  if (isOrderBeingUpdated) {
    return (
      <DetailsWrapper>
        {" "}
        <Loading ariaLabel="Loading order details" />{" "}
      </DetailsWrapper>
    )
  }

  if (Object.keys(groupedLineItemsWithoutDisabled).length === 0 && !isEditing) {
    return (
      <DetailsWrapper>
        <div className="flex justify-center items-center mt-8">
          <Typography color="textPrimary" className="font-semibold">
            Your cart is empty!
          </Typography>
        </div>
      </DetailsWrapper>
    )
  }

  const lineItemsUpdating = isUpdatingOrder || isUpdatingLineItems

  return (
    <DetailsWrapper>
      <GenovaInsuranceExplanation order={order} location="patientCheckout" />
      {!_.isEmpty(drawCenterPanels) && (
        <DrawCenterLineItems
          panels={drawCenterPanels}
          showPrices={showPrices}
          order={order}
          isEditing={isEditing}
          updatingOrderedTestIds={updatingOrderedTestIds}
          enableLineItems={enableLineItems}
          disableLineItems={disableLineItems}
          isUpdatingOrder={lineItemsUpdating}
        />
      )}
      {!_.isEmpty(comboGroupLineItems) && (
        <ComboGroupLineItems
          comboGroups={comboGroupLineItems}
          showPrices={showPrices}
          order={order}
          isEditing={isEditing}
          updatingOrderedTestIds={updatingOrderedTestIds}
          enableLineItems={enableLineItems}
          disableLineItems={disableLineItems}
          isUpdatingOrder={lineItemsUpdating}
        />
      )}
      {(!_.isEmpty(shippedPanels) ||
        !_.isEmpty(individualShippedLineItems)) && (
        <ShippedLineItems
          panels={shippedPanels}
          individualShippedLineItems={individualShippedLineItems}
          showPrices={showPrices}
          order={order}
          isEditing={isEditing}
          updatingOrderedTestIds={updatingOrderedTestIds}
          enableLineItems={enableLineItems}
          disableLineItems={disableLineItems}
          isUpdatingOrder={lineItemsUpdating}
        />
      )}
      {!_.isEmpty(individualIOKLineItems) && (
        <div className="mt-6">
          <Typography color="textPrimary" className="font-semibold mb-2">
            Your practitioner will hand you these tests:
          </Typography>
          {individualIOKLineItems.map((lineItem) => (
            <LabTestLineItem
              key={lineItem.id}
              lineItem={lineItem}
              showPrice={showPrices}
              hideDiscount={Boolean(order.storefront_id)}
              isLabshopsOrder={order.is_labshops_order}
              isEditing={isEditing}
              updatingOrderedTestIds={updatingOrderedTestIds}
              enableLineItems={enableLineItems}
              disableLineItems={disableLineItems}
              isUpdatingOrder={lineItemsUpdating}
            />
          ))}
        </div>
      )}
      {!axlePhlebotomyEnabledFlag && <NeedPhlebotomistWarning order={order} />}
      {/* Don't show price information if the patient does not need to pay or order.total_price = 0. */}
      {showPrices && (
        <>
          <Coupons order={order} />
          <MobileBloodDrawIndicator order={order} />
          <Divider className="mb-3 mt-8" />
          <PriceBreakdown
            checkoutToken={checkoutToken}
            order={order}
            setCancelModalOpenWithOptions={setCancelModalOpenWithOptions}
            customFeeLineItems={customFeeLineItems}
            isUpdatingLineItems={isUpdatingLineItems}
          />
        </>
      )}
    </DetailsWrapper>
  )
}

interface CouponsProps {
  order: PatientPortalOrder
  className?: string
}

function Coupons({ order, className }: CouponsProps) {
  const couponLineItems = order.line_items.flatMap((lineItem) =>
    lineItem.type === ORDER_LINE_ITEM_TYPE.COUPON ? lineItem : []
  )

  if (_.isEmpty(couponLineItems)) {
    return null
  }

  return (
    <div className={className}>
      {couponLineItems.map((lineItem) => (
        <Coupon
          key={lineItem.id}
          className="mb-2 bg-white"
          title={lineItem.coupon.patient_title}
          subtitle={lineItem.coupon.patient_subtitle}
          cost={lineItem.cost}
        />
      ))}
    </div>
  )
}
