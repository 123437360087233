export enum UserPaths {
  ROOT = "/",
  ONBOARDING = "/onboarding",
  SETTINGS = "/settings",
  ACCOUNT = "/settings/account",
  PROFILE = "/settings/profile",
  BUNDLES = "/settings/bundles",
  SNIPPETS = "/settings/snippets",
  ADD_SHARED_BUNDLE = "/settings/bundles/add/:shareBundleId",
  CLINIC = "/settings/clinic",
  TEAM = "/settings/team",
  NOTIFICATIONS = "/settings/notifications",
  PHLEBOTOMISTS = "/settings/phlebotomists",
  BILLING_AND_CUSTOM_FEES = "/settings/billing",
  INSURANCE_ACCOUNT_SETTINGS = "/settings/insurance",
  PHYSICIAN_SERVICES_ENABLE_MODAL = "/settings/physician-authorization-application", // Should use PHYSICIAN_SERVICES_TERMS, just keeping this for backward compatibility
  PHYSICIAN_SERVICES_TERMS = "/settings/physician-services-terms",
  DASHBOARD = "/dashboard",
  ORDER_CREATE = "/checkout/create/:patientId/:shippingState?",
  ORDER_CHECKOUT = "/checkout/:orderId/:checkoutState?",
  CONVERSATIONS = "/conversations",
  ERRORS_404 = "/errors/404",
  ERRORS_500 = "/errors/500",
  LOADER = "/loader",
  PATIENT_REQUISITIONS = "/patient/:patientId/requisitions/:orderId",
  PATIENT_PENDING_PAYMENT = "/patient/:patientId/pendingpayment/:orderId",
  PATIENT_DETAIL_FOOD_PLAN = "/patient/:patientId/food-plans/:foodPlanId",
  PATIENT_DETAIL = "/patient/:patientId",
  SIGNATURE = "/signature",
  UNIVERSITY_CATALOG = "/university",
  UNIVERSITY_LIVE_CLASS = "/university/live",
  ORDERING_ACCESS = "/lab-ordering-access",
  PHLEBOTOMY_MAP = "/phlebotomists",
  ECOMMERCE_STORE = "/store",
  ECOMMERCE_STORE_ORDERS = "/store/orders",
  ECOMMERCE_STORE_ANALYTICS = "/store/analytics",
  PROMOTIONS = "/promotions",
  FOOD_PLANS = "/food-plans",
  FIND_A_PRACTITIONER = "/find-a-practitioner",
  IN_OFFICE_KITS = "/in-office-kits",
  RUPA_BLOOD_DASHBOARDS = "/rupa-blood-dashboards",
  RUPA_BLOOD_DASHBOARDS_PATIENT_PREVIEW = "/blood-lab-dashboard/:orderedResultId/patient-preview",
  // TODO: Revert back to blood-lab-dashboard/ once we have fully converted to snapshots
  RUPA_BLOOD_DASHBOARDS_SNAPSHOT_PATIENT_PREVIEW = "/blood-lab-dashboard-snapshot/:snapshotId/patient-preview",
  TEST_PDF_GENERATION = "/test-pdf-generation/:snapshotId",
  CLIENTS = "/clients",
}

export enum GuestPaths {
  CALLBACK = "/callback",
  LOGIN = "/login",
  LOGIN_INTERNAL = "/login_internal",
  RESET = "/reset",
  PATIENT_SIGNUP = "/patient_signup",
  PATIENT_PORTAL_CALLBACK = "/patient_portal_callback",
}

export enum AllowAnyPaths {
  DISCOVER_LABS = "/discover-labs",
  DISCOVER_LABS_SEARCH = "/discover-labs/search",
  PATIENT_CHECKOUT = "/patientcheckout",
  PAYMENT_COLLECTION = "/patient-payment",
  ORDER_INSTRUCTIONS_ERROR = "/orderinstructions/error/:errorCode?",
  ORDER_INSTRUCTIONS_DETAIL = "/orderinstructions/:orderId",
  SET_PASSWORD = "/setpassword",
  PROMO = "/promo",
  PUBLIC_ECOMMERCE_STOREFRONT = "/store/:storefrontId",
  MAGIC_LOGIN = "/magiclogin",
  VERIFY_EMAIL = "/verifyemail",
  PHLEBOTOMY_BOOKING = "/schedule-blood-draw",
}

/**
 * Note, this is more about patient-checkout paths rather than patient portal paths.
 * See `app/patient-portal/paths.ts` for the patient portal paths.
 */
export const PatientPaths: string[] = [
  AllowAnyPaths.PATIENT_CHECKOUT,
  AllowAnyPaths.PAYMENT_COLLECTION,
  AllowAnyPaths.ORDER_INSTRUCTIONS_DETAIL,
  AllowAnyPaths.ORDER_INSTRUCTIONS_ERROR,
]
