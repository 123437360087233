import { useModal } from "@ebay/nice-modal-react"

import { ReactComponent as ResultsVisualizationWithNotificationIcon } from "app/assets/icons/dashboard/results-visualization-with-notification.svg"
import { ReactComponent as ResultsVisualizationIcon } from "app/assets/icons/dashboard/results-visualization.svg"
import { InteractiveReportModal } from "app/components/modals/InteractiveReportModal"
import ActionButton from "app/dashboard/components/TableRowDetailRenderer/actions/ActionButton"
import useEventCallback from "app/hooks/use-event-callback"
import { LabCompany, LabTest, OrderedTest, PatientOrdersOrder } from "app/types"

export interface PatientOrdersOrderedTestEmbeddableResultActionProps {
  order: PatientOrdersOrder
  orderedTest: OrderedTest
  labCompany: LabCompany
  labTest: LabTest
}

export default function PatientOrdersOrderedTestEmbeddableResultAction({
  order,
  orderedTest,
  labCompany,
  labTest,
}: PatientOrdersOrderedTestEmbeddableResultActionProps) {
  const emmbeddableUserResultId = orderedTest.embeddable_user_result_id

  const hasAnyUnreadNotification = orderedTest.is_ordered_test_result_unread

  const interactiveReportModal = useModal(InteractiveReportModal)

  const openModal = useEventCallback(() =>
    interactiveReportModal.show({
      onClose: () => {
        interactiveReportModal.remove()
      },
      emmbeddableUserResultId: emmbeddableUserResultId ?? null,
      reportName: labTest.name,
      orderId: order.id,
      labCompanyId: labCompany.id,
      labTestName: labTest.name,
      downloadableResultUrls: orderedTest.results ? [orderedTest.results] : [],
      userType: "practitioner",
    })
  )

  return (
    <ActionButton
      disabled={!emmbeddableUserResultId}
      onClick={openModal}
      className="md:ml-2"
      tooltipTitle={
        emmbeddableUserResultId ? (
          "See Results"
        ) : (
          <>
            See Results <br />
            (Not Available Yet)
          </>
        )
      }
    >
      <>
        {hasAnyUnreadNotification ? (
          <ResultsVisualizationWithNotificationIcon
            data-cy="results-visualization-notification"
            fill="currentColor"
            viewBox="0 0 17 22"
          />
        ) : (
          <ResultsVisualizationIcon
            data-cy="results-visualization-cleared"
            fill="currentColor"
            viewBox="0 0 15 18"
          />
        )}
      </>
    </ActionButton>
  )
}
