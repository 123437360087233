import React from "react"

import { useHistory } from "react-router-dom"

import NiceModal, { muiDialog, useModal } from "@ebay/nice-modal-react"
import { faSquareInfo } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dialog, FadeProps, Grow, IconButton } from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import CloseIcon from "@material-ui/icons/Close"

import { UserPaths } from "app/Routes/paths"
import { ReactComponent as GreenCheck } from "app/assets/icons/green-check.svg"
import { ReactComponent as OptimalRangesIcon } from "app/assets/icons/optimal-range.svg"
import { ReactComponent as DownArrowIcon } from "app/assets/icons/rupa-blood-dashboards/down-arrow.svg"
import { ReactComponent as UpArrowIcon } from "app/assets/icons/rupa-blood-dashboards/up-arrow.svg"
import BodyText from "app/components/design-system/BodyText"
import useAppSelector from "app/hooks/useAppSelector"
import { OptimalRangeSource } from "app/main/settings/RupaBloodDashboards/constants"
import { ClinicSettings } from "app/main/settings/RupaBloodDashboards/constants"
import { colors, shadows } from "app/theme"
import { openInNewTab } from "app/utils/link-utils"
import makeAppStyles from "app/utils/makeAppStyles"

interface TrendsOptimalRangesModalProps {
  onClose: () => void
  optimalRanges: string[]
  practitionerView?: boolean
}

const GrowTransition = React.forwardRef<unknown, FadeProps>(function Transition(
  props,
  ref
) {
  return <Grow ref={ref} {...props} timeout={500} />
})

const useStyles = makeAppStyles(() => ({
  dialogPaper: {
    margin: 15,
    borderRadius: 12,
    border: "4px solid white",
    boxShadow: shadows["2xl"],
    width: 700,
    transition: "all 500ms",
  },
}))

const TrendsOptimalRangesModal = ({
  onClose,
  optimalRanges,
  practitionerView = false,
}: TrendsOptimalRangesModalProps) => {
  const classes = useStyles()
  const history = useHistory()

  const modal = useModal()
  const muiDialogControl = muiDialog(modal)

  const optimalRangesUsed = optimalRanges.length > 0
  const rupaRangeUsed = optimalRanges.includes(OptimalRangeSource.RUPA_HEALTH)

  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const clinic = practitioner?.clinic

  const clinicOptimalRangesEnabled = clinic?.clinic_settings.includes(
    ClinicSettings.USE_OPTIMAL_RANGES_FOR_BLOOD_REPORTS
  )

  return (
    <>
      <Dialog
        {...muiDialogControl}
        disableEnforceFocus
        disableEscapeKeyDown
        fullWidth
        className={"fs-unmask"}
        classes={{
          paper: classes.dialogPaper,
        }}
        TransitionComponent={GrowTransition}
      >
        <MuiDialogTitle
          disableTypography
          className="flex justify-between flex-nowrap bg-white items-center py-1 px-6"
        >
          <BodyText
            weight="semibold"
            size="lg"
            className="font-title flex items-center"
          >
            About Optimal Ranges
          </BodyText>
          <IconButton
            aria-label="close"
            onClick={onClose}
            key="close-button"
            className="text-body relative top-[1px] right-0 mr-[-12px]"
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent className="sm:p-[22.5px] bg-blue-gray-100 flex flex-col p-3 gap-3 text-sm">
          <div className="flex flex-col items-start gap-[17px] self-stretch p-4 rounded-lg bg-white shadow-sm">
            <div className="bg-slate-100 text-navy w-full font-semibold rounded pt-1 pb-1 flex">
              <div className="mr-2 ml-1.5 flex items-center">
                <OptimalRangesIcon />
              </div>
              Optimal Ranges
            </div>
            <div>
              Optimal ranges go beyond normal ranges, aiming for peak health
              rather than just absence of disease. These evidence-based,
              narrower ranges offer a more precise view of your body's function.
              {practitionerView && clinic && (
                <span className="font-open-sans text-body text-sm">
                  {" "}
                  Your clinic has optimal ranges{" "}
                  <span className="font-bold">
                    {clinicOptimalRangesEnabled ? "on" : "off"}
                  </span>{" "}
                  in{" "}
                  <span
                    className="underline cursor-pointer"
                    onClick={() => {
                      onClose()
                      history.push(UserPaths.RUPA_BLOOD_DASHBOARDS)
                    }}
                  >
                    Settings
                  </span>
                  .
                </span>
              )}
            </div>
            {optimalRangesUsed && (
              <div>
                <div>Your Trends view includes optimal ranges from:</div>
                <ul className="list-disc pl-6">
                  {optimalRanges.map((range) => (
                    <li className="font-semibold" key={range}>
                      {range}
                    </li>
                  ))}
                </ul>
                {rupaRangeUsed && (
                  <div className="text-xs mt-2">
                    <span
                      className="underline cursor-pointer"
                      onClick={() =>
                        openInNewTab(
                          "https://www.rupahealth.com/optimal-ranges"
                        )
                      }
                    >
                      Learn more
                    </span>{" "}
                    about how Rupa Heath’s Optimal Ranges were developed.
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="flex flex-col items-start gap-[17px] self-stretch p-4 rounded-lg bg-white shadow-sm">
            <div className="bg-slate-100 text-navy w-full font-semibold rounded pt-1 pb-1">
              <FontAwesomeIcon className="ml-2 mr-2" icon={faSquareInfo} />
              Legend
            </div>
            <TypeSection
              title={
                optimalRangesUsed || clinicOptimalRangesEnabled
                  ? "Optimal (or Normal, if no Optimal Range is specified)"
                  : "Normal"
              }
              body={
                optimalRangesUsed || clinicOptimalRangesEnabled
                  ? "Within the ideal range for optimal health."
                  : "Within the lab’s standard range."
              }
              statusIndicators={<GreenCheck width={19} height={27} />}
              color={colors.emerald[100]}
            />
            {(optimalRangesUsed || clinicOptimalRangesEnabled) && (
              <TypeSection
                title="Above or Below Optimal"
                body="Within the lab’s standard normal range but outside the ideal level for optimal health."
                statusIndicators={
                  <>
                    <DownArrowIcon
                      width={19}
                      height={27}
                      fill={colors.lime[500]}
                    />
                    <UpArrowIcon
                      width={19}
                      height={27}
                      fill={colors.lime[500]}
                    />
                  </>
                }
                color={colors.lime[100]}
              />
            )}
            <TypeSection
              title="Abnormally High or Low"
              body="Outside the lab’s standard range."
              statusIndicators={
                <>
                  <DownArrowIcon
                    width={19}
                    height={27}
                    fill={colors.red[500]}
                  />
                  <UpArrowIcon width={19} height={27} fill={colors.red[500]} />
                </>
              }
              color={colors.rose[100]}
            />
          </div>
        </MuiDialogContent>
      </Dialog>
    </>
  )
}

const TypeSection = ({
  title,
  body,
  statusIndicators,
  color,
}: {
  title: string
  body: string
  statusIndicators: React.ReactNode
  color?: string
}) => {
  return (
    <div className="flex flex-col items-start gap-2.5 self-stretch p-2.5 rounded-md border border-slate-200 bg-white">
      <div className="flex gap-4 items-center">
        <div
          className="flex-shrink-0 w-32 h-14 rounded-lg flex items-center justify-center"
          style={{ backgroundColor: color }}
        >
          {statusIndicators}
        </div>
        <div>
          <div className="font-open-sans font-semibold text-body text-sm">
            {title}
          </div>
          <div className="font-open-sans text-body text-sm">{body}</div>
        </div>
      </div>
    </div>
  )
}

const TrendsLegendNiceModal = NiceModal.create<TrendsOptimalRangesModalProps>(
  TrendsOptimalRangesModal
)

export default TrendsLegendNiceModal
