import { TextField } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import { colors } from "app/theme"
import { Clinic } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import { useIOKOrderFormContext } from "../order-history/IokStartOrderModal"

const useStyles = makeAppStyles(() => ({
  textField: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: colors.blueGray[50],
      borderRadius: 6,
      height: 35,
    },
    "& .MuiOutlinedInput-input": {
      paddingLeft: "9px",
    },
    "& .MuiFormHelperText-root": {
      color: "red",
    },
  },
  combinedRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: 15,
  },
}))

interface Props {
  clinic: Clinic
}

const OrderFormAddress = ({ clinic }: Props) => {
  const classes = useStyles()
  const {
    register,
    formState: { errors },
  } = useIOKOrderFormContext()

  return (
    <>
      <BodyText style={{ fontWeight: 600 }}>Shipping Address</BodyText>
      <TextField
        className={classes.textField}
        {...register("streetAddress1", {
          required: "This field is required",
        })}
        fullWidth={true}
        id="street-address-1"
        defaultValue={clinic.street}
        placeholder="Street Address"
        variant="outlined"
        error={Boolean(errors.streetAddress1)}
      />
      <TextField
        className={classes.textField}
        {...register("streetAddress2")}
        id="street-address-2"
        placeholder="Street Address Line 2"
        variant="outlined"
        fullWidth={true}
      />
      <div className={classes.combinedRow}>
        <TextField
          className={classes.textField}
          {...register("city", {
            required: "This field is required",
          })}
          id="city"
          defaultValue={clinic.city}
          placeholder="City"
          variant="outlined"
          fullWidth={true}
          error={Boolean(errors.city)}
        />
        <TextField
          className={classes.textField}
          {...register("state", {
            required: "This field is required",
          })}
          id="state"
          placeholder="State"
          defaultValue={clinic.state}
          variant="outlined"
          fullWidth={true}
          error={Boolean(errors.state)}
        />
      </div>
      <TextField
        className={classes.textField}
        {...register("zipCode", {
          required: "This field is required",
        })}
        id="zipCode"
        placeholder="Zip Code"
        defaultValue={clinic.zipcode}
        variant="outlined"
        fullWidth={true}
        error={Boolean(errors.zipCode)}
      />
    </>
  )
}

export default OrderFormAddress
