import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { CircularProgress, Divider } from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"

import { ReactComponent as TestTubesIcon } from "app/assets/icons/test-tubes-small.svg"
import BodyText from "app/components/design-system/BodyText"
import { LAB_COMPANY_ACCOUNT_STATUS } from "app/constants"
import { trackLabDropdownFromOrderSupplies } from "app/services/segment"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection, ResourceIdentifier } from "app/swr/types"
import { colors } from "app/theme"
import { Practitioner } from "app/types"
import { showErrorToast } from "app/utils"
import { LabCompany } from "types/lab-company"

import FormFooter from "../components/FormFooter"
import FormHeader from "../components/FormHeader"
import OrderFormAddress from "../components/OrderFormAddress"
import OrderFormProduct from "../components/OrderFormProduct"
import { Filter } from "../components/filter"
import {
  InOfficeKitLabCompanyType,
  InOfficeKitProduct,
  InOfficeKitProductCategoryType,
  InOfficeKitProductInventoryConfig,
  LabCompanyOrderingRightType,
} from "../types"
import { useIOKOrderFormContext } from "./IokStartOrderModal"

interface GroupedProducts {
  [key: string]: {
    id: string
    category: InOfficeKitProductCategoryType
    products: InOfficeKitProduct[]
  }
}

interface StartOrderFormProps {
  onCloseModal: () => void
  practitioner: Practitioner
  verifiedPractitioners: Practitioner[]
  iokInventoryConfigs: InOfficeKitProductInventoryConfig[]
  initialValues?: Object
  setInitialValues: (values: Object) => void
  selectedLabCompany: InOfficeKitLabCompanyType | null
  setSelectedLabCompany: (labCompany: InOfficeKitLabCompanyType | null) => void
  iokProducts: InOfficeKitProduct[]
  areProductsLoading: boolean
  selectedPractitioner: Practitioner | null
  setSelectedPractitioner: (practitioner: Practitioner) => void
  setIsConfirming: (isConfirming: boolean) => void
}

export const StartOrderForm = ({
  onCloseModal,
  practitioner,
  verifiedPractitioners,
  iokInventoryConfigs,
  initialValues,
  setInitialValues,
  selectedLabCompany,
  setSelectedLabCompany,
  iokProducts,
  areProductsLoading,
  selectedPractitioner,
  setSelectedPractitioner,
  setIsConfirming,
}: StartOrderFormProps) => {
  const dispatch = useDispatch()
  const { getValues, reset, trigger } = useIOKOrderFormContext()

  const clinic = practitioner.clinic

  const [selectedProduct, setSelectedProduct] =
    useState<InOfficeKitProduct | null>(null)
  const [productsByCategory, setProductsByCategory] =
    useState<GroupedProducts>(Object)

  const { data: iokLabCompanyIdentifiers, isLoading } = useCollectionSWR<
    ResourceCollection<InOfficeKitLabCompanyType>
  >("/in_office_kit_lab_company/", { include: ["lab_company.ordering_rights"] })

  const iokLabCompanies = useCachedCollection<InOfficeKitLabCompanyType>(
    iokLabCompanyIdentifiers
  )

  const [canPracOrder, setCanPracOrder] = useState<boolean>(false)

  useEffect(() => {
    if (selectedLabCompany) {
      setCanPracOrder(
        hasOrderingRights(selectedPractitioner || practitioner, orderingRights)
      )
    } else {
      // Don't disable button if no lab company is selected
      setCanPracOrder(true)
    }
  }, [selectedLabCompany, selectedPractitioner])

  useEffect(() => {
    if (!initialValues) return
    if (initialValues["iok_lab_company"]) {
      const initialLabCompany = iokLabCompanies.find(
        (labCompany) => labCompany.id === initialValues["iok_lab_company"]
      )
      if (initialLabCompany) {
        setSelectedLabCompany(initialLabCompany)
      }
    } else if (!selectedLabCompany) {
      setSelectedLabCompany(null)
    }
  }, [initialValues, iokLabCompanies])

  useEffect(() => {
    if (selectedProduct) {
      setProductsByCategory(groupProductsByCategory([selectedProduct]))
    } else if (selectedLabCompany) {
      setProductsByCategory(groupProductsByCategory(iokProducts))
    } else {
      setProductsByCategory(groupProductsByCategory([]))
    }
  }, [iokProducts, selectedProduct])

  const labCompanyId = selectedLabCompany?.relationships?.lab_company
    ?.data as ResourceIdentifier

  const labCompany = useCachedResource<LabCompany>(
    labCompanyId ? labCompanyId : undefined
  )
  const orderingRights = useCachedCollection<LabCompanyOrderingRightType>(
    labCompany ? labCompany.relationships.ordering_rights.data : undefined
  )

  const hasOrderingRights = (
    practitioner: Practitioner,
    ordering_rights: LabCompanyOrderingRightType[]
  ) => {
    const { primary_practitioner_type, clinic, lab_company_accounts } =
      practitioner
    const pracTypeId = primary_practitioner_type?.id
    const { state } = clinic
    if (!ordering_rights.length) {
      return true
    }

    const canOrder = lab_company_accounts?.some(({ lab_companies, status }) => {
      const isActive = [
        LAB_COMPANY_ACCOUNT_STATUS.PENDING,
        LAB_COMPANY_ACCOUNT_STATUS.CONFIRMED,
      ].includes(status)
      const isLabCompanyPresent = lab_companies.some(
        ({ id }) => id === labCompany?.id
      )
      return isActive && isLabCompanyPresent
    })

    if (canOrder) {
      return true
    }

    return ordering_rights.some(({ attributes, relationships }) => {
      const { disallowed_states } = attributes
      const { practitioner_types } = relationships
      if (disallowed_states.includes(state)) {
        return false
      }
      return practitioner_types.data.some(({ id }) => id === String(pracTypeId))
    })
  }

  const groupProductsByCategory = (products: InOfficeKitProduct[]) => {
    return products.reduce((acc, product) => {
      const categoryId =
        product.attributes.in_office_kit_product_category?.id || ""
      if (!acc[categoryId]) {
        acc[categoryId] = {
          id: categoryId,
          category: product.attributes.in_office_kit_product_category,
          products: [],
        }
      }
      acc[categoryId].products.push(product)
      return acc
    }, {})
  }

  const resetProductSelections = () => {
    const currentValues = getValues()
    Object.keys(currentValues).forEach((key) => {
      if (key.startsWith("product:")) {
        delete currentValues[key]
      }
    })
    reset(currentValues)
  }

  const onContinue = async () => {
    const data = getValues()
    const isProductSelected = Object.keys(data).some(
      (key) => key.startsWith("product:") && data[key]
    )
    if (!isProductSelected) {
      dispatch(
        showErrorToast({
          message: "Please select at least one product to order.",
        })
      )
      return
    }
    if (await trigger()) setIsConfirming(true)
  }

  return (
    <>
      <FormHeader
        headerText="In-Office Supplies Order"
        onClose={onCloseModal}
      />
      <MuiDialogContent>
        <div className="mt-4 w-full rounded-md bg-white shadow-sm border border-slate-300 self-center">
          <div className="h-2 rounded-tl-md bg-gradient-to-r from-cyan-500 to-transparent" />
          <div className="p-4 flex gap-4 flex-row items-center">
            <div className="bg-slate-100 p-3 w-10 h-10 min-w-10 rounded-md flex items-center justify-center">
              <TestTubesIcon fill="currentColor" />
            </div>
            <p className="text-sm">
              Our partner labs don't currently charge for supplies on Rupa.
              {<br />}
              Please only order what you need. 💙
            </p>
          </div>
        </div>
        <div className="flex flex-col items-start justify-center w-full h-full gap-4 bg-white rounded-lg shadow-md p-4 mt-2.5">
          <OrderFormAddress clinic={clinic} />
        </div>
        {isLoading ? (
          <div className="flex w-100 justify-center p-10">
            <CircularProgress />
          </div>
        ) : (
          <div className="flex flex-col items-start justify-center w-full h-full gap-4 bg-white rounded-lg shadow-md p-4 mt-2.5">
            {practitioner &&
              (practitioner.is_clinic_staff ||
                verifiedPractitioners.length > 0) && (
                <Filter<Practitioner>
                  options={verifiedPractitioners}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setSelectedPractitioner(newValue)
                    } else {
                      setSelectedPractitioner(practitioner)
                    }
                  }}
                  getOptionLabel={(option) => option.user.full_name}
                  value={selectedPractitioner}
                  placeholder="Select A Practitioner"
                  selectIcon={true}
                  style={{ width: "100%" }}
                  textFieldStyle={{
                    backgroundColor: colors.blueGray[50],
                  }}
                  label="Signing Practitioner"
                  getOptionSelected={(option, value) => option.id === value.id}
                  noOptionsText="No options found. Please make sure at least one Practitioner in your Clinic has completed verification."
                />
              )}
            <Filter<InOfficeKitLabCompanyType>
              options={iokLabCompanies}
              onChange={(_event, newLabCompany) => {
                setSelectedLabCompany(newLabCompany)
                setInitialValues({})
                resetProductSelections()
                if (newLabCompany) {
                  trackLabDropdownFromOrderSupplies(
                    practitioner.id,
                    newLabCompany.id
                  )
                }
              }}
              getOptionLabel={(option) => option.attributes.lab_company_name}
              label="Lab Company"
              // TODO: Fix console error when this is changed from uncontrolled to controlled
              value={selectedLabCompany}
              placeholder="Select A Lab Company"
              selectIcon={true}
              style={{ width: "100%" }}
              textFieldStyle={{ backgroundColor: colors.blueGray[50] }}
            />
            {selectedLabCompany && selectedLabCompany.attributes.resource_text && (
              <div
                className="w-full border border-blue-500 bg-blue-50 text-primary rounded-md p-2.5"
                dangerouslySetInnerHTML={{
                  __html: selectedLabCompany.attributes.resource_text,
                }}
              />
            )}
            {areProductsLoading ? (
              <div className="flex w-100 justify-center p-10">
                <CircularProgress />
              </div>
            ) : (
              <>
                {selectedLabCompany && iokProducts.length > 5 && (
                  <Filter<InOfficeKitProduct>
                    options={iokProducts}
                    onChange={(_e, newValue) => {
                      setSelectedProduct(newValue)
                    }}
                    getOptionLabel={(option) => option.attributes.name}
                    value={selectedProduct}
                    placeholder="Search for supplies"
                    style={{ width: "100%" }}
                    textFieldStyle={{
                      backgroundColor: colors.blueGray[50],
                    }}
                  />
                )}

                {Object.values(productsByCategory)
                  .sort(
                    (a, b) =>
                      (a.category
                        ? a.category.sort_order ?? Number.MAX_SAFE_INTEGER - 1
                        : Number.MAX_SAFE_INTEGER) -
                      (b.category
                        ? b.category.sort_order ?? Number.MAX_SAFE_INTEGER - 1
                        : Number.MAX_SAFE_INTEGER)
                  )
                  .map((group) => (
                    <div
                      key={group.id}
                      className="flex flex-col w-full gap-2.5"
                    >
                      <Divider className="w-full text-slate-300 h-[1.5px]" />
                      {group && group.category?.name && (
                        <BodyText className="font-semibold bg-slate-100 rounded-lg px-3 py-1">
                          {group.category.name}
                        </BodyText>
                      )}
                      {productsByCategory[group.id].products.map(
                        (
                          product: InOfficeKitProduct,
                          index: number,
                          arr: InOfficeKitProduct[]
                        ) => (
                          <div key={index}>
                            <OrderFormProduct
                              product={product}
                              initialQuantity={initialValues?.[product.id]}
                              inventoryConfig={iokInventoryConfigs.find(
                                (config) =>
                                  config.relationships?.in_office_kit_product
                                    ?.data?.id === product.id
                              )}
                            />
                            {index < arr.length - 1 && (
                              <Divider className="w-full mt-2.5 text-gray-100 opacity-50" />
                            )}
                          </div>
                        )
                      )}
                    </div>
                  ))}
              </>
            )}
          </div>
        )}
      </MuiDialogContent>
      <FormFooter
        onSubmit={onContinue}
        buttonText="Continue"
        cannotOrderWarning={
          canPracOrder
            ? undefined
            : "The selected Practitioner does not have permission to order from this Lab Company"
        }
      />
    </>
  )
}
