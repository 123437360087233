import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { DateRange } from "materialui-daterange-picker"
import moment from "moment"

import { makeStyles } from "@material-ui/core"
import MuiDialogActions from "@material-ui/core/DialogActions"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

import DownloadFileIcon from "app/assets/icons/transaction-reports/download-file-white.svg"
import RupaDateRangePicker from "app/components/DateRangePicker/RupaDateRangePicker"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { Dialog } from "app/components/modals"
import useAppSelector from "app/hooks/useAppSelector"
import { trackEventWithProperties } from "app/services/segment.typed"
import { colors, navy } from "app/theme"
import { downloadFileByUrl, showSuccessToast } from "app/utils"

import { BillingAndCustomFeesEvent } from "./constants"
import useStartTransactionReportDownload from "./hooks/use-start-transaction-report-download"
import useTransactionReportResources from "./hooks/use-transaction-reports-resources"

interface Props {
  open: boolean
  title?: string
  description?: string
  confirmButtonTitle?: string
  onClose: () => void
}

const styles = (theme) => ({
  dialogPaper: {
    margin: 15,
    borderRadius: 7,
  },
  modalContainer: {
    background: "white",
    borderRadius: 8,
  },
  styledContent: {
    padding: 22.5,
    backgroundColor: colors.coolGray[50],
  },
  styledDialogTitle: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderColor: colors.blueGray[200],
    alignItems: "center",
    padding: "8px 24px",
  },
  actionsContainer: {
    margin: 0,
    padding: "15px 22.5px",
    display: "flex",
    gap: 3.5,
    flexFlow: "column wrap",
    alignItems: "flex-end",
  },
  actionButtonsContainer: {
    margin: 0,
    display: "flex",
    gap: 10,
  },
  titleAndInfoContainer: {
    display: "flex",
    flexFlow: "column wrap",
    marginRight: 24,
    padding: "8.5px 0",
    width: "80%",
    color: navy,
  },
  closeButton: {
    color: navy,
    position: "relative" as "relative",
    top: 1,
    right: 0,
    marginRight: -12,
  },
})

const useStyles = makeStyles(styles)

interface TransactionReportDateRange {
  start_date: string
  end_date: string
}

/**
 * DownloadTransactionReport Modal
 *
 * @param open - The modal will be open if this is true
 * @param title - The title of the modal
 * @param description - The description of the modal
 * @param confirmButtonTitle - The confirm button title
 * @param onClose - The action which needs to be done when the modal closes
 */
const DownloadTransactionReportModal = ({
  open,
  title = "Download Transaction Report",
  description = "Download your transaction history with Rupa. This report will include your order history, payer information, and custom fees (if applicable). Data from before September 20, 2022 is not available. Please message our team if you need this information!",
  onClose,
}: Props) => {
  const classes = useStyles(styles)

  const dispatch = useDispatch()

  const practitioner = useAppSelector(({ practitioner }) => practitioner)

  const [reportDownloading, setReportDownloading] = useState(false)
  const [reportId, setReportId] = useState<string | undefined>(undefined)
  const [dateRange, setDateRange] = useState<TransactionReportDateRange | null>(
    null
  )

  const START_OF_PAYMENTS_2_0 = moment("2022-09-21").toDate()

  const startDownload = useStartTransactionReportDownload()
  const { transactionReport, unableToDownload } =
    useTransactionReportResources(reportId)

  const handleDateRangeChange = (dateRange: DateRange) => {
    if (dateRange.startDate && dateRange.endDate) {
      trackEventWithProperties(
        BillingAndCustomFeesEvent.TRANSACTION_REPORT_DATE_RANGE_ADJUSTED,
        {
          practitionerId: practitioner.id,
          startDate: moment(dateRange.startDate).format("YYYY-MM-DD"),
          endDate: moment(dateRange.endDate).format("YYYY-MM-DD"),
        }
      )
      setDateRange({
        start_date: moment(dateRange.startDate).format("YYYY-MM-DD"),
        end_date: moment(dateRange.endDate).format("YYYY-MM-DD"),
      })
    }
  }

  const handleDownloadClick = async () => {
    if (dateRange) {
      setReportDownloading(true)
      const transactionReportId = await startDownload({
        ...dateRange,
        file_type: "csv",
        transaction_type: "payments",
      })
      trackEventWithProperties(
        BillingAndCustomFeesEvent.TRANASACTION_REPORT_CLICK_DOWNLOAD,
        {
          practitionerId: practitioner.id,
          startDate: dateRange.start_date,
          endDate: dateRange.end_date,
          transactionReportId,
        }
      )
      setReportId(transactionReportId)
    }
  }

  const handleOnClose = () => {
    setReportId(undefined)
    setDateRange(null)
    setReportDownloading(false)
    onClose()
  }

  useEffect(() => {
    if (transactionReport?.attributes.report_file) {
      setReportDownloading(false)
      downloadFileByUrl(transactionReport.attributes.report_file)
      trackEventWithProperties(
        BillingAndCustomFeesEvent.TRANSACTION_REPORT_RECEIVED_DOWNLOAD,
        {
          practitionerId: practitioner.id,
          startDate: dateRange?.start_date,
          endDate: dateRange?.end_date,
          transactionReportId: reportId,
        }
      )
      handleOnClose()
      dispatch(
        showSuccessToast({
          message: "Successfully downloaded transaction report.",
        })
      )
    }
  }, [transactionReport])

  useEffect(() => {
    if (unableToDownload) {
      setReportDownloading(false)
    }
  }, [unableToDownload])

  return (
    <Dialog
      onClose={handleOnClose}
      aria-labelledby="lab-test-bundle-title"
      open={open}
      className={"fs-unmask"}
      classes={{
        paper: classes.dialogPaper,
      }}
      fullWidth
      maxWidth="md"
    >
      <TitleSection
        title={title}
        description={description}
        onClose={handleOnClose}
      />
      <MuiDialogContent className={classes.styledContent}>
        <div className={classes.modalContainer}>
          <RupaDateRangePicker
            // Even preset definedRanges will not go before this.
            minDate={START_OF_PAYMENTS_2_0}
            // Even preset definedRanges will not go after this.
            maxDate={new Date()}
            definedRanges={[
              {
                label: "This Month",
                startDate: moment().startOf("month").toDate(),
                endDate: moment().endOf("month").toDate(),
              },
              {
                label: "Last Month",
                startDate: moment()
                  .subtract(1, "month")
                  .startOf("month")
                  .toDate(),
                endDate: moment().subtract(1, "month").endOf("month").toDate(),
              },
              {
                label: "This Year",
                startDate: moment().startOf("year").toDate(),
                endDate: moment().endOf("year").toDate(),
              },
              {
                label: "Last Year",
                // This will not go past the "min date"
                startDate: moment()
                  .subtract(1, "year")
                  .startOf("year")
                  .toDate(),
                endDate: moment().subtract(1, "year").endOf("year").toDate(),
              },
              {
                label: "All Time",
                startDate: START_OF_PAYMENTS_2_0,
                endDate: new Date(),
              },
            ]}
            handleDateRangeChange={handleDateRangeChange}
          />
        </div>
      </MuiDialogContent>
      <MuiDialogActions className={classes.actionsContainer}>
        <div className={classes.actionButtonsContainer}>
          <DesignSystemButton color="noaction" onClick={handleOnClose}>
            Close
          </DesignSystemButton>
          <Tooltip
            title={!Boolean(dateRange) ? "Please select a date range" : ""}
            disableHoverListener={Boolean(dateRange)}
            placement="top"
            arrow
          >
            <span>
              <DesignSystemButton
                color="primary"
                onClick={handleDownloadClick}
                startIcon={DownloadFileIcon}
                loading={reportDownloading}
                disabled={!dateRange}
              >
                Download
              </DesignSystemButton>
            </span>
          </Tooltip>
        </div>
        {reportDownloading && (
          <div>This may take a few minutes to process.</div>
        )}
      </MuiDialogActions>
    </Dialog>
  )
}

const TitleSection = ({ title, description, onClose }) => {
  const classes = useStyles(styles)
  const closeButton = onClose && (
    <IconButton
      aria-label="close"
      onClick={onClose}
      key="close-button"
      className={classes.closeButton}
    >
      <CloseIcon />
    </IconButton>
  )

  return (
    <MuiDialogTitle disableTypography className={classes.styledDialogTitle}>
      <div className={classes.titleAndInfoContainer}>
        <DisplayText weight="semibold" size="lg">
          {title}
        </DisplayText>
        <BodyText weight="regular" size="sm">
          {description}
        </BodyText>
      </div>
      {closeButton}
    </MuiDialogTitle>
  )
}

export default DownloadTransactionReportModal
