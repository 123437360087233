import { Button } from "@rupahealth/design"

export interface ContactSupportButtonProps {
  message?: string
  className?: string
  variant?:
    | "ghost"
    | "primary"
    | "outline-primary"
    | "destructive"
    | "outline-destructive"
    | "success"
    | "outline"
    | null
    | undefined
  size?: "icon" | "default" | "sm" | "lg" | "xl" | null | undefined
  shadow?: "none" | "default" | "sm" | "lg" | null | undefined
  fullWidth?: boolean | null | undefined
  disabled?: boolean
  asChild?: boolean
  children?: React.ReactNode
}

export const ContactSupportButton = ({
  message,
  className,
  size = "default",
  variant = "primary",
  shadow = null,
  fullWidth = false,
  disabled = false,
  asChild = false,
  children = "Contact Support",
}: ContactSupportButtonProps) => {
  return (
    <Button
      onClick={() => {
        if (window.Intercom) {
          window.Intercom("showNewMessage", message)
        } else {
          window.open("mailto:hello@rupahealth.com")
        }
      }}
      className={className}
      size={size}
      variant={variant}
      shadow={shadow}
      fullWidth={fullWidth}
      disabled={disabled}
      asChild={asChild}
    >
      {children}
    </Button>
  )
}
