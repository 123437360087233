import { useCallback } from "react"
import { useDispatch } from "react-redux"

// This restricted import predates our decision to discontinue using formsy-react.
// We now opt to use react-hook-form instead.
// When introducing changes to this component, please consider refactoring to remove
// formsy-react and replace it with react-hook-form where applicable.
// eslint-disable-next-line no-restricted-imports
import Formsy from "formsy-react"

import Grid from "@material-ui/core/Grid"
// This restricted import predates our decision to discontinue using formsy-react.
// We now opt to use react-hook-form instead.
// When introducing changes to this component, please consider refactoring to remove
// formsy-react and replace it with react-hook-form where applicable.
// eslint-disable-next-line no-restricted-imports
import * as Sentry from "@sentry/react"

import { FormTextField, SelectFormField } from "app/components/forms/fields"
import { usePractitionerTypeOptions } from "app/hooks/usePractitionerTypes"
import { updatePractitioner } from "app/store/actions"
import { Practitioner } from "app/types"

interface Props {
  practitioner: Practitioner
}

export const PersonalInformation: React.FC<Props> = ({ practitioner }) => {
  const dispatch = useDispatch()

  const onSubmit = useCallback(
    async ({ first_name, last_name, npi_number, phone_number }) => {
      let hasChangedAttribute = false
      if (
        npi_number !== practitioner.npi_number ||
        first_name !== practitioner.user?.first_name ||
        last_name !== practitioner.user?.last_name ||
        phone_number !== practitioner.phone_number
      ) {
        hasChangedAttribute = true
      }

      if (hasChangedAttribute) {
        dispatch(
          updatePractitioner(
            {
              user: {
                first_name,
                last_name,
              },
              npi_number,
              phone_number,
            },
            true
          )
        )
      }
    },
    [practitioner]
  )

  const { practitionerTypeOptions, isLoading: practitionerTypeOptionsLoading } =
    usePractitionerTypeOptions(
      practitioner.primary_practitioner_type?.id
        ? [practitioner.primary_practitioner_type]
        : undefined
    )

  const onPractitionerTypeChange = (event) => {
    const value = event.target.value
    if (value !== practitioner.primary_practitioner_type?.id) {
      let newPracType = practitionerTypeOptions.find(
        (opt) => opt.value === value
      )

      if (!newPracType) {
        Sentry.captureException(
          new Error(
            `PersonalInformation: PractitionerType not found in practitionerTypeOptions. Type: ${value} | Options: [${practitionerTypeOptions
              .map((o) => o.value)
              .join(", ")}]`
          )
        )
        return
      }

      dispatch(
        updatePractitioner({ primary_practitioner_type: newPracType }, true)
      )
    }
  }

  return (
    <div className="pt-6">
      <Formsy onValidSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormTextField
              label="First Name"
              clickToEdit
              name="first_name"
              value={practitioner.user?.first_name}
              required={false}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              label="Last Name"
              clickToEdit
              name="last_name"
              value={practitioner.user?.last_name}
              required={false}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              label="Email"
              disabled
              name="email"
              value={practitioner.user?.email}
              required={false}
            />
          </Grid>
          {practitioner.phone_number && (
            <Grid item xs={12} md={6}>
              <FormTextField
                label="Phone Number"
                clickToEdit
                name="phone_number"
                value={practitioner.phone_number}
                required={false}
              />
            </Grid>
          )}
          {/* Do not display the NPI Number for Clinic Staff members */}
          {!practitioner.is_clinic_staff && (
            <Grid item xs={12} md={6}>
              <FormTextField
                label="NPI Number"
                disabled={Boolean(practitioner.first_order_date)}
                clickToEdit={!Boolean(practitioner.first_order_date)}
                name="npi_number"
                value={practitioner.npi_number}
                required={false}
                validations={{
                  matchRegexp: /^[0-9]+$/,
                  isLength: 10,
                }}
                validationErrors={{
                  matchRegexp: "NPI Number must only be numbers",
                  isLength: "NPI Number must be 10 digits long",
                }}
                // Disable but display the NPI Number if the practitioner has already sent and order.
              />
            </Grid>
          )}
          {!practitioner.is_clinic_staff && (
            <Grid item xs={12} md={6}>
              <SelectFormField
                label="Practitioner Type"
                onChange={onPractitionerTypeChange}
                disabled={
                  Boolean(practitioner.first_order_date) ||
                  practitionerTypeOptionsLoading
                }
                name="primary_practitioner_type_id"
                value={practitioner.primary_practitioner_type?.id}
                required={false}
                hideEmptyOption
              >
                {practitionerTypeOptions.map((opt) => (
                  <option key={opt.value} value={opt.value}>
                    {opt.label}
                  </option>
                ))}
              </SelectFormField>
            </Grid>
          )}
        </Grid>
      </Formsy>
    </div>
  )
}
