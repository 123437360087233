import {
  SpecimenIssueActionUnion,
  SpecimenIssueStatusUnion,
} from "app/constants.typed"
import { Resource } from "app/swr/types"

export interface SpecimenIssueNormalized extends Resource {
  type: "specimen_issue"
  attributes: {
    description: string
    patient_description: string
    available_actions: SpecimenIssueActionUnion[]
    action_chosen: SpecimenIssueActionUnion | null
    status: SpecimenIssueStatusUnion | null
    created_at: string
    updated_at: string
    estimated_recollection_arrival_date: string | null
    date_resolved: string | null
    date_recollection_ordered_with_lab: string | null
    date_recollection_sample_received_at_lab: string | null
    recollection_shipment_tracking_number: string | null
    estimated_recollection_results_date: string | null
    recollection_requisition: string | null
  }
}

export interface SpecimenIssue {
  id: string
  description: string
  patient_description: string
  available_actions: SpecimenIssueActionUnion[]
  action_chosen: SpecimenIssueActionUnion | null
  status: SpecimenIssueStatusUnion | null
  created_at: string
  updated_at: string
  estimated_recollection_arrival_date: string | null
  date_resolved: string | null
  date_recollection_ordered_with_lab: string | null
  date_recollection_sample_received_at_lab: string | null
  recollection_shipment_tracking_number: string | null
  estimated_recollection_results_date: string | null
  recollection_requisition: string | null
}

export enum SpecimenIssueEvents {
  SPECIMEN_ISSUE_RESOLVE_CLICKED = "Specimen Issue Resolve Clicked",
  SPECIMEN_ISSUE_ACTION_SELECTED = "Specimen Issue Action Selected",
  SPECIMEN_ISSUE_SUCCESS_MODAL_ACTION = "Specimen Issue Success Modal Action",
}
