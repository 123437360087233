import { StyledSwitch } from "app/main/settings/components/StyledSwitch"

import { useClinicDocumentAccessModalContext } from "./ClinicDocumentAccessModalProvider"

const ClinicDocumentAccessFutureSharing = () => {
  const { clinic, sharingFuture, setSharingFuture } =
    useClinicDocumentAccessModalContext()

  const onChange = () => {
    setSharingFuture(!sharingFuture)
  }

  return (
    <div className="flex flex-row gap-2 mt-4">
      <StyledSwitch checked={sharingFuture} onChange={onChange} />
      <div>
        <p className="font-semibold text-sm">
          Share future documents with {clinic.attributes.name} automatically.
        </p>
        <p className="font-normal text-sm">
          Results you import or documents from a different clinic.
        </p>
      </div>
    </div>
  )
}

export default ClinicDocumentAccessFutureSharing
