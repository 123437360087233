import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

import { API } from "app/api"
import { LabCompany } from "app/types"
import { handleApiError } from "app/utils"

const useLabCompanies = () => {
  const [labCompanies, setLabCompanies] = useState<LabCompany[]>([])
  const dispatch = useDispatch()

  useEffect(() => {
    const loadLabCompanies = async () => {
      try {
        const response = await API.LabCompany.getList({
          include_importable: true,
        })
        setLabCompanies(response?.data?.results ?? [])
      } catch (error) {
        dispatch(handleApiError(error))
      }
    }

    loadLabCompanies()
  }, [dispatch])

  return labCompanies
}

export default useLabCompanies
