import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@rupahealth/design"

import { getFileThumbnail } from "../utils"

interface Props {
  file: File
  removeFile: () => void
}

export const UploadedFileCard: React.FC<Props> = ({ file, removeFile }) => {
  const thumbnail = getFileThumbnail(file)

  return (
    <div className="w-full bg-white rounded-md border border-slate-200 flex items-center gap-2.5 py-1 px-2">
      <img
        alt={file.name}
        src={thumbnail}
        width={46}
        className="rounded-sm border border-slate-200"
      />

      <div className="w-full text-left">{file.name}</div>
      <div className="ml-auto">
        <Button
          variant="ghost"
          className="p-1 h-auto rounded-md"
          onClick={removeFile}
        >
          <FontAwesomeIcon
            icon={faXmark}
            className="text-slate-400 text-xl19"
          />
        </Button>
      </div>
    </div>
  )
}
