import { Skeleton } from "@material-ui/lab"

type SkeletonType = "roundedRectangle" | "circle" | "circleLine"
interface Props {
  type?: SkeletonType
}

const CellSkeleton: React.FC<Props> = ({ type }) => {
  switch (type) {
    case "circle":
      return (
        <div className="flex justify-center">
          <Skeleton
            animation="wave"
            component="div"
            height={20}
            width={20}
            variant="circle"
          />
        </div>
      )
    case "roundedRectangle":
      return (
        <Skeleton
          animation="wave"
          component="div"
          className="rounded-2xl min-w-[50px]"
          height={30}
          width="75%"
          variant="rect"
        />
      )
    case "circleLine":
      return (
        <div className="flex items-center justify-center gap-[6px]">
          <Skeleton
            animation="wave"
            component="div"
            height={20}
            width={20}
            variant="circle"
          />
          <Skeleton
            animation="wave"
            component="div"
            className="rounded-lg min-w-[50px]"
            height={24}
            width="50%"
            variant="text"
          />
        </div>
      )
    default:
      return (
        <Skeleton
          animation="wave"
          component="div"
          height={24}
          width="75%"
          variant="text"
          className="min-w-[150px]"
        />
      )
  }
}

export default CellSkeleton
