import useFeatureFlag from "app/hooks/use-feature-flag"
import { ClinicSettings } from "app/main/settings/RupaBloodDashboards/constants"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { ClinicFeatures } from "app/types"

interface Props {
  supportsBloodReport: boolean | undefined
  hasSnapshots: boolean | undefined
  clinicFeatures: string[] | undefined
  clinicSettings: string[] | undefined
  inPatientPortal: boolean
}

export default function useBloodReportActionsEnabledForOrderedResult({
  supportsBloodReport,
  hasSnapshots,
  clinicFeatures,
  clinicSettings,
  inPatientPortal,
}: Props) {
  const [isBloodReportsEnabled] = useFeatureFlag(FeatureFlag.RupaBloodReports)
  const [isBloodLabDashboardSnapshotOn] = useFeatureFlag(
    FeatureFlag.RupaBloodReportSnapshots
  )

  const clinicHasBloodReportsEnabled = clinicFeatures?.includes(
    ClinicFeatures.RUPA_BLOOD_REPORTS
  )

  const clinicHasBloodReportsEnabledForPatient = clinicSettings?.includes(
    ClinicSettings.USE_RUPA_BLOOD_REPORTS
  )

  const isBloodReportSnapshotActionEnabled =
    isBloodReportsEnabled &&
    isBloodLabDashboardSnapshotOn &&
    ((clinicHasBloodReportsEnabled && !inPatientPortal) ||
      (clinicHasBloodReportsEnabledForPatient && inPatientPortal)) &&
    supportsBloodReport &&
    hasSnapshots

  return {
    isBloodReportActionEnabled: isBloodReportSnapshotActionEnabled,
  }
}
