import { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import _ from "lodash"
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form"
import { z } from "zod"

import { zodResolver } from "@hookform/resolvers/zod"
import { Button, Divider, Grid, Link, makeStyles } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import * as Sentry from "@sentry/react"

import { API } from "app/api"
import AddIcon from "app/assets/icons/add-blue.svg"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { IN_OFFICE_KIT_LAB_COMPANIES } from "app/constants"
import useAppSelector from "app/hooks/useAppSelector"
import {
  ControlledSelectField,
  ControlledTextField,
} from "app/main/patient-checkout/fields"
import { US_STATES } from "app/main/patient-checkout/utils/usStates"
import { showMessage } from "app/store/actions"
import { colors, navy, primaryColor } from "app/theme"
import { InOfficeKit, InOfficeKitsFormData, Practitioner } from "app/types"
import { APIError, handleApiError, handleApiSuccess } from "app/utils"

const LAB_COMPANY_FIELD_NAME = "lab_company"
const LAB_TEST_FIELD_NAME = "lab_test"
const LAB_TEST_QUANTITY_FIELD_NAME = "lab_test_quantity"
const IN_OFFICE_KITS_FIELD_NAME = "in_office_kits"

interface Props {
  onClose: () => void
  onSubmit: () => void
  open: boolean
}

const styles = (theme) => ({
  styledContent: {
    padding: "22.5px",
    backgroundColor: colors.coolGray[50],
  },
  styledDialogTitle: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    "flex-wrap": "nowrap",
    backgroundColor: "white",
    borderBottomWidth: "1px",
    borderColor: colors.blueGray[200],
    alignItems: "center",
    padding: "16px 24px",
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "12px 20px",
    borderTopWidth: "1px",
    borderColor: colors.blueGray[300],
  },
  titleAndInfoContainer: {
    display: "flex",
    "flex-direction": "column",
    "flex-wrap": "wrap",
    marginRight: "24px",
  },
  infoContainer: {
    backgroundColor: colors.blueGray[100],
    padding: "24px",
  },
  closeButton: {
    color: navy,
    position: "relative" as "relative",
    bottom: "23px",
    right: "0px",
    marginRight: "-20px",
    [theme.breakpoints.down("sm")]: {
      bottom: "75px",
    },
  },
  styledLink: {
    color: primaryColor,
    fontWeight: 600,
    fontSize: 14,
    "&:hover": {
      cursor: "pointer",
    },
  },
  styledDivider: {
    margin: "14px 0px",
    width: "100%",
  },
  toggleIcon: {
    marginTop: "-3px",
    width: "12px",
  },
  styledBodyTextSectionTitle: {
    paddingBottom: "14px",
  },
  styledAddKitButton: {
    margin: "-10px  0px",
  },
})

const useStyles = makeStyles(styles)

const InOfficeKitTypeHint = z.lazy(() =>
  z.object({
    lab_company: z.string().nonempty("Please select a lab company."),
    lab_test: z
      .string()
      .nonempty("Please enter a lab test.")
      .max(
        50,
        "Maximum length is 50 characters. Please enter a shorter lab test name."
      ),
    lab_test_quantity: z
      .string()
      .nonempty("Please enter a quantity.")
      .regex(new RegExp("[1-9]+"), "Quantity must be a non-zero number."),
  })
) as z.ZodType<InOfficeKit>

const InOfficeKitsFormSchema = z.object({
  address_street_primary: z
    .string()
    .nonempty("Please enter a street address.")
    .max(
      100,
      "Maximum length is 100 characters. Please use a shorter street address."
    ),
  address_street_secondary: z
    .string()
    .max(
      100,
      "Maximum length is 100 characters. Please use a shorter street address."
    )
    .optional()
    .or(z.literal("")),
  address_city: z
    .string()
    .nonempty("Please enter a city.")
    .max(
      50,
      "Maximum length is 50 characters. Please use a shorter city name."
    ),
  address_state: z.string().nonempty("Please select a state."),
  address_zipcode: z
    .string()
    .length(5, "Please enter a valid, 5-digit zip code.")
    .nonempty("Please enter a zip code.")
    .regex(new RegExp("[0-9]+"), "Please enter a valid, 5-digit zip code."),
  in_office_kits: z.array(InOfficeKitTypeHint),
})

/**
 * In-Office Kits modal
 * @param onClose - The action taken when the modal closes
 * @param open - The modal will be open if this is true
 */
const InOfficeKitsModal = ({ onClose, onSubmit, open }: Props) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const classes = useStyles(styles)
  const dispatch = useDispatch()
  const currentPractitioner = useAppSelector(({ practitioner }) => practitioner)
  const [selectedPractitioner, setSelectedPractitioner] =
    useState<Practitioner>(currentPractitioner)
  const [practitionerList, setPractitionerList] = useState<Practitioner[]>([])

  useEffect(() => {
    const fetchPractitioners = async () => {
      if (currentPractitioner.is_clinic_staff) {
        const clinicId = currentPractitioner.clinic.id
        const response = await API.Clinic.getPractitioners(clinicId)

        // Filter practitioners with full_verification_info set to true
        const verifiedPractitioners = response.data.filter(
          (prac) => prac.has_full_verification_info
        )
        setPractitionerList(verifiedPractitioners)
      } else {
        setSelectedPractitioner(currentPractitioner)
        setPractitionerList([currentPractitioner])
      }
    }
    fetchPractitioners()
  }, [currentPractitioner])

  const handlePractitionerChange = (selectedId: string) => {
    const selected = practitionerList.find(
      (practitioner) => practitioner.id === selectedId
    )
    setSelectedPractitioner(selected || currentPractitioner)
  }

  const defaultValues: InOfficeKitsFormData = {
    address_street_primary: selectedPractitioner?.clinic?.street,
    address_street_secondary: "",
    address_city: selectedPractitioner?.clinic?.city,
    address_state: selectedPractitioner?.clinic?.state,
    address_zipcode: selectedPractitioner?.clinic?.zipcode,
    in_office_kits: [
      {
        lab_company: "",
        lab_test: "",
        lab_test_quantity: "",
      },
    ],
    lab_company: "",
    lab_test: "",
    lab_test_quantity: "",
  }

  const methods = useForm<InOfficeKitsFormData>({
    defaultValues,
    resolver: zodResolver(InOfficeKitsFormSchema),
    mode: "onChange",
  })

  const { fields: documentFields, append } = useFieldArray({
    control: methods.control,
    name: IN_OFFICE_KITS_FIELD_NAME,
  })

  // Ensure that the selected practitioner is not clinic staff
  const isFormValid =
    methods.formState.isValid && !selectedPractitioner.is_clinic_staff

  const handleClose = () => {
    setIsSubmitting(false)
    methods.reset()
    onClose()
  }

  const handleSubmit = () => {
    setIsSubmitting(false)
    methods.reset()
    onSubmit()
  }

  const handleError = (error) => {
    const hasErrorData = error.response && error.response.data
    const hasErrorStatus = error.response && error.response.status

    if (hasErrorData && hasErrorStatus && !_.isEmpty(error.response.data)) {
      Sentry.captureException(
        new APIError(
          `Status: ${error.response.status}, Response Data: ${error.response.data}`
        )
      )
      dispatch(
        showMessage({
          message:
            "Unable to submit your request. Please try again. If this issue persists, please contact support via Intercom for assistance.",
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        })
      )
    } else {
      dispatch(handleApiError(error))
    }
  }

  const formSubmit = async (data: InOfficeKitsFormData) => {
    setIsSubmitting(true)

    const formData = new FormData()
    formData.append("address_street_primary", data.address_street_primary)
    formData.append("address_street_secondary", data.address_street_secondary)
    formData.append("address_city", data.address_city)
    formData.append("address_state", data.address_state)
    formData.append("address_zipcode", data.address_zipcode)

    data.in_office_kits.forEach((kit) => {
      if (!kit) {
        return
      }

      formData.append(IN_OFFICE_KITS_FIELD_NAME, data.lab_company)
      formData.append(IN_OFFICE_KITS_FIELD_NAME, data.lab_test)
      formData.append(IN_OFFICE_KITS_FIELD_NAME, data.lab_test_quantity)
    })

    formData.append(
      IN_OFFICE_KITS_FIELD_NAME,
      JSON.stringify(data.in_office_kits)
    )

    // Validate the selectedPractitioner is not Clinic Staff
    if (selectedPractitioner.is_clinic_staff) {
      dispatch(
        showMessage({
          message: "You must select a practitioner to submit this request.",
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        })
      )
      handleClose()
      return
    }

    try {
      const response = await API.Practitioner.createInOfficeKitsRequest(
        selectedPractitioner.id,
        formData
      )

      if (response.status === 200) {
        dispatch(
          handleApiSuccess("In-Office Kits request successfully submitted!")
        )
        handleSubmit()
      }
    } catch (e) {
      handleError(e)
      handleClose()
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="in-office-kits-request-form"
      open={open}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
    >
      <Form
        title="Request Kits to be Shipped to Your Office"
        onClose={handleClose}
        isSubmitting={isSubmitting}
        isFormValid={isFormValid}
        formSubmit={formSubmit}
        confirmButtonTitle="Submit"
        classes={classes}
        methods={methods}
        practitioner={currentPractitioner}
        onPractitionerChange={handlePractitionerChange}
        practitionerList={practitionerList}
        documentFields={documentFields}
        append={append}
      />
    </Dialog>
  )
}

function Form({
  title,
  onClose,
  isSubmitting,
  isFormValid,
  formSubmit,
  confirmButtonTitle,
  classes,
  methods,
  practitioner,
  onPractitionerChange,
  practitionerList,
  documentFields,
  append,
}) {
  useEffect(() => {
    methods.trigger([
      "address_street_primary",
      "address_street_secondary",
      "address_city",
      "address_state",
      "address_zipcode",
    ])
  }, [])

  return (
    <div id="in-office-kits-request-form">
      <TitleSection title={title} onClose={onClose} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(formSubmit)}>
          <div className={classes.infoContainer}>
            <ClinicInfoSection practitioner={practitioner} />
            <PractitionerSection
              practitioner={practitioner}
              practitionerList={practitionerList}
              handlePractitionerChange={onPractitionerChange}
            />
            <InOfficeKitsSection
              append={append}
              documentFields={documentFields}
            />
          </div>
          <div className={classes.actionsContainer}>
            <DesignSystemButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isFormValid}
              loading={isSubmitting}
            >
              {confirmButtonTitle}
            </DesignSystemButton>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

const TitleSection = ({ title, onClose }) => {
  const classes = useStyles(styles)
  const closeButton = onClose && (
    <IconButton
      aria-label="Close"
      onClick={onClose}
      key="close-button"
      className={classes.closeButton}
    >
      <CloseIcon />
    </IconButton>
  )

  return (
    <MuiDialogTitle disableTypography className={classes.styledDialogTitle}>
      <div className={classes.titleAndInfoContainer}>
        <DisplayText weight="semibold" size="lg">
          {title}
        </DisplayText>
        <BodyText>
          Lab tests must be ordered from Rupa to be used with In-Office Kits.
        </BodyText>
        <BodyText>
          If you already have kits that you&apos;d like to use,{" "}
          <Link
            className={classes.styledLink}
            onClick={() => {
              window.Intercom("showNewMessage")
            }}
          >
            message us here.
          </Link>
        </BodyText>
      </div>
      {closeButton}
    </MuiDialogTitle>
  )
}

const ClinicInfoSection = ({ practitioner }) => {
  const classes = useStyles(styles)

  return (
    <>
      <div>
        <BodyText
          weight="semibold"
          className={classes.styledBodyTextSectionTitle}
        >
          Shipping Address
        </BodyText>
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <Grid item xs={12} md={12}>
              <ControlledTextField
                aria-label="Street Address Line 1"
                name="address_street_primary"
                defaultValue={
                  _.isEmpty(practitioner.clinic)
                    ? ""
                    : practitioner.clinic.street
                }
                placeholder="Street Address Line 1"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <ControlledTextField
                aria-label="Street Address Line 2"
                name="address_street_secondary"
                defaultValue=""
                placeholder="Street Address Line 2"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledTextField
                aria-label="City"
                name="address_city"
                defaultValue={
                  _.isEmpty(practitioner.clinic) ? "" : practitioner.clinic.city
                }
                placeholder="City"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledSelectField
                aria-label="State"
                name="address_state"
                defaultValue={
                  _.isEmpty(practitioner.clinic)
                    ? ""
                    : practitioner.clinic.state
                }
                placeholder="State"
              >
                {Object.entries(US_STATES).map(([code, name]) => (
                  <option key={code} value={code}>
                    {name}
                  </option>
                ))}
              </ControlledSelectField>
            </Grid>
            <Grid item xs={12} md={12}>
              <ControlledTextField
                aria-label="Zip Code"
                name="address_zipcode"
                defaultValue={
                  _.isEmpty(practitioner.clinic)
                    ? ""
                    : practitioner.clinic.zipcode
                }
                placeholder="Zip Code"
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

const PractitionerSection = ({
  practitioner,
  practitionerList,
  handlePractitionerChange,
}) => {
  const classes = useStyles(styles)
  if (!practitioner.is_clinic_staff) {
    return <></>
  }

  return (
    <>
      <Divider className={classes.styledDivider} />
      <PractitionerDropdown
        practitioners={practitionerList}
        handleChange={handlePractitionerChange}
      />
    </>
  )
}

const PractitionerDropdown = ({ practitioners, handleChange }) => {
  const classes = useStyles(styles)
  const { watch } = useFormContext()
  const selectedPractitionerId = watch("practitioner")

  useEffect(() => {
    handleChange(selectedPractitionerId)
  }, [selectedPractitionerId, handleChange])

  const practitionerOptions = useMemo(() => {
    return practitioners.map((practitioner) => {
      const id = practitioner.id
      const name = practitioner.user.full_name
      return [id, name]
    })
  }, [practitioners])

  return (
    <>
      <BodyText
        weight="semibold"
        className={classes.styledBodyTextSectionTitle}
      >
        Practitioner Select
      </BodyText>
      <Grid container spacing={2}>
        {practitionerOptions.length === 0 ? (
          <Grid item xs={12}>
            <BodyText>
              At least one practitioner in your clinic needs to upload their
              license and e-signature before you can order kits
            </BodyText>
          </Grid>
        ) : (
          <Grid item xs={12} md={12}>
            <ControlledSelectField
              aria-label="Practitioner Select"
              name={"practitioner"}
              defaultValue=""
              placeholder="Select A Practitioner..."
            >
              {practitionerOptions.map(([id, name], index) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </ControlledSelectField>
          </Grid>
        )}
      </Grid>
    </>
  )
}
const InOfficeKitsSection = ({ append, documentFields }) => {
  const classes = useStyles(styles)

  return (
    <>
      {documentFields.map((field, index) => {
        return (
          <>
            <Divider className={classes.styledDivider} />
            <InOfficeKitsFields key={field.id} index={index} />
          </>
        )
      })}
      <Divider className={classes.styledDivider} />
      <Button
        color="primary"
        aria-label="Add Another Kit"
        startIcon={<img src={AddIcon} className={classes.toggleIcon} alt="" />}
        onClick={() => append()}
        className={classes.styledAddKitButton}
      >
        Add Another Kit
      </Button>
    </>
  )
}

const InOfficeKitsFields = ({ index }) => {
  const { resetField } = useFormContext()
  const classes = useStyles(styles)

  const inOfficeOptions = Object.entries(IN_OFFICE_KIT_LAB_COMPANIES).map(
    ([key, name]) => {
      let optionName = name

      switch (name) {
        case IN_OFFICE_KIT_LAB_COMPANIES.DUTCH:
          optionName = `${name} (${key})`
          break
        case IN_OFFICE_KIT_LAB_COMPANIES.DIAGNOSTIC_SOLUTIONS:
          optionName = "Diagnostic Solutions"
          break
      }

      return [key, optionName]
    }
  )

  const labCompanyField = `${IN_OFFICE_KITS_FIELD_NAME}[${index}].${LAB_COMPANY_FIELD_NAME}`
  const labTestField = `${IN_OFFICE_KITS_FIELD_NAME}[${index}].${LAB_TEST_FIELD_NAME}`
  const labTestQuantityField = `${IN_OFFICE_KITS_FIELD_NAME}[${index}].${LAB_TEST_QUANTITY_FIELD_NAME}`

  const labCompany = useWatch({ name: labCompanyField })
  const isAccessMedicalInOfficeKit = labCompany === "ACCESS"

  useEffect(() => {
    if (!isAccessMedicalInOfficeKit) {
      resetField(labTestField, {
        defaultValue: "",
      })
      return
    }

    resetField(labTestField, {
      defaultValue: "Standard blood lab kits",
    })
  }, [isAccessMedicalInOfficeKit, labTestField])

  return (
    <>
      <BodyText
        weight="semibold"
        className={classes.styledBodyTextSectionTitle}
      >
        In-Office Kit
      </BodyText>
      <Grid container spacing={2}>
        <Grid container item spacing={2}>
          <Grid item xs={12} md={12}>
            <ControlledSelectField
              aria-label="Lab Company"
              name={labCompanyField}
              defaultValue=""
              placeholder="Select Lab Company..."
            >
              {inOfficeOptions.map(([key, name]) => (
                <option key={key} value={key}>
                  {name}
                </option>
              ))}
            </ControlledSelectField>
          </Grid>
          <Grid item xs={9} md={9}>
            {isAccessMedicalInOfficeKit ? (
              <ControlledTextField
                aria-label="Lab Test"
                name={labTestField}
                placeholder="Standard blood lab kits"
                disabled={true}
              />
            ) : (
              <ControlledTextField
                aria-label="Lab Test"
                name={labTestField}
                placeholder="Lab Test"
              />
            )}
          </Grid>
          <Grid item xs={3} md={3}>
            <ControlledTextField
              aria-label="Quantity of Lab Tests"
              name={labTestQuantityField}
              defaultValue=""
              placeholder="Quantity"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default InOfficeKitsModal
