import React from "react"

import {
  Table,
  TableHead,
  TableBody,
  TableContainer,
  Paper,
  TableFooter,
  TableRow,
} from "@material-ui/core"
import { Table as TanstackTable } from "@tanstack/react-table"

import ScrollToAnchor from "app/components/ScrollToAnchor"
import useTableStyles from "app/dashboard/components/TableRenderer/hooks/use-table-styles"

import SearchInput from "./SearchInput"
import TableDataRows from "./TableDataRows"
import TableEmptyRow from "./TableEmptyRow"
import TableHeaderRow from "./TableHeaderRow"
import TablePaginator from "./TablePaginator"
import TablePlaceholderRows from "./TablePlaceholderRows"

export interface BaseTableProps<TData> {
  table: TanstackTable<TData>
}

interface RupaTableProps<TData> extends BaseTableProps<TData> {
  className?: string
  searchTerm?: string
  searchPlaceholder?: string
  emptyTitle?: string
  hidePagination?: boolean
  onSearchTermChange?: (value: string) => void
}

function RupaTable<TData>({
  table,
  className,
  searchTerm,
  searchPlaceholder,
  emptyTitle,
  hidePagination,
  onSearchTermChange,
}: RupaTableProps<TData>) {
  const { meta } = table.options
  const classes = useTableStyles()

  return (
    <>
      {onSearchTermChange && (
        <SearchInput
          placeholder={searchPlaceholder || "Search table..."}
          value={searchTerm}
          onChange={onSearchTermChange}
          isLoading={meta?.isLoading}
        />
      )}
      <ScrollToAnchor
        behavior="smooth"
        block="start"
        component={TableContainer}
        componentProps={{
          className: classes.container,
          component: Paper,
        }}
        top={-150}
        when={table.getState().pagination.pageIndex}
      >
        <Table className={`table-auto ${className}`}>
          <TableHead className="bg-slate-100 py-[10px]">
            <TableHeaderRow table={table} />
          </TableHead>
          <TableBody>
            {meta?.isLoading ? (
              <TablePlaceholderRows table={table} />
            ) : table.getRowModel().rows.length > 0 ? (
              <TableDataRows<TData> table={table} />
            ) : (
              <TableEmptyRow table={table} title={emptyTitle} />
            )}
          </TableBody>

          {!hidePagination && (
            <TableFooter>
              <TableRow className={classes.tablePaginationRow}>
                <TablePaginator table={table} />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </ScrollToAnchor>
    </>
  )
}

export default RupaTable
