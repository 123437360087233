import { useState } from "react"

import NiceModal, { useModal } from "@ebay/nice-modal-react"
import { Dialog, DialogContent } from "@rupahealth/design"

import { ResultsUploadForm } from "./form/ResultsUploadForm"

interface Props {
  patientId: string
}

export const ResultsUploadModal: React.FC<Props> = ({ patientId }) => {
  const modal = useModal()
  const [dirty, setDirty] = useState(false)

  const onModalClose = (force?: boolean) => {
    if (dirty && !force) {
      if (!window.confirm("Are you sure? Unsaved changes will be lost.")) {
        return
      }
    }
    modal.remove()
  }

  return (
    <Dialog
      open={modal.visible}
      onOpenChange={(open) => !open && onModalClose()}
    >
      <DialogContent className="fs-unmask bg-slate-50 p-5 max-h-[90vh] overflow-y-auto max-w-[90vw] md:max-w-2xl border-[4px]">
        <div className="flex flex-col gap-[18px] text-body">
          <span className="font-title font-semibold text-lg">
            Import Lab Results
          </span>
          <div className="relative">
            <div className="w-full transition-opacity opacity-100 h-auto duration-500 delay-100">
              <ResultsUploadForm
                patientId={patientId}
                onClose={onModalClose}
                setDirty={setDirty}
              />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

const ResultsUploadNiceModal = NiceModal.create<Props>(ResultsUploadModal)

export default function useResultsUploadModal() {
  return useModal(ResultsUploadNiceModal)
}
