import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { RootState } from "app/types"
import { showSuccessToast } from "app/utils"

import {
  updatePassword,
  USER_SHOWN_INITIAL_PASSWORD_SET_MESSAGE,
} from "../../auth/store/actions"
import NewPasswordModal from "../login/NewPasswordModal"

/**
 * Modal which forces the user to change their password if user.force_password_change is true.
 */
function PasswordInitModal({ children }: { children?: JSX.Element }) {
  const { force_password_change: forcePasswordChange } = useSelector(
    ({ profile }: RootState) => profile.user || ({} as any)
  )

  const shouldShowInitialPasswordSetMessage: boolean = useSelector(
    ({ auth }: RootState) => auth.user.shouldShowInitialPasswordSetMessage
  )

  useEffect(() => {
    if (shouldShowInitialPasswordSetMessage) {
      dispatch(showSuccessToast({ message: "Your password has been set." }))
      dispatch({ type: USER_SHOWN_INITIAL_PASSWORD_SET_MESSAGE })
    }
  }, [shouldShowInitialPasswordSetMessage])

  const showPasswordReset: boolean =
    !shouldShowInitialPasswordSetMessage && !!forcePasswordChange

  const dispatch = useDispatch()
  const handleChangePassword = async (password) => {
    return dispatch(updatePassword(password))
  }
  return (
    <>
      {showPasswordReset && (
        <NewPasswordModal
          allowClose={false}
          tokenExpired={false}
          open
          onChangePassword={handleChangePassword}
        />
      )}
      {children}
    </>
  )
}

export default PasswordInitModal
