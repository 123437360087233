import { ClinicAccessModalDocument, ResourceType } from "app/types"
import { Document as DocumentResource } from "types/patient-with-documents-v2"

export interface DocumentDescriptors {
  title: string
  subtitle: string
}

export default function useDocumentsDescriptors(
  document: DocumentResource | ClinicAccessModalDocument
): DocumentDescriptors {
  // Determine the attributes based on the document type
  const attributes = "attributes" in document ? document.attributes : document

  // Localize the date, and format in Month Day, Year format
  const formattedDate = attributes.date
    ? new Date(attributes.date).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
    : undefined
  const creatorName = attributes.creator_name
  const labCompanyName = attributes.lab_company_name
  const totalBiomarkerResultsCount = attributes.total_biomarker_results_count
  const resourceType = attributes.resource_type

  if (resourceType === ResourceType.FOOD_PLAN) {
    return {
      title: "Food Plan",
      subtitle: `Created on ${formattedDate}${
        creatorName ? ` by ${creatorName}` : ""
      }`,
    }
  } else if (resourceType === ResourceType.RESULTS_INTERPRETATION) {
    return {
      title: "Results Summary",
      subtitle: `Created on ${formattedDate}${
        creatorName ? ` by ${creatorName}` : ""
      }`,
    }
  } else if (resourceType === ResourceType.ORDER_USER_RESULT) {
    return {
      title: labCompanyName ?? "Lab Result",
      subtitle: formattedDate ?? "",
    }
  } else if (resourceType === ResourceType.IMPORTED_USER_RESULT) {
    return {
      title: `Imported ${labCompanyName ? labCompanyName + " " : ""}Results`,
      subtitle:
        `${formattedDate ?? ""}` +
        `${creatorName ? " • " + creatorName : ""}` +
        `${
          totalBiomarkerResultsCount && totalBiomarkerResultsCount > 0
            ? " • " +
              totalBiomarkerResultsCount +
              " biomarker" +
              (totalBiomarkerResultsCount > 1 ? "s" : "")
            : ""
        }`,
    }
  }
  return {
    title: "Document",
    subtitle: `Created on ${formattedDate}${
      creatorName ? ` by ${creatorName}` : ""
    }`,
  }
}
