import { useState } from "react"

import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import { mergeResources } from "app/swr/helpers/swr"
import useMutateResource from "app/swr/hooks/use-mutate-resource"
import { ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { UserResult, UserResultPostData } from "types/user-result"

export default function useUpdateUserResult(id: string) {
  const handleApiError = useHandleApiError()
  const mutateResource = useMutateResource()

  const [loading, setLoading] = useState(false)

  const identifier: Pick<UserResult, "type" | "id"> = {
    type: "user_result",
    id,
  }

  const patchUserResult = useEventCallback(
    async (patchData: Partial<UserResultPostData>) => {
      setLoading(true)

      const { labCompanyId, sampleTypeId, attributes } = patchData

      const relationships = {
        sample_types: { data: [{ type: "lab_test_type", id: sampleTypeId }] },
      }

      if (labCompanyId && labCompanyId.toLowerCase() !== "other") {
        relationships["lab_company"] = {
          data: { id: labCompanyId, type: "lab_company" },
        }
      } else {
        // Ensure lab company is removed for other lab company name
        relationships["lab_company"] = { data: null }
      }

      const requestData = {
        ...identifier,
        attributes,
        relationships,
      }

      const sendPatchRequest = async () => {
        try {
          const { data } = await resourceRequest<ResourceResponse<UserResult>>({
            method: "patch",
            url: `/user_results/${id}/`,
            data: { data: requestData },
          })

          setLoading(false)

          return data
        } catch (error) {
          handleApiError(error)

          setLoading(false)

          // re-throw so that the caller can handle the error
          throw error
        }
      }

      return mutateResource<UserResult>(identifier, sendPatchRequest, {
        optimisticData(currentData) {
          if (!currentData) {
            // should never be reached, but type error without
            return undefined as any
          }
          return mergeResources(currentData, requestData)
        },
        rollbackOnError: true,
      })
    }
  )

  return {
    patchUserResult,
    loading,
  }
}
