import React, { useEffect } from "react"

import NiceModal, { useModal } from "@ebay/nice-modal-react"
import { Dialog, DialogContent } from "@rupahealth/design"

import {
  trackDocumentAccessEvent,
  DOCUMENT_ACCESS_EVENTS,
} from "app/services/segment"

import ClinicDocumentAccessModalInner from "./components/ClinicDocumentAccessModalInner"
import ClinicDocumentAccessModalProvider, {
  ClinicDocumentAccessModalProviderProps,
  useClinicDocumentAccessModalContext,
} from "./components/ClinicDocumentAccessModalProvider"

interface Props
  extends Omit<ClinicDocumentAccessModalProviderProps, "children"> {}

const ClinicDocumentAccessModal: React.FC<Props> = ({ userId, clinic }) => {
  const { modalOpen, closeModal } = useClinicDocumentAccessModalContext()

  const onOpenChange = (open: boolean) => {
    if (!open) closeModal()
  }

  useEffect(() => {
    if (modalOpen && userId) {
      trackDocumentAccessEvent(
        DOCUMENT_ACCESS_EVENTS.DOCUMENT_ACCESS_USER_CLINIC_ACCESS_MODAL_OPEN,
        {
          user_id: userId,
          clinic_id: clinic.id,
        }
      )
    }
  }, [modalOpen])

  return (
    <Dialog open={modalOpen} onOpenChange={onOpenChange}>
      <DialogContent className="bg-slate-100 p-0 max-h-[90vh] overflow-y-auto max-w-md border-[4px] rounded-xl">
        <ClinicDocumentAccessModalInner />
      </DialogContent>
    </Dialog>
  )
}

const ClinicDocumentAccessNiceModal = NiceModal.create<Props>(
  ({ ...props }) => (
    <ClinicDocumentAccessModalProvider {...props}>
      <ClinicDocumentAccessModal {...props} />
    </ClinicDocumentAccessModalProvider>
  )
)

export function useClinicDocumentAccessModal() {
  return useModal(ClinicDocumentAccessNiceModal)
}
