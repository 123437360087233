import React from "react"

import { Box, CircularProgress, CircularProgressProps } from "@material-ui/core"

import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles(() => ({
  // Tailwind doesn't support some svg properties. stroke-linecap is one of them.
  roundedCircle: { strokeLinecap: "round" },
}))

interface Props extends CircularProgressProps {}

export const RupaCircularProgress: React.FC<Props> = ({ value, ...props }) => {
  const styles = useStyles()

  return (
    <Box className="relative inline-flex">
      <CircularProgress
        variant={value ? "determinate" : "indeterminate"}
        value={value}
        className="relative z-20"
        classes={{ circle: styles.roundedCircle }}
        {...props}
      />
      <CircularProgress
        variant="determinate"
        value={100}
        className="absolute z-10 [&>svg]:text-slate-200"
        {...props}
      />
    </Box>
  )
}
