import { Typography } from "@material-ui/core"

import { ReactComponent as PatientDocumentsIcon } from "app/assets/images/documents/patient-documents-icon.svg"
import useSetQueryParam from "app/hooks/use-set-query-param"

import {
  DOCUMENT_TYPE_QUERY_PARAM_KEY,
  DocumentTypeOption,
  DocumentTypeQueryParamValue,
} from "./DocumentTypeSelect"
import { DocumentTypeOption as DocumentTypeOptionV2 } from "./DocumentTypeSelectV2"

interface Props {
  patientFirstName: string
  totalDocumentCount: number
  selectedDocumentType?: DocumentTypeOption | DocumentTypeOptionV2
}
const PatientDocumentsListEmpty: React.FC<Props> = ({
  patientFirstName,
  totalDocumentCount,
  selectedDocumentType,
}) => {
  const setQueryParam = useSetQueryParam()

  let title: string, subtitle: React.ReactNode
  if (totalDocumentCount > 0 && selectedDocumentType) {
    title = `There aren't any ${selectedDocumentType.label} documents for ${patientFirstName} yet!`
    subtitle = (
      <span
        className="text-primary-500 cursor-pointer"
        onClick={() =>
          setQueryParam(
            DOCUMENT_TYPE_QUERY_PARAM_KEY,
            DocumentTypeQueryParamValue.ALL
          )
        }
      >
        See all of {patientFirstName}'s documents
      </span>
    )
  } else {
    title = `There aren't any documents for ${patientFirstName} yet!`
    subtitle =
      "Documents include: Lab Results, Food Plans, Results Summaries, and more."
  }

  return (
    <div className="bg-slate-50 rounded-lg text-center">
      <div className="flex flex-col content-center items-center py-[30px] px-[7px]">
        <PatientDocumentsIcon />

        <Typography className="font-semibold text-base15">{title}</Typography>
        <Typography className="text-sm">{subtitle}</Typography>
      </div>
    </div>
  )
}

export default PatientDocumentsListEmpty
