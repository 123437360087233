import { useEffect, useMemo } from "react"

import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import useFeatureFlag from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"
import { useUserResultsCollection } from "app/main/results-upload/hooks/use-user-results-collection"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { selectPractitioner } from "app/store/selectors/practitioner.selectors"
import union from "lodash/union"

import { ResultMissingInfo } from "./ResultMissingInfo"
import { useDismissedResultsMissingInfo } from "./use-dismissed-results-missing-info"

interface Props {
  userId: string
}

export const ResultsMissingInfoBanner: React.FC<Props> = (props) => {
  const [simultaneousUploadsEnabled, flagLoading] = useFeatureFlag(
    FeatureFlag.SimultaneousResultsUploads
  )
  const showBanner = useMemo(
    () => !flagLoading && simultaneousUploadsEnabled,
    [flagLoading, simultaneousUploadsEnabled]
  )

  if (!showBanner) {
    return null
  }

  return <ResultsMissingInfoBannerInner {...props} />
}

const ResultsMissingInfoBannerInner: React.FC<Props> = ({ userId }) => {
  const practitioner = useAppSelector(selectPractitioner)
  // TODO: PROD-2462 update for patient uploaded results
  const importedBy = practitioner?.clinic?.name

  const { data, mutate } = useUserResultsCollection({
    hasSnapshots: false,
    // TODO https://linear.app/rupa-health/issue/PROD-2472/adjustments-to-support-patient-upload
    // support patient uploads also
    uploadSource: "practitioner_upload",
    userId,
    writeAccessOnly: true,
  })

  const { dismissedResultIds, dismissResult, setDismissedResultIds } =
    useDismissedResultsMissingInfo({ userId })

  const resultsToShow = useMemo(() => {
    return data.filter((result) => !dismissedResultIds.includes(result.id))
  }, [data, dismissedResultIds])

  useEffect(() => {
    // On unmount, clean up locally stored ids that are no longer in the data, b/c
    // that means they aren't missing info anymore
    return () => {
      const allResultIds = data.map((result) => result.id)
      setDismissedResultIds(union(dismissedResultIds, allResultIds))
    }
  }, [])

  if (!resultsToShow.length) return null

  return (
    <div className="bg-amber-50 border border-amber-200 shadow-sm rounded-xl flex flex-col gap-2.5 p-3">
      <div className="flex items-start sm:items-center gap-2">
        <span>
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="text-amber-500"
          />
        </span>
        <span className="font-semibold text-amber-900">
          Add missing details to see these results in Trends.
        </span>
      </div>
      <div className="bg-white flex flex-col rounded-[10px] border border-slate-200 px-2">
        {resultsToShow.map((userResultIdentifier) => (
          <ResultMissingInfo
            key={userResultIdentifier.id}
            userResultIdentifier={userResultIdentifier}
            importedBy={importedBy ?? ""}
            onDismiss={() => dismissResult(userResultIdentifier.id)}
            onDetailsAdded={() => mutate()}
            className="border-b-[1.5px] border-slate-200 last:border-b-0"
          />
        ))}
      </div>
    </div>
  )
}
