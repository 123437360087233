import { useEffect } from "react"
import { useDispatch } from "react-redux"

import MuiDialogContent from "@material-ui/core/DialogContent"
import { withStyles } from "@material-ui/core/styles"

import ResultsUploadManualEntryBaseForm from "app/main/results-upload/modals/manual-upload/ResultsUploadManualEntryBaseForm"
import ResultsUploadManualEntryBiomarkerResultForm from "app/main/results-upload/modals/manual-upload/ResultsUploadManualEntryBiomarkerResultForm"
import * as Actions from "app/store/actions"

import useManualUploadModalMetadata from "../../hooks/use-manual-upload-modal-metadata"
import ContentCard from "./ContentCard"
import FormSectionTitle from "./FormSectionTitle"

const CustomDialogContent = withStyles(() => ({
  root: {
    paddingTop: "0 !important", // Removes the top padding
  },
}))(MuiDialogContent)

interface Props {
  userResultId: string
}

const UserResultReadOnlyFormContainer = ({ userResultId }: Props) => {
  const dispatch = useDispatch()

  const {
    labTestTypes,
    labCompanies,
    methods,
    existingBiomarkerIds,
    resultFields,
  } = useManualUploadModalMetadata(userResultId, false)

  // Fetch available lab companies and sample types on modal open
  useEffect(() => {
    dispatch(Actions.getLabTestTypeList())
  }, [dispatch])

  return (
    <CustomDialogContent className="bg-gray-100 flex flex-col gap-4 p-0">
      <ContentCard>
        <div className="flex flex-col gap-4">
          <FormSectionTitle number={1} title="Lab Information" />
          <ResultsUploadManualEntryBaseForm
            availableLabCompanies={labCompanies}
            availableLabTestTypes={labTestTypes}
            methods={methods}
            isReadOnly={true}
          />
        </div>
      </ContentCard>

      <ContentCard>
        <div className="scroll-mt-10">
          <FormSectionTitle number={2} title="Results" className="mb-4" />
          <div className="flex flex-col gap-5">
            {resultFields.map((field, index) => (
              <ResultsUploadManualEntryBiomarkerResultForm
                key={field.id ?? `new-${index}`}
                field={field}
                index={index}
                existingBiomarkerIds={existingBiomarkerIds}
                availableLabTestTypes={labTestTypes}
                methods={methods}
                isReadOnly={true}
              />
            ))}
          </div>
        </div>
      </ContentCard>
    </CustomDialogContent>
  )
}

export default UserResultReadOnlyFormContainer
