import useDebounceState from "app/hooks/use-debounce-state"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { DashboardPractitioner } from "types/dashboard/dashboard-practitioner"

import DashboardFilter from "../DashboardFilter"

function useDashboardPractitioners({
  debouncedInputValue,
  value,
}: {
  debouncedInputValue?: string
  value?: string
}) {
  const valuePractitioner = useCachedResource<DashboardPractitioner>(
    value
      ? {
          type: "dashboard_practitioner",
          id: value,
        }
      : undefined
  )

  const search =
    debouncedInputValue &&
    // Exclude searching in this case as this means the patient is already selected and would end up returning only itself.
    debouncedInputValue !== valuePractitioner?.attributes.full_name
      ? debouncedInputValue
      : undefined

  const {
    data: practitionerIdentifiers,
    meta,
    isLoading,
  } = useCollectionSWR(
    "/dashboard/practitioners/",
    {
      params: {
        "filter[search]": search,
        "page[limit]": "20",
        order_by: "user__first_name,user__last_name",
      },
    },
    {
      keepPreviousData: true,
      revalidateIfStale: true,
      revalidateOnFocus: true,
    }
  )

  const practitioners = useCachedCollection<DashboardPractitioner>(
    practitionerIdentifiers
  )

  // If the value is not yet loaded, then we retrieve it independently
  useCollectionSWR(
    value && !valuePractitioner ? `/dashboard/practitioners/` : null,
    {
      params: {
        "filter[id.in]": value,
        "page[limit]": "1",
      },
    }
  )

  return {
    isLoading,
    isMore: meta?.pagination?.count > meta?.pagination?.limit,
    practitioners,
    valuePractitioner,
  }
}

export interface DashboardPractitionerFilterProps {
  onFilterChange: (value?: string) => void
  value?: string
}

export default function DashboardPractitionerFilter({
  onFilterChange,
  value,
}: DashboardPractitionerFilterProps) {
  const [debouncedInputValue, inputValue, setInputValue] = useDebounceState("")
  const { isLoading, isMore, practitioners, valuePractitioner } =
    useDashboardPractitioners({ debouncedInputValue, value })

  return (
    <DashboardFilter
      getOptionLabel={(option) => option.attributes.full_name}
      getOptionValue={(option) => option.id}
      inputValue={inputValue}
      isLoading={isLoading}
      isMore={isMore}
      isMoreText="Only showing the first 20 practitioners. Please refine your search to find the practitioner you are looking for."
      onChange={onFilterChange}
      onInputChange={setInputValue}
      options={practitioners}
      label="Practitioner"
      value={valuePractitioner}
    />
  )
}
