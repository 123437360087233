import { useMemo } from "react"

import axios from "axios"
import useSWR from "swr"

import { getApiBaseUrl } from "app/utils"
import { JobStatus } from "types/job-status"

const POLLING_INTERVAL_SEC = 3
const POLLING_INTERVAL = POLLING_INTERVAL_SEC * 1000

/**
 * Hook that returns the statuses for provided jobIds
 */
export default function useStatuses(ids: string[]) {
  const { data, ...swr } = useSWR<{ data: JobStatus[] }>(
    ids.length ? "/api/normalized/status/" : null,
    async (url) => {
      const response = await axios.get(getApiBaseUrl() + url, {
        params: { ids: ids.join(",") },
      })
      return response.data
    },
    { refreshInterval: POLLING_INTERVAL }
  )

  const jobStatuses = useMemo(() => data?.data ?? [], [data])

  return { jobStatuses, ...swr }
}
