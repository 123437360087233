import { API } from "app/api"
import { handleApiError, handleApiSuccess, showErrorToast } from "app/utils"

export const GET_TEAM_MEMBERS = "[APP] GET_TEAM_MEMBERS"

export function getTeamMembers() {
  return (dispatch) => {
    const request = API.TeamMember.getList()

    return request
      .then(async (response) => {
        await dispatch({
          type: GET_TEAM_MEMBERS,
          payload: response.data.results,
        })
      })
      .catch((error) => dispatch(handleApiError(error)))
  }
}

export function createTeamMember(
  firstName,
  lastName,
  email,
  isClinicStaff,
  primaryPractitionerType,
  customPractitionerType,
  npiNumber,
  onClose,
  stopSubmitting
) {
  const request = API.TeamMember.create({
    first_name: firstName,
    last_name: lastName,
    email,
    is_clinic_staff: isClinicStaff,
    primary_practitioner_type: primaryPractitionerType,
    custom_practitioner_type: customPractitionerType,
    npi_number: npiNumber,
  })

  return (dispatch, getState) =>
    request
      .then(async (response) => {
        dispatch(handleApiSuccess("Team member added"))
        window.Intercom("trackEvent", "team-member-added")

        let teamMembers = getState().teamMember

        if (teamMembers?.length) {
          teamMembers = [...teamMembers, response.data]
        } else {
          teamMembers = [response.data]
        }

        await dispatch({
          type: GET_TEAM_MEMBERS,
          payload: teamMembers,
        })

        // Close Modal
        onClose()
      })
      .catch((error) => {
        const response = error.response
        const data = response && response.data

        // Show a custom error message if a user with email already exists
        if (data && data.code === "duplicate_email") {
          dispatch(showErrorToast({ message: data.detail }))
          stopSubmitting()
        } else {
          dispatch(handleApiError(error))
        }
      })
}

export function reinviteTeamMember(userId, userEmail) {
  return (dispatch) => {
    const request = API.TeamMember.resendInvite({ user_id: userId })

    return request
      .then(async (response) => {
        dispatch(handleApiSuccess(`Reminder email sent to ${userEmail}`))
      })
      .catch((error) => {
        dispatch(handleApiError(error))
      })
  }
}
