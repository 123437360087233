import clsx from "clsx"

import formatDateForHuman from "app/utils/format-date-for-human"

interface Props {
  onClick?: () => void
  Icon: (className: string) => React.ReactNode
  preTitle?: React.ReactNode
  title?: React.ReactNode
  preSubTitle?: React.ReactNode
  subTitle?: React.ReactNode
  date?: string
  actions?: React.ReactNode
}

const PatientDocumentCard: React.FC<Props> = ({
  onClick,
  Icon,
  preTitle,
  title,
  preSubTitle,
  subTitle,
  date,
  actions,
}) => {
  return (
    <div
      className={clsx(
        "flex relative items-center overflow-hidden p-3 gap-3.5 border border-slate-200 rounded-lg bg-white shadow-sm transition duration-300 hover:bg-blue-50 hover:border-primary",
        { "cursor-pointer": !!onClick }
      )}
      onClick={onClick}
    >
      {Icon(
        "min-w-[90px] shadow-xl rounded-sm border-[0.5px] border-slate-300"
      )}

      <div className="flex flex-col gap-3.5">
        <div>
          {!!preTitle && (
            <div className="text-slate-500 text-sm13 font-bold leading-[135%]">
              {preTitle}
            </div>
          )}
          {!!title && (
            <div className="text-primary text-base15 font-semibold">
              {title}
            </div>
          )}
        </div>
        <div>
          {!!preSubTitle && (
            <div className="text-body text-sm13 font-semibold">
              {preSubTitle}
            </div>
          )}
          {!!subTitle && <div className="text-body text-sm13">{subTitle}</div>}

          {!!date && (
            <div className="text-body text-sm13 font-normal">
              {formatDateForHuman(date)}
            </div>
          )}
        </div>
      </div>
      {actions}
    </div>
  )
}

export default PatientDocumentCard
