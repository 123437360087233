import React from "react"
import { useDispatch } from "react-redux"

import NiceModal, { muiDialog, useModal } from "@ebay/nice-modal-react"
import { Dialog, FadeProps, Grow, IconButton } from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"
import CloseIcon from "@material-ui/icons/Close"

import { API } from "app/api"
import { ReactComponent as BabyBlueAirplaneImage } from "app/assets/images/baby-blue-airplane.svg"
import BodyText from "app/components/design-system/BodyText"
import Button from "app/components/design-system/Button"
import useAppSelector from "app/hooks/useAppSelector"
import { shadows } from "app/theme"
import { Patient } from "app/types"
import { handleApiError, showErrorToast, showSuccessToast } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"

interface Props {
  onClose: () => void
  patient: Patient
  existingFileUrl?: string
  existingUserResultId?: string
  existingOrderedResultId?: string
}

const useStyles = makeAppStyles(() => ({
  dialogPaper: {
    margin: 15,
    borderRadius: 12,
    border: "4px solid white",
    boxShadow: shadows["2xl"],
    width: 700,
    transition: "all 500ms",
    paddingTop: 0,
  },
  dialogRoot: {
    // override padding on first child, which appears to be a default MUI style
    "&:first-child": {
      padding: 0,
    },
  },
}))

const GrowTransition = React.forwardRef<unknown, FadeProps>(function Transition(
  props,
  ref
) {
  return <Grow ref={ref} {...props} timeout={500} />
})
const RequestPastResultsModal = ({ onClose, patient }: Props) => {
  const modal = useModal()
  const muiDialogControl = muiDialog(modal)
  const dispatch = useDispatch()
  const classes = useStyles()
  const practitioner = useAppSelector(({ practitioner }) => practitioner)

  const handleSendEmail = async () => {
    try {
      const response = await API.Patient.requestPastResults(
        patient.id,
        practitioner.id
      )

      const responseSuccess = response.status === 200

      if (!responseSuccess) {
        dispatch(
          showErrorToast({
            message: `There was an error sending the results request e-mail.`,
          })
        )
        return
      }

      dispatch(
        showSuccessToast({
          message: `Results request e-mail sent successfully`,
        })
      )

      onClose()
    } catch (error) {
      dispatch(handleApiError(error))
      return
    }
  }

  return (
    <>
      <Dialog
        {...muiDialogControl}
        disableEnforceFocus
        disableEscapeKeyDown
        fullWidth
        maxWidth={"xs"}
        classes={{
          paper: classes.dialogPaper,
        }}
        TransitionComponent={GrowTransition}
      >
        <MuiDialogContent
          className={`flex flex-col p-2 rounded-b-lg ${classes.dialogRoot}`}
        >
          <div className="bg-blue-gray-100 rounded-t-lg pb-6">
            <div className="flex justify-end">
              <IconButton
                aria-label="close"
                onClick={onClose}
                key="close-button"
                className="text-body"
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div className="text-center px-4 mx-auto">
              <div className="flex justify-center">
                <BabyBlueAirplaneImage className="w-23 h-23 mb-5" />
              </div>

              <BodyText size="2xl" className="mb-2" weight="semibold">
                Ask {patient.first_name} for Past Results
              </BodyText>
              <BodyText size="md">
                We'll send {patient.first_name} a secure link to share <br />
                their past results. They'll show right up in Trends!
              </BodyText>
            </div>
          </div>

          <div className="flex justify-around p-5 space-x-5">
            <Button
              variant="secondary"
              color="secondary"
              className="px-8 w-full"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              color="primary"
              className="px-8 w-full"
              onClick={handleSendEmail}
            >
              Send
            </Button>
          </div>
        </MuiDialogContent>
      </Dialog>
    </>
  )
}

const RequestPastResultsNiceModal = NiceModal.create<Props>(
  RequestPastResultsModal
)

export default function useRequestPastResultsModal() {
  return useModal(RequestPastResultsNiceModal)
}
