import usePatientsCollection from "app/components/Clients/usePatientsCollection"
import useDebounceState from "app/hooks/use-debounce-state"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { DashboardPatient } from "types/dashboard/dashboard-patient"

import DashboardFilter from "../DashboardFilter"

// The maximum number of patients to show in the filter at once
const MAX_PATIENTS_TO_SHOW = 20

export interface DashboardPatientFilterProps {
  includeStorefront?: boolean
  onFilterChange: (value?: string) => void
  value?: string
}

function DashboardPatientFilter({
  includeStorefront = false,
  onFilterChange,
  value,
}: DashboardPatientFilterProps) {
  const [debouncedInputValue, inputValue, setInputValue] = useDebounceState("")

  const valuePatient = useCachedResource<DashboardPatient>(
    value ? { type: "dashboard_patient", id: value } : undefined
  )

  const search =
    debouncedInputValue &&
    // Exclude searching in this case as this means the patient is already selected and would end up returning only itself.
    debouncedInputValue !== valuePatient?.attributes.full_name
      ? debouncedInputValue
      : undefined

  const { isLoading, patients, meta } = usePatientsCollection({
    filters: {
      orderType: includeStorefront ? "storefront" : "non-storefront",
      search,
    },
    pagination: { pageIndex: 0, pageSize: MAX_PATIENTS_TO_SHOW },
  })

  // If the value is not yet loaded, then we retrieve it independently
  useCollectionSWR(value && !valuePatient ? `/dashboard/patients/` : null, {
    params: {
      "filter[order_type]": includeStorefront ? "storefront" : "non-storefront",
      "filter[id.in]": value,
      "page[limit]": "1",
    },
  })

  return (
    <DashboardFilter
      getOptionLabel={(option) => option.attributes.full_name}
      getOptionValue={(option) => option.id}
      inputValue={inputValue}
      isLoading={isLoading}
      isMore={meta?.pagination?.count > MAX_PATIENTS_TO_SHOW}
      isMoreText={`Only showing the first ${MAX_PATIENTS_TO_SHOW} patients. Please refine your search to find the patient you are looking for.`}
      label="Patient"
      onChange={onFilterChange}
      onInputChange={setInputValue}
      options={patients}
      value={valuePatient}
    />
  )
}

export default DashboardPatientFilter
