import { useDispatch } from "react-redux"

import { Theme, makeStyles } from "@material-ui/core"

import LinkIcon from "app/assets/icons/link.svg"
import PauseIcon from "app/assets/icons/pause.svg"
import PlayIcon from "app/assets/icons/play.svg"
import ScreenIcon from "app/assets/icons/screen.svg"
import PageToolbar from "app/components/PageToolbar"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import usePhysicianServicesOptInModal from "app/components/modals/PhysicianServicesOptInModal/use-physician-services-opt-in-modal"
import { VENDOR_PHYSICIAN_AUTHORIZATION_LABEL } from "app/constants"
import { StorefrontStatus } from "app/constants.typed"
import { StorefrontProductType, StorefrontType } from "app/hooks/use-resource"
import { trackLabShopEvent } from "app/services/segment"
import { primaryColor } from "app/theme"
import { DefaultPractitionerOption } from "app/types"
import { getBaseUrl, showErrorToast, showSuccessToast } from "app/utils"

import { LabShopEvent } from "../constants"

const styles = (theme: Theme) => ({
  disabledButtonOverride: {
    "&.Mui-disabled": {
      pointerEvents: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 8,
    },
  },
  pauseIcon: { position: "relative" as any, top: "-1px" },
  link: {
    color: primaryColor,
    fontWeight: 600,
  },
  copyLinkBtn: {
    [theme.breakpoints.down("sm")]: {
      alignSelf: "start",
    },
  },
})

const useStyles = makeStyles(styles)

interface Props {
  storefront: StorefrontType
  products: StorefrontProductType[]
  selectedOrderingPhysician: DefaultPractitionerOption | undefined
  isDirty: boolean
  isFormValid: boolean
  handleSubmitPauseButton: () => void
  handleSaveDraft: () => void
  usePractitionerLicense: boolean
  currentPractitionerIsClinicStaff: boolean
}

const EcommerceToolbar = ({
  storefront,
  products,
  selectedOrderingPhysician,
  isDirty,
  isFormValid,
  handleSubmitPauseButton,
  handleSaveDraft,
  usePractitionerLicense,
  currentPractitionerIsClinicStaff,
}: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const physicianServicesOptInModal = usePhysicianServicesOptInModal()

  const isActionButtonForSuccess =
    storefront.attributes.status === StorefrontStatus.Disabled ||
    storefront.attributes.status === StorefrontStatus.Paused ||
    isDirty

  const handleCopy = (event) => {
    event.preventDefault()

    const storefrontUrl = `${getBaseUrl()}/store/${storefront?.id}`

    // Use the `navigator.clipboard` API to asynchronously copy the text
    navigator.clipboard
      .writeText(storefrontUrl)
      .then(() => {
        dispatch(
          showSuccessToast({
            message: "Succesfully copied LabShop link",
            options: { autoHideDuration: 5000 },
          })
        )

        trackLabShopEvent(LabShopEvent.COPY_LABSHOP_URL, {
          storefrontId: storefront?.id,
        })
      })
      .catch(() =>
        dispatch(showErrorToast({ message: "Unable to copy the LabShop link" }))
      )
  }

  const handleVisitYourStoreClick = () => {
    trackLabShopEvent(LabShopEvent.VISIT_LABSHOP_URL, {
      storefrontId: storefront?.id,
    })

    window.open(`${getBaseUrl()}/store/${storefront.id}`, "_blank")
  }

  const hasNoProducts =
    !storefront.relationships.draft_lab_tests?.data.length &&
    !storefront.relationships.draft_bundles?.data.length &&
    !products.length
  const showSaveDraftButton =
    isDirty && !Boolean(storefront.attributes.date_published) && hasNoProducts

  const physicianServicesLink = (
    <span
      className={"font-semibold text-primary cursor-pointer"}
      onClick={() => {
        physicianServicesOptInModal.show({
          defaultPhysAuthOn: false,
          returnToDescriptor: "Labshop",
          onClose: () => {
            physicianServicesOptInModal.remove()
          },
        })
      }}
    >
      Add {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}
    </span>
  )

  const physicianServicesTooltipText = currentPractitionerIsClinicStaff ? (
    <div>
      {selectedOrderingPhysician?.practitioner.user.full_name
        ? selectedOrderingPhysician?.practitioner.user.full_name
        : "Practitioner"}{" "}
      must have {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} enabled to set up this
      store. They can turn this on in their Settings.
    </div>
  ) : (
    <div>
      {selectedOrderingPhysician?.practitioner.user.full_name
        ? selectedOrderingPhysician?.practitioner.user.full_name
        : "Practitioner"}{" "}
      must have {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} enabled to set up this
      store. {physicianServicesLink} or select a different practitioner.
    </div>
  )

  const renderTooltipTitle = () => {
    let title
    if (
      !selectedOrderingPhysician?.practitioner
        .vendor_physician_authorization_enabled &&
      !usePractitionerLicense
    ) {
      title = (
        <div>
          <BodyText>{physicianServicesTooltipText}</BodyText>
        </div>
      )
    } else if (
      (!isDirty && !Boolean(storefront.attributes.date_published)) ||
      showSaveDraftButton
    ) {
      title =
        "Please make changes and add lab tests or bundles in order to publish your LabShop"
    } else if (!isFormValid) {
      title =
        "Please correct errors in your form in order to publish your LabShop"
    } else {
      title = ""
    }

    return title
  }

  const tooltipTitle = renderTooltipTitle()

  return (
    <PageToolbar
      title={"Store Setup"}
      fullBorder={true}
      topContainerStyle={{ paddingTop: "17px", paddingBottom: "16px" }}
    >
      {storefront.attributes.status === StorefrontStatus.Published && (
        <DesignSystemButton
          color="text"
          startIcon={LinkIcon}
          className={classes.copyLinkBtn}
          onClick={handleCopy}
        >
          Copy Link
        </DesignSystemButton>
      )}
      {storefront.attributes.status === StorefrontStatus.Published && (
        <DesignSystemButton
          color="noaction"
          startIcon={ScreenIcon}
          onClick={handleVisitYourStoreClick}
        >
          Visit Your Store
        </DesignSystemButton>
      )}
      {showSaveDraftButton && (
        <DesignSystemButton
          color="secondary"
          disabled={!isDirty && !Boolean(storefront.attributes.date_published)}
          onClick={handleSaveDraft}
          className={classes.disabledButtonOverride}
        >
          Save Draft
        </DesignSystemButton>
      )}
      <Tooltip title={tooltipTitle} interactive placement="bottom" arrow>
        <DesignSystemButton
          color={isActionButtonForSuccess ? "success" : "secondary"}
          startIcon={
            isActionButtonForSuccess ? (
              <img
                src={PlayIcon}
                className={classes.pauseIcon}
                alt="play icon"
              />
            ) : (
              <img
                src={PauseIcon}
                className={classes.pauseIcon}
                alt="pause icon"
              />
            )
          }
          disabled={
            (!isDirty && !Boolean(storefront.attributes.date_published)) ||
            (!selectedOrderingPhysician?.practitioner
              .vendor_physician_authorization_enabled &&
              !usePractitionerLicense) ||
            showSaveDraftButton ||
            !isFormValid
          }
          onClick={handleSubmitPauseButton}
          className={classes.disabledButtonOverride}
        >
          {storefront.attributes.status === StorefrontStatus.Disabled ||
          storefront.attributes.status === StorefrontStatus.Paused
            ? "Go Live"
            : !isDirty
            ? "Pause Store"
            : "Save & Publish"}
        </DesignSystemButton>
      </Tooltip>
    </PageToolbar>
  )
}

export default EcommerceToolbar
