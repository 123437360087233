import { faChartLineUp, faCircleCheck } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface Props {
  title: string
}

const PendingTrackingInTrendsSubTitle = ({ title }: Props) => {
  return (
    <span className="flex items-center gap-[3px]">
      <div className="bg-amber-500 rounded-full p-[4px] flex items-center justify-center">
        <FontAwesomeIcon
          icon={faChartLineUp}
          className="text-white text-[8px]"
        />
      </div>
      <span className="text-sm13 font-semibold">{title}</span>
    </span>
  )
}

export const TrackingInTrendsSubTitle = ({ title }: Props) => {
  return (
    <span className="text-sm13 font-semibold">
      <FontAwesomeIcon icon={faCircleCheck} className="text-emerald-500" />{" "}
      {title}
    </span>
  )
}

export default PendingTrackingInTrendsSubTitle
