import { useEffect, useMemo, useRef, useState } from "react"
import { useDispatch } from "react-redux"

import { FormProvider } from "react-hook-form"

import { CircularProgress } from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"
import { withStyles } from "@material-ui/core/styles"
import { Button, cn } from "@rupahealth/design"

import { ReactComponent as ReviewIcon } from "app/assets/images/results-over-time/review.svg"
import { ReactComponent as StarsIcon } from "app/assets/images/results-over-time/stars.svg"
import Tooltip from "app/components/Tooltip"
import useCreateSnapshot from "app/main/results-upload/hooks/use-create-snapshot"
import { BASE_FORM_FIELDS } from "app/main/results-upload/hooks/use-results-upload-manual-entry-form"
import ResultUploadManualEntryAddBiomarkerResultButton from "app/main/results-upload/modals/manual-upload/ResultsUploadManualEntryAddBiomarkerResultButton"
import ResultsUploadManualEntryBaseForm from "app/main/results-upload/modals/manual-upload/ResultsUploadManualEntryBaseForm"
import ResultsUploadManualEntryBiomarkerResultForm from "app/main/results-upload/modals/manual-upload/ResultsUploadManualEntryBiomarkerResultForm"
import ResultsUploadManualEntryNewBiomarkerLoadingState from "app/main/results-upload/modals/manual-upload/ResultsUploadManualEntryNewBiomarkerLoadingState"
import { useUserResultContext } from "app/main/results-upload/providers/UserResultDataProvider"
import CheckboxInput from "app/patient-portal/fields/components/CheckboxInput"
import * as Actions from "app/store/actions"
import { colors } from "app/theme"
import { Biomarker } from "types/biomarker"

import useManualUploadModalMetadata from "../../hooks/use-manual-upload-modal-metadata"
import ContentCard from "./ContentCard"
import FormSectionTitle from "./FormSectionTitle"
import ResultsUploadStatusBanner from "./ResultsUploadStatusBanner"

const CustomDialogContent = withStyles(() => ({
  root: {
    paddingTop: "0 !important", // Removes the top padding
  },
}))(MuiDialogContent)

interface Props {
  userResultId: string
  onSnapshotCreate: () => void
  isManualEntry?: boolean
  showSuccessStatusBanner?: boolean
}

/**
 * Must be used within the UserResultProvider context
 */

const UserResultEditableFormContainer = ({
  userResultId,
  onSnapshotCreate,
  showSuccessStatusBanner,
  isManualEntry,
}: Props) => {
  const dispatch = useDispatch()

  const {
    userResult,
    userBiomarkerResults,
    labTestTypes,
    labCompanies,
    methods,
    existingBiomarkerIds,
    resultFields,
    enablePartTwoForm,
    setEnablePartTwoForm,
  } = useManualUploadModalMetadata(userResultId, true)

  const {
    userBiomarkerResultsSWR: { isLoading: isUserBiomarkerResultsLoading },
    createUserBiomarkerResult,
    isCreateUserBiomarkerResultLoading,
    updateUserBiomarkerResult,
    onDeleteUserBiomarkerResult,
    bulkUpdateUserBiomarkerResultsSampleType,
  } = useUserResultContext()

  const [hasReviewedExtractedInfo, setHasReviewedExtractedInfo] =
    useState(false)

  const [
    indexOfDeleteBiomarkerResultLoading,
    setIndexOfDeleteBiomarkerResultLoading,
  ] = useState<number | null>(null)

  const partTwoRef = useRef<any>(null)

  const { createSnapshotFromUserResult, isLoading: isCreateSnapshotLoading } =
    useCreateSnapshot()

  const watchSampleTypeValue = methods.watch("sample_type")

  const { errors } = methods.formState

  const bulkUpdateSampleType = () => {
    const allIdsNull = resultFields.every(
      (field) => field?.relationships?.sample_type?.data?.id === null
    )
    // Update if all sample type ids are null and we have a main sample type value to use to update
    if (watchSampleTypeValue && allIdsNull) {
      bulkUpdateUserBiomarkerResultsSampleType(watchSampleTypeValue)
      resultFields.forEach((_field, index) => {
        methods.setValue(`results.${index}.relationships.sample_type.data`, {
          id: watchSampleTypeValue,
          type: "lab_test_type",
        })
      })
      methods.trigger()
    }
  }

  const handleBiomarkerSelect = async (biomarker: Biomarker) => {
    await saveDirtyBiomarkerResults()
    await createUserBiomarkerResult({
      biomarkerId: biomarker.id,
      userResultId,
      sampleTypeId: methods.getValues("sample_type"),
    })
  }

  const handleBiomarkerResultDelete = async (index: number) => {
    setIndexOfDeleteBiomarkerResultLoading(index)

    await onDeleteUserBiomarkerResult(userBiomarkerResults[index]?.id)

    setIndexOfDeleteBiomarkerResultLoading(null)
  }

  const isBiomarkerFieldDirty = (userBiomarkerField, userBiomarkerResult) => {
    return (
      userBiomarkerField.attributes.value !==
        userBiomarkerResult.attributes.value ||
      userBiomarkerField.attributes.units !==
        userBiomarkerResult.attributes.units ||
      userBiomarkerField.attributes.normal_min !==
        userBiomarkerResult.attributes.normal_min ||
      userBiomarkerField.attributes.normal_max !==
        userBiomarkerResult.attributes.normal_max ||
      userBiomarkerField.relationships.sample_type.data.id !==
        userBiomarkerResult.relationships.sample_type.data.id ||
      userBiomarkerField.relationships.biomarker.data.id !==
        userBiomarkerResult.relationships.biomarker.data.id
    )
  }

  const saveDirtyBiomarkerResults = async () => {
    await resultFields.forEach(async (field, index) => {
      if (isBiomarkerFieldDirty(field, userBiomarkerResults[index])) {
        await updateUserBiomarkerResult(userBiomarkerResults[index].id, field)
      }
    })
  }

  const handleSaveResults = async () => {
    // Revalidate one more time before saving and creating snapshot
    await methods.trigger()

    if (methods.formState.isValid) {
      await saveDirtyBiomarkerResults()

      await createSnapshotFromUserResult(userResultId)

      onSnapshotCreate()
    }
  }

  const watchBaseForm = methods.watch(BASE_FORM_FIELDS)

  const isBaseFormValid = useMemo(() => {
    return BASE_FORM_FIELDS.every(
      (field) => !methods.getFieldState(field).invalid
    )
  }, [watchBaseForm])

  useEffect(() => {
    if (!isBaseFormValid) {
      setEnablePartTwoForm(false)
    }
  }, [isBaseFormValid])

  useEffect(() => {
    if (userBiomarkerResults?.length !== resultFields.length) {
      methods.setValue("results", userBiomarkerResults)
    }
  }, [userBiomarkerResults, resultFields])

  // Fetch available lab companies and sample types on modal open
  useEffect(() => {
    dispatch(Actions.getLabTestTypeList())
  }, [])

  // Recursive function used to flatten error object keys so we can scroll to the proper element.
  const flattenErrorKeys = (obj, parent = "") => {
    // Check if the current segment is an object or an array, and handle accordingly
    if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        if (obj[i] !== null && obj[i] !== undefined) {
          // Only process non-null/undefined elements
          let result = flattenErrorKeys(obj[i], `${parent}[${i}]`)
          if (result) return result // Return the first full path found
        }
      }
    } else if (typeof obj === "object" && obj !== null) {
      for (const key of Object.keys(obj)) {
        let propName = parent ? `${parent}.${key}` : key
        if (key === "attributes" || key === "relationships") {
          // Continue one level deeper after 'attributes' or 'relationships', but do not go beyond
          for (const childKey of Object.keys(obj[key])) {
            return `${propName}.${childKey}` // Return the path including the first child key
          }
        }
        let result = flattenErrorKeys(obj[key], propName)
        if (result) return result // Return the first full path found
      }
    } else {
      // Base case: return the path when a primitive type or final object is reached
      return parent
    }
    return null // If no valid path is found, return null
  }

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const inputName = flattenErrorKeys(errors)
      const errorElement = document.querySelector(`[name="${inputName}"]`)

      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" })
      }
    }
  }, [errors])

  const numScannedBiomarkers = useMemo(() => {
    return userBiomarkerResults?.reduce(
      (count, ubr) =>
        ubr.relationships.matched_biomarker_result?.data ? count + 1 : count,
      0
    )
  }, [userBiomarkerResults])

  const blockedByNotReviewingExtractedInfo = useMemo(() => {
    return !hasReviewedExtractedInfo && !isManualEntry
  }, [hasReviewedExtractedInfo, isManualEntry])

  const partTwoAvailable = useMemo(() => {
    return (
      isBaseFormValid && !isUserBiomarkerResultsLoading && enablePartTwoForm
    )
  }, [isBaseFormValid, isUserBiomarkerResultsLoading, enablePartTwoForm])

  return (
    <FormProvider {...methods}>
      <form onSubmit={(e) => e.preventDefault()} noValidate>
        <CustomDialogContent className="bg-gray-100 flex flex-col gap-4 p-0">
          {/* Base Form Data */}
          <ContentCard>
            <div className="flex flex-col gap-4">
              <FormSectionTitle number={1} title="Lab Information" />
              {showSuccessStatusBanner &&
                Boolean(userBiomarkerResults?.length) && (
                  <ResultsUploadStatusBanner
                    containerClassName="bg-emerald-50 border-emeral-300 py-2 px-[7px]"
                    textColor="text-emerald-900"
                    icon={<StarsIcon width={17} fill={colors.emerald[900]} />}
                    title={`${userBiomarkerResults.length} Biomarkers Extracted!`}
                    text="We need just a bit more info to continue."
                  />
                )}
              <ResultsUploadManualEntryBaseForm
                availableLabCompanies={labCompanies}
                availableLabTestTypes={labTestTypes}
                methods={methods}
              />
              {!enablePartTwoForm && (
                <Button
                  variant="primary"
                  className="w-full"
                  onClick={async () => {
                    // Revalidate one more time before moving to part two
                    methods.trigger(BASE_FORM_FIELDS).then((_isValid) => {
                      if (isBaseFormValid) {
                        setEnablePartTwoForm(true)
                        bulkUpdateSampleType()
                        partTwoRef.current?.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        })
                      }
                    })
                  }}
                >
                  Continue
                </Button>
              )}
            </div>
          </ContentCard>

          {/* Biomarker Results */}
          <ContentCard>
            <div
              ref={partTwoRef}
              className={cn(
                { "opacity-50 pointer-events-none": !partTwoAvailable },
                "scroll-mt-10"
              )}
            >
              <FormSectionTitle
                number={2}
                title="Enter Results"
                className="mb-4"
              />
              {!Boolean(isManualEntry) && (
                <div className="mb-4">
                  {numScannedBiomarkers > 0 ? (
                    <ResultsUploadStatusBanner
                      containerClassName="bg-indigo-50 border-indigo-300 py-2 px-[7px]"
                      textColor="text-indigo-900"
                      icon={
                        <ReviewIcon
                          width={17}
                          fill={colors.indigo[900]}
                          className="relative top-[2px]"
                        />
                      }
                      title="Review Your Results"
                      text="Please review the results below to make sure they were extracted accurately!"
                    />
                  ) : (
                    <ResultsUploadStatusBanner
                      containerClassName="bg-yellow-50 border-yellow-300 p-3"
                      textColor="text-yellow-900"
                      icon={null}
                      title={null}
                      text={
                        "Unfortunately, we weren't able to extract any values from the provided PDF. Please input the values manually."
                      }
                    />
                  )}
                </div>
              )}
              <div className="flex flex-col gap-5 mb-5">
                {resultFields.map((field, index) => {
                  return (
                    <ResultsUploadManualEntryBiomarkerResultForm
                      key={field.id ?? `new-${index}`}
                      field={field}
                      index={index}
                      onDelete={() => handleBiomarkerResultDelete(index)}
                      isDeleteLoading={
                        indexOfDeleteBiomarkerResultLoading === index
                      }
                      existingBiomarkerIds={existingBiomarkerIds}
                      availableLabTestTypes={labTestTypes}
                      methods={methods}
                    />
                  )
                })}
              </div>

              {(isCreateUserBiomarkerResultLoading ||
                isUserBiomarkerResultsLoading) && (
                <div className="mb-5">
                  <ResultsUploadManualEntryNewBiomarkerLoadingState />
                </div>
              )}

              {userResult && !isUserBiomarkerResultsLoading && (
                <ResultUploadManualEntryAddBiomarkerResultButton
                  existingBiomarkerIds={existingBiomarkerIds}
                  onBiomarkerSelect={handleBiomarkerSelect}
                  disabled={!enablePartTwoForm}
                />
              )}
            </div>
          </ContentCard>

          {enablePartTwoForm && !isManualEntry && (
            <ContentCard>
              <CheckboxInput
                color="primary"
                value={hasReviewedExtractedInfo}
                onChange={() =>
                  setHasReviewedExtractedInfo(!hasReviewedExtractedInfo)
                }
                label={
                  <div className="text-rupa-navy font-open-sans text-sm font-semibold">
                    I've reviewed the extracted information and confirm it's
                    accurate.
                    <span className="text-red-600">*</span>
                  </div>
                }
              />
            </ContentCard>
          )}

          {enablePartTwoForm && (
            <Tooltip
              title="Please review the extracted information before saving your results."
              disableHoverListener={!blockedByNotReviewingExtractedInfo}
              placement="top"
              arrow
            >
              <span>
                <Button
                  variant="primary"
                  className="w-full"
                  disabled={
                    blockedByNotReviewingExtractedInfo ||
                    isCreateSnapshotLoading ||
                    !Boolean(userBiomarkerResults?.length)
                  }
                  onClick={handleSaveResults}
                >
                  Save Results{" "}
                  {isCreateSnapshotLoading && <CircularProgress size={15} />}
                </Button>
              </span>
            </Tooltip>
          )}
        </CustomDialogContent>
      </form>
    </FormProvider>
  )
}

export default UserResultEditableFormContainer
