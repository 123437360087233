import { Dispatch, SetStateAction, useState } from "react"

import { isEqual } from "lodash"
import { useDebounce, useMountedState } from "react-use"

export function useDebounceState<S>(
  initialState: S | (() => S)
): [S, S, Dispatch<SetStateAction<S>>]
export function useDebounceState<S>(
  initialState: S | (() => S),
  ms: number
): [S, S, Dispatch<SetStateAction<S>>]
export function useDebounceState<S = undefined>(
  initialState: undefined,
  ms: number
): [S | undefined, S | undefined, Dispatch<SetStateAction<S | undefined>>]
export function useDebounceState<S = undefined>(): [
  S | undefined,
  S | undefined,
  Dispatch<SetStateAction<S | undefined>>
]
/**
 * Returns a debounced stateful value, the non-debounced state value, and a function to update it.
 *
 * @param initialState the initial state
 * @param ms the milleseconds to debounce before the returned state value update is reflected
 * @returns the debounce state value, the state value, and update function
 */
export function useDebounceState<S>(initialState?: S | (() => S), ms?: number) {
  const isMounted = useMountedState()
  const [state, setState] = useState(initialState)
  const [debounceState, setDebounceState] = useState(state)

  useDebounce(
    () => {
      if (!isMounted()) {
        return
      }

      if (!isEqual(state, debounceState)) {
        setDebounceState(state)
      }
    },
    ms || 250,
    [state]
  )

  return [debounceState, state, setState]
}

export default useDebounceState
