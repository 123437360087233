import { default as fakePDFThumbnail } from "app/assets/images/documents/pdf-thumbnail.svg"
import { validateFileMime } from "app/components/upload/helpers"

export const RESULTS_UPLOAD_IMAGE_TYPES = ".jpg,.jpeg,.png,.bmp,.tiff"
export const RESULTS_UPLOAD_FILE_TYPES = `.pdf,${RESULTS_UPLOAD_IMAGE_TYPES}`

export function fileIsValidResultsUploadImage(file: File) {
  return validateFileMime(file, RESULTS_UPLOAD_IMAGE_TYPES)
}

export function getFileThumbnail(file: File) {
  if (fileIsValidResultsUploadImage(file)) {
    return URL.createObjectURL(file)
  }
  // It's difficult to get a thumbnail for an uploaded PDF, so we'll just use a generic one for now
  return fakePDFThumbnail
}
