import { Fragment } from "react"

import { TableRow, TableCell } from "@material-ui/core"
import { flexRender } from "@tanstack/react-table"

import { BaseTableProps } from "./RupaTable"

function TableDataRows<TData>({ table }: BaseTableProps<TData>) {
  return (
    <>
      {table.getRowModel().rows.map((row) => (
        <Fragment key={row.id}>
          <TableRow className="bg-white">
            {row.getVisibleCells().map((cell, idx) => (
              <TableCell
                key={`${cell.id}-cell-${idx}`}
                className="break-words py-[14px]"
                style={{
                  ...cell.column.columnDef.meta?.cellStyle,
                  ...cell.column.columnDef.meta?.dataCellStyle,
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        </Fragment>
      ))}
    </>
  )
}

export default TableDataRows
