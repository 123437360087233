import { useMemo } from "react"

import clsx from "clsx"
import { useHistory } from "react-router-dom"

import { useMediaQuery } from "@material-ui/core"

import { UserPaths } from "app/Routes/paths"
import PageToolbar from "app/components/PageToolbar"
import useFeatureFlag from "app/hooks/use-feature-flag"
import useQuery from "app/hooks/use-query"
import useAppSelector from "app/hooks/useAppSelector"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { ClinicFeatures, Practitioner } from "app/types"

import Alerts from "../dashboard/Alerts"
import MissingSignatureOrLicenseBanner from "../dashboard/MissingSignatureOrLicenseBanner"
import NoNPIBanner from "../dashboard/NoNPIBanner"
import VerifyEmailBanner from "../dashboard/VerifyEmailBanner"
import IOKShareFeedbackButton from "./IOKShareFeedbackButton"
import IOKWatchTutorialButton from "./IOKWatchTutorialButton"
import InOfficeKitTabs, { InOfficeKitTabId } from "./InOfficeKitsTabs"
import InventoryProvider from "./inventory/InventoryDataProvider"
import InventoryTab from "./inventory/InventoryTab"
import IokStartOrderButton from "./order-history/IokStartOrderButton"
import OrderHistoryDataProvider from "./order-history/OrderHistoryDataProvider"
import OrderHistoryTab from "./order-history/OrderHistoryTab"

const InOfficeKitsPage: React.FC = () => {
  const history = useHistory()
  const query = useQuery()
  const activeTab = useMemo(() => query.get("tab"), [query])
  const [showInventory] = useFeatureFlag(FeatureFlag.InOfficeKitInventory)
  const [showAlerts] = useFeatureFlag(FeatureFlag.ShowAlertsOnAllPages)
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"))
  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const inOfficeKitsActive = practitioner?.clinic?.clinic_features?.includes(
    ClinicFeatures.ALLOW_IN_OFFICE_KITS
  )
  if (inOfficeKitsActive === undefined) {
    // Return null if the practitioner & clinic is not loaded
    return null
  } else if (inOfficeKitsActive === false) {
    // Redirect to clinic page if the clinic does not have in-office kits enabled
    history.push(UserPaths.CLINIC)
  }

  return (
    <>
      <PageToolbar title="In-Office Supplies">
        {showInventory && (
          <div className="flex flex-wrap justify-center">
            <IOKWatchTutorialButton />
            <IOKShareFeedbackButton />
          </div>
        )}
      </PageToolbar>
      <div className="px-6 mb-20">
        <div className="py-3">
          <NoNPIBanner />
          <VerifyEmailBanner displayText="You'll need to verify your email address before ordering In-Office Supplies. " />
          <MissingSignatureOrLicenseBanner
            baseDisplayText="To place an order for In-Office Supplies, "
            showWithoutBlockedOrders
          />
          {showAlerts && <Alerts />}
        </div>
        {/* This provider would ideally only wrap OrderHistoryTab but the StartOrderButton & modal rely on it */}
        <OrderHistoryDataProvider activeTab={activeTab}>
          <div
            className={clsx("flex pb-3", {
              "flex-col gap-3": isMobile,
              "flex-row justify-between items-center": !isMobile,
            })}
          >
            <InOfficeKitTabs />
            {activeTab === InOfficeKitTabId.ORDER_HISTORY && (
              <IokStartOrderButton />
            )}
          </div>

          <InOfficeKitsPageInner
            activeTab={activeTab}
            practitioner={practitioner}
          />
        </OrderHistoryDataProvider>
      </div>
    </>
  )
}

interface InnerProps {
  activeTab: string | null
  practitioner: Practitioner
}

const InOfficeKitsPageInner: React.FC<InnerProps> = ({
  activeTab,
  practitioner,
}) => {
  switch (activeTab) {
    case InOfficeKitTabId.INVENTORY:
      return (
        <InventoryProvider practitioner={practitioner}>
          <InventoryTab practitioner={practitioner} />
        </InventoryProvider>
      )
    case InOfficeKitTabId.ORDER_HISTORY:
    default:
      return <OrderHistoryTab practitioner={practitioner} />
  }
}

export default InOfficeKitsPage
