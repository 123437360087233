import { DialogTitle } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

import { ReactComponent as PersonIcon } from "app/assets/icons/document-access/person.svg"
import Button from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { UserClinicAccessSharingStatus } from "app/types"

import { useClinicDocumentAccessModalContext } from "./ClinicDocumentAccessModalProvider"
import ClinicDocumentAccessOption from "./ClinicDocumentAccessOption"

const ClinicDocumentAccessModalInner: React.FC = () => {
  const { clinic, isLoading, isSubmitting, onSubmit, documents } =
    useClinicDocumentAccessModalContext()

  return (
    <div>
      <DialogTitle disableTypography id="document-access-modal-title">
        <PersonIcon className="mx-auto table mt-6" />
        <DisplayText
          className="min-h-10 text-center mt-6"
          weight="semibold"
          size="lg"
        >
          Share Documents with {clinic.attributes.name}
        </DisplayText>
      </DialogTitle>
      <div className="bg-gray-200 h-[1px] mx-4" />
      <div className="mx-4">
        {isLoading &&
          Array.from({ length: 3 }).map((_, index) => (
            <div>
              <Skeleton
                animation="wave"
                component="div"
                height={50}
                width="100%"
                variant="text"
                className="rounded-2xl"
              />
            </div>
          ))}
        {!isLoading && (
          <div className="py-5">
            <div className="font-semibold">Sharing Options</div>
            <ClinicDocumentAccessOption
              optionSharingStatus={UserClinicAccessSharingStatus.NONE}
              text="Only share documents from this clinic"
            />
            <ClinicDocumentAccessOption
              optionSharingStatus={UserClinicAccessSharingStatus.ALL}
              text="Share all past & future results"
            />
            <ClinicDocumentAccessOption
              optionSharingStatus={UserClinicAccessSharingStatus.SPECIFIC}
              text="Select specific documents to share..."
              disabledReason={
                documents.length === 0
                  ? "You don't have any documents to share yet."
                  : undefined
              }
            />
          </div>
        )}
      </div>
      <div className="bg-gray-200 h-[1px] w-full" />
      <div className="pb-4 pt-4 px-4 bg-white">
        <Button
          className="text-center w-full"
          variant="primary"
          onClick={onSubmit}
          disabled={isLoading || isSubmitting}
          loading={isSubmitting}
        >
          Save Data Access
        </Button>
      </div>
    </div>
  )
}

export default ClinicDocumentAccessModalInner
