import { useState } from "react"
import { useDispatch } from "react-redux"

import _ from "lodash"

import { makeStyles } from "@material-ui/core"
import MuiDialogActions from "@material-ui/core/DialogActions"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import * as Sentry from "@sentry/react"

import { API } from "app/api"
import DesignSystemButton from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { Dialog } from "app/components/modals"
import * as OrderActions from "app/main/checkout/store/actions"
import * as Actions from "app/store/actions"
import { colors, navy } from "app/theme"
import { LabCompany, LabTestListItemCompany } from "app/types"
import {
  APIError,
  handleApiError,
  handleApiSuccess,
  showErrorToast,
} from "app/utils"

import BodyText from "../design-system/BodyText"

interface Props {
  open: boolean
  title: string
  licenseType?: string
  state?: string
  labCompany?: LabCompany | LabTestListItemCompany | null
  practitionerId?: string
  orderId?: string
}

const styles = (theme) => ({
  dialogPaper: {
    margin: 15,
    borderRadius: 7,
  },
  styledContent: {
    padding: 22.5,
    backgroundColor: colors.coolGray[100],
  },
  paragraphContent: {
    marginBottom: "10px",
  },
  styledDialogTitle: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    "flex-wrap": "nowrap",
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderColor: colors.blueGray[200],
    alignItems: "center",
    padding: "15px 24px 8px 24px",
  },
  actionsContainer: {
    margin: 0,
    padding: "15px 22.5px",
  },
  titleAndInfoContainer: {
    display: "flex",
    "flex-direction": "column",
    "flex-wrap": "wrap",
    marginRight: 24,
  },
  closeButton: {
    color: navy,
    position: "relative" as "relative",
    top: -5,
    right: 0,
    marginRight: -12,
  },
  bannerStyling: {
    backgroundColor: colors.yellow[50],
    marginTop: "20px",
    display: "flex",
    padding: "8px 12px",
    marginBottom: "19px",
    alignItems: "center",
    justifyContent: "left",
    border: (props) => `2px solid ${colors.yellow[400]}`,
    borderLeft: (props) => `8px solid ${colors.yellow[400]}`,
    borderRadius: 6,
  },
  bannerText: {
    color: colors.yellow[900],
  },
})

const useStyles = makeStyles(styles)

/**
 * Override Lab Test Access Modal
 * @param open - The modal will be open if this is true
 * @param title - The title of the modal
 * @param licenseType - Type of license that is overriding
 * @param state - The state that lab test is unavailable in
 * @param labCompany - The lab company to register as an override
 * @param practitionerId - The ID of prac used in api call to register lab company override
 * @param orderId - The ID of current order (if applicable); used to update the order state
 */
const OverrideAccessModal = ({
  open,
  title,
  licenseType,
  state,
  labCompany,
  practitionerId,
  orderId,
}: Props) => {
  const classes = useStyles(styles)
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const closeModal = () => {
    dispatch(Actions.resetLabTestForOverride())
  }

  const customErrorMessage = (error: any) => {
    const errorData = error.response && error.response.data
    const errorStatus = error.response && error.response.status
    const url = error && error.config && error.config.url

    if (errorStatus && errorData && !_.isEmpty(error.response.data)) {
      Sentry.captureException(new APIError(`Status: ${errorStatus}, on ${url}`))
      dispatch(
        showErrorToast({
          message: `${error.response.data} Please reach out to hello@rupahealth.com for support with this issue.`,
        })
      )
    } else {
      // Unknown format, handle generically
      dispatch(handleApiError(error))
    }
  }

  /**
   * On give access button click, call api to register the lab company override for the prac.
   *
   * If successful, update redux state of the practitioner and the order's signing practitioner.
   * Then show successful API toast and close the modal.
   *
   * If unsuccessful, show API error toast.
   */
  const handleGiveAccess = async () => {
    setLoading(true)
    try {
      const response = await API.LabCompany.registerLabCompany(
        practitionerId,
        labCompany?.id
      )

      if (response.data) {
        // Update prac from endpoint response
        dispatch(Actions.updatePractitionerState(response.data))
        // Fetch latest order for updated order.signing_practitioner
        await dispatch(OrderActions.getOrder(orderId))

        closeModal()
        dispatch(
          handleApiSuccess(
            `You now have access to order from ${labCompany?.name}`
          )
        )
        setLoading(false)
      }
    } catch (error) {
      customErrorMessage(error)
      setLoading(false)
    }
  }

  return (
    <Dialog
      onClose={closeModal}
      aria-labelledby="lab-test-bundle-title"
      open={open}
      className={"fs-unmask"}
      classes={{
        paper: classes.dialogPaper,
      }}
      fullWidth
      maxWidth="sm"
    >
      <TitleSection title={title} onClose={closeModal} />
      <MuiDialogContent className={classes.styledContent}>
        <BodyText weight="semibold" className={classes.paragraphContent}>
          Each lab has different ordering access criteria depending on your
          license and state.
        </BodyText>

        <BodyText>
          {`Our records don't show whether practitioners licensed as ${licenseType} ${
            state ? `in ${state}` : ""
          } can order from ${
            labCompany?.name
          }. Please proceed if you believe you should be able to order.`}
        </BodyText>
        <div className={classes.bannerStyling}>
          <div>
            <BodyText className={classes.bannerText}>
              Rupa processes orders after payment and patient demographic
              information is collected. We’ll have to issue a full refund if the
              order cannot be placed at the lab due to licensing restrictions.
            </BodyText>
          </div>
        </div>
      </MuiDialogContent>
      <MuiDialogActions className={classes.actionsContainer}>
        <DesignSystemButton
          color="noaction"
          loading={false}
          onClick={closeModal}
          disabled={false}
        >
          Go Back
        </DesignSystemButton>
        <DesignSystemButton
          color="primary"
          loading={loading}
          onClick={handleGiveAccess}
          disabled={false}
        >
          Yes! Give Me Access
        </DesignSystemButton>
      </MuiDialogActions>
    </Dialog>
  )
}

const TitleSection = ({ title, onClose }) => {
  const classes = useStyles(styles)
  const closeButton = onClose && (
    <IconButton
      aria-label="close"
      onClick={onClose}
      key="close-button"
      className={classes.closeButton}
    >
      <CloseIcon />
    </IconButton>
  )

  return (
    <MuiDialogTitle disableTypography className={classes.styledDialogTitle}>
      <div className={classes.titleAndInfoContainer}>
        <DisplayText weight="semibold" size="lg">
          {title}
        </DisplayText>
      </div>
      {closeButton}
    </MuiDialogTitle>
  )
}

export default OverrideAccessModal
