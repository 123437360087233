import { useEffect, useState } from "react"

import { Theme, useMediaQuery } from "@material-ui/core"
import { cn } from "@rupahealth/design"

import { FAILED_PROCESSING_COPY } from "../../constants"
import { useUserResultContext } from "../../providers/UserResultDataProvider"
import ResultUploadPdfViewer from "../components/ResultUploadPdfViewer"
import ResultsUploadStatusBanner from "../components/ResultsUploadStatusBanner"
import UserResultEditableFormContainer from "../components/UserResultEditableFormContainer"
import UserResultReadOnlyFormContainer from "../components/UserResultReadOnlyFormContainer"
import ResultsUploadMobileStackedEntry from "./ResultsUploadMobileStackedEntry"

interface ResultsUploadSideBySideEntryModalProps {
  showProcessingError?: boolean
  pdfFile?: File
  pdfFileUrl?: string
  isReadOnly?: boolean
  onSnapshotCreate: () => void
}

const ResultsUploadSideBySideEntry = ({
  showProcessingError,
  pdfFile,
  pdfFileUrl,
  isReadOnly,
  onSnapshotCreate,
}: ResultsUploadSideBySideEntryModalProps) => {
  const [showView, setShowView] = useState(false)

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const { userResult } = useUserResultContext()

  useEffect(() => {
    const showContent = setTimeout(() => {
      setShowView(true)
    }, 200)

    return () => clearTimeout(showContent)
  }, [])

  return isMobile ? (
    <div
      className={cn(
        "flex gap-[18px] overflow-hidden transition-opacity duration-500",
        showView ? "opacity-100" : "opacity-0"
      )}
    >
      <ResultsUploadMobileStackedEntry
        userResultId={userResult?.id}
        showProcessingError={showProcessingError}
        pdfFile={pdfFile}
        pdfFileUrl={pdfFileUrl}
        onSnapshotCreate={onSnapshotCreate}
      />
    </div>
  ) : (
    <div
      className={cn(
        "flex gap-[18px] overflow-hidden transition-opacity duration-500",
        showView ? "opacity-100" : "opacity-0"
      )}
    >
      <div className="w-1/2 overflow-y-scroll">
        {showProcessingError && (
          <div className="pb-5">
            <ResultsUploadStatusBanner
              containerClassName="bg-yellow-50 border-yellow-300 p-3"
              textColor="text-yellow-900"
              icon={null}
              title={null}
              text={FAILED_PROCESSING_COPY}
            />
          </div>
        )}
        {userResult &&
          (isReadOnly ? (
            <UserResultReadOnlyFormContainer userResultId={userResult.id} />
          ) : (
            <UserResultEditableFormContainer
              userResultId={userResult.id}
              showSuccessStatusBanner={!showProcessingError}
              onSnapshotCreate={onSnapshotCreate}
            />
          ))}
      </div>

      <div className="w-1/2">
        <ResultUploadPdfViewer pdfFileUrl={userResult?.attributes.pdf_file} />
      </div>
    </div>
  )
}

export default ResultsUploadSideBySideEntry
