import React, { useEffect, useRef } from "react"

import Lottie, {
  LottieRefCurrentProps,
  PartialLottieComponentProps,
} from "lottie-react"

import resultsScanningAnimation from "app/main/results-upload/assets/results-files-lottie.json"

/**
 * Lottie animation for scanning results
 */
interface Props extends PartialLottieComponentProps {
  // Pass either width or height, the other will be calculated to maintain aspect ratio
  width?: number
  height?: number
}

const DEFAULT_WIDTH = 150
const DEFAULT_HEIGHT = 110

export const ScanningResultsLottie: React.FC<Props> = ({
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
  ...lottieProps
}) => {
  const lottieRef = useRef<LottieRefCurrentProps | null>(null)

  // keep aspect ratio consistent
  if (width !== DEFAULT_WIDTH) {
    height = (width / DEFAULT_WIDTH) * DEFAULT_HEIGHT
  } else if (height !== DEFAULT_HEIGHT) {
    width = (height / DEFAULT_HEIGHT) * DEFAULT_WIDTH
  }

  useEffect(() => {
    const svg =
      lottieRef.current?.animationContainerRef?.current?.getElementsByTagName(
        "svg"
      )[0]
    if (svg) {
      svg.setAttribute("viewBox", "35 12 150 75")
      svg.setAttribute("height", height.toString())
      svg.setAttribute("width", width.toString())
    }
  }, [])

  return (
    <Lottie
      animationData={resultsScanningAnimation}
      initialSegment={[157, 636]}
      lottieRef={lottieRef}
      loop={true}
      width={width}
      height={height}
      {...lottieProps}
    />
  )
}
