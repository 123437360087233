import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// This restricted import predates our decision to discontinue using formsy-react.
// We now opt to use react-hook-form instead.
// When introducing changes to this component, please consider refactoring to remove
// formsy-react and replace it with react-hook-form where applicable.
// eslint-disable-next-line no-restricted-imports
import Formsy from "formsy-react"

import { Theme, styled, useMediaQuery } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"

import { UserPaths } from "app/Routes"
import { API } from "app/api"
import CogIconGray from "app/assets/images/cog-icon-gray.svg"
import PageToolbar from "app/components/PageToolbar"
import Tooltip from "app/components/Tooltip"
import { FormTextField, SelectFormField } from "app/components/forms/fields"
import useFeatureFlag from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"
import { US_STATES } from "app/main/patient-checkout/utils/usStates"
import EMRSettings from "app/main/settings/components/EMRSettings"
import ResultsSummaryLogoSettings from "app/main/settings/components/ResultsSummaryLogoSettings"
import Separator from "app/main/settings/components/Separator"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { updatePractitioner } from "app/store/actions"
import { selectPractitioner } from "app/store/selectors/practitioner.selectors"
import { colors } from "app/theme"
import { RootState, StripeConnectAccount, SupportedCountry } from "app/types"

import SideNav from "./SideNav"
import InOfficeKitsToggle from "./components/InOfficeKitsToggle"
import {
  ClinicInputFieldNames,
  DEFAULT_SPECIMEN_ISSUE_EMAIL_RECIPIENT,
  NJ_NY_RI_RESTRICTIONS_URL,
  SPECIMEN_ISSUE_EMAIL_RECIPIENT_CHOICES,
  SpecimenIssueEmailRecipientId,
} from "./constants"

const Wrapper = styled("div")({
  background: colors.coolGray[50],
  border: `1px solid ${colors.coolGray[200]}`,
  padding: 24,
  minHeight: "100vh",
})

function Clinic() {
  const practitioner = useAppSelector(selectPractitioner)
  const dispatch = useDispatch()

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const [validCountries, setValidCountries] = useState<SupportedCountry[]>([])
  const [nyWarning, setNYWarning] = useState(false)
  const [specimenIssueEmailRecipient, setSpecimenIssueEmailRecipient] =
    useState<SpecimenIssueEmailRecipientId>()

  useEffect(() => {
    const getValidCountries = async () => {
      const { data } = await API.SupportedCountries.getList()
      setValidCountries(data)
    }

    getValidCountries()
  }, [])

  useEffect(() => {
    if (practitioner?.clinic?.id) {
      setSpecimenIssueEmailRecipient(
        practitioner.clinic.specimen_issue_email_recipient ||
          DEFAULT_SPECIMEN_ISSUE_EMAIL_RECIPIENT
      )
    }

    if (practitioner?.clinic?.state === "NY") {
      setNYWarning(true)
    }
  }, [practitioner])

  const onSelectChange = (event, field) => {
    if (
      field === ClinicInputFieldNames.ClinicState &&
      event.target.value !== practitioner?.clinic?.state
    ) {
      dispatch(
        updatePractitioner({ clinic: { state: event.target.value } }, true)
      )

      setNYWarning(event.target.value === "NY")
    } else if (
      field === ClinicInputFieldNames.ClinicProvinceRegion &&
      event.target.value !== practitioner?.clinic?.province_region
    ) {
      dispatch(
        updatePractitioner(
          { clinic: { province_region: event.target.value } },
          true
        )
      )
    } else if (
      field === ClinicInputFieldNames.ClinicCountry &&
      event.target.value !== practitioner?.clinic?.country
    ) {
      dispatch(
        updatePractitioner(
          {
            clinic: {
              country: event.target.value,
              // If we are changing from US to non-US, clear out the state (only relevant to US)
              // If change is non-US to US, clear out province_region (only relevant to non-US)
              state:
                event.target.value === "US" ? practitioner?.clinic?.state : "",
              province_region:
                event.target.value === "US"
                  ? ""
                  : practitioner?.clinic?.province_region,
            },
          },
          true
        )
      )
    } else if (
      field === ClinicInputFieldNames.ClinicSpecimenIssueEmailRecipient &&
      event.target.value !==
        practitioner?.clinic?.specimen_issue_email_recipient
    ) {
      dispatch(
        updatePractitioner(
          { clinic: { specimen_issue_email_recipient: event.target.value } },
          true
        )
      )
    }
  }

  const onSubmit = useCallback(
    async ({ clinic_name, clinic_street, clinic_city, clinic_zipcode }) => {
      let hasChangedAttribute = false
      if (
        clinic_name !== practitioner?.clinic?.name ||
        clinic_street !== practitioner?.clinic?.street ||
        clinic_city !== practitioner?.clinic?.city ||
        clinic_zipcode !== practitioner?.clinic?.zipcode
      ) {
        hasChangedAttribute = true
      }

      if (hasChangedAttribute) {
        dispatch(
          updatePractitioner(
            {
              clinic: {
                name: clinic_name,
                street: clinic_street,
                city: clinic_city,
                zipcode: clinic_zipcode,
              },
            },
            true
          )
        )
      }
    },
    [practitioner]
  )

  const [emrAccountSettingsEnabled] = useFeatureFlag(
    FeatureFlag.EMRAccountSettings
  )
  const [resultsSummaryLogosEnabled] = useFeatureFlag(
    FeatureFlag.ResultsSummaryLogos
  )
  const [inOfficeKitsV2] = useFeatureFlag(FeatureFlag.InOfficeKitsV2)
  const [specimenIssueVisibility] = useFeatureFlag(
    FeatureFlag.SpecimenIssueVisibility
  )
  const [isInternationalClinicsEnabled] = useFeatureFlag(
    FeatureFlag.InternationalClinics
  )

  const emrIntegrations = useAppSelector(
    ({ emrIntegrations }) => emrIntegrations.results
  )

  const stripeConnectAccount = useSelector<
    RootState,
    StripeConnectAccount | undefined
  >((state) => {
    const customFees = state.customFees
    if (
      Array.isArray(customFees.stripeConnectAccounts) &&
      customFees.stripeConnectAccounts.length
    ) {
      return customFees.stripeConnectAccounts[0]
    }
  })
  const customFeesEnabledForClinic = useAppSelector(
    (state) => state?.practitioner?.clinic?.custom_fees_enabled
  )

  const customFeesArePresent =
    Boolean(customFeesEnabledForClinic) || Boolean(stripeConnectAccount)
  const disableCountryDropdown =
    customFeesArePresent ||
    practitioner?.clinic?.has_active_prac_license_labshop

  let disabledCountryDropdownTooltipText = ""

  if (customFeesArePresent) {
    disabledCountryDropdownTooltipText =
      "You cannot change your country as your clinic has set up Custom Fees. Please reach out to us via chat to help you completely remove Custom Fees from your account."
  } else if (practitioner?.clinic?.has_active_prac_license_labshop) {
    disabledCountryDropdownTooltipText =
      "You cannot change your country as one or more of your LabShops are using a practitioner's license. Please reach out to us via chat if you would still like to change your country."
  }

  return (
    <>
      {isMobile && (
        <PageToolbar
          breadcrumbs={[
            {
              to: UserPaths.SETTINGS,
              icon: CogIconGray,
              label: "Settings",
            },

            {
              label: "Clinic Settings",
            },
          ]}
        />
      )}
      <Grid container>
        {!isMobile && (
          <Grid item xs={12} md={3}>
            <SideNav />
          </Grid>
        )}
        <Grid item xs={12} md={9}>
          {!isMobile && (
            <PageToolbar
              title="Clinic Settings"
              greyBackground={true}
              fullBorder={true}
            />
          )}
          <Wrapper>
            <div className="pt-6">
              <Formsy onValidSubmit={onSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormTextField
                      name={ClinicInputFieldNames.ClinicName}
                      value={practitioner?.clinic?.name}
                      required={false}
                      label="Practice Name"
                      clickToEdit
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField
                      name={ClinicInputFieldNames.ClinicStreet}
                      value={practitioner?.clinic?.street}
                      required={false}
                      label="Street Address"
                      clickToEdit
                    />
                  </Grid>
                  <Grid item xs={12} md={isInternationalClinicsEnabled ? 3 : 4}>
                    <FormTextField
                      name={ClinicInputFieldNames.ClinicCity}
                      value={practitioner?.clinic?.city}
                      required={false}
                      label="City"
                      clickToEdit
                    />
                  </Grid>
                  {isInternationalClinicsEnabled &&
                  practitioner?.clinic?.is_international_clinic ? (
                    <Grid item xs={12} md={3}>
                      <SelectFormField
                        name={ClinicInputFieldNames.ClinicProvinceRegion}
                        value={practitioner?.clinic?.province_region}
                        required={false}
                        label="Province"
                        onChange={(e) =>
                          onSelectChange(
                            e,
                            ClinicInputFieldNames.ClinicProvinceRegion
                          )
                        }
                      >
                        {practitioner?.clinic?.country &&
                          validCountries
                            .find(
                              (country) =>
                                country.countryShortCode ===
                                practitioner?.clinic?.country
                            )
                            ?.regions?.map((regionData) => (
                              <option
                                key={regionData.shortCode}
                                value={regionData.name}
                              >
                                {regionData.name}
                              </option>
                            ))}
                      </SelectFormField>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      md={isInternationalClinicsEnabled ? 3 : 4}
                    >
                      <SelectFormField
                        name={ClinicInputFieldNames.ClinicState}
                        value={practitioner?.clinic?.state}
                        required={false}
                        label="State"
                        onChange={(e) =>
                          onSelectChange(e, ClinicInputFieldNames.ClinicState)
                        }
                      >
                        {Object.entries(US_STATES).map(([code, name]) => (
                          <option key={code} value={code}>
                            {name}
                          </option>
                        ))}
                      </SelectFormField>
                    </Grid>
                  )}
                  {isInternationalClinicsEnabled && (
                    <Grid item xs={12} md={3}>
                      <Tooltip
                        title={disabledCountryDropdownTooltipText}
                        placement="bottom"
                        arrow
                      >
                        <div>
                          <SelectFormField
                            name={ClinicInputFieldNames.ClinicCountry}
                            value={practitioner?.clinic?.country}
                            required={false}
                            label="Country"
                            disabled={true}
                            onChange={(e) =>
                              onSelectChange(
                                e,
                                ClinicInputFieldNames.ClinicCountry
                              )
                            }
                          >
                            {validCountries.map((countryData) => (
                              <option
                                key={countryData.countryShortCode}
                                value={countryData.countryShortCode}
                                disabled={
                                  countryData.countryShortCode !==
                                    practitioner?.clinic?.country &&
                                  disableCountryDropdown
                                }
                              >
                                {countryData.countryName}
                              </option>
                            ))}
                          </SelectFormField>
                        </div>
                      </Tooltip>
                    </Grid>
                  )}
                  <Grid item xs={12} md={isInternationalClinicsEnabled ? 3 : 4}>
                    <FormTextField
                      name="clinic_zipcode"
                      value={practitioner?.clinic?.zipcode}
                      required={false}
                      label={
                        isInternationalClinicsEnabled &&
                        practitioner?.clinic?.is_international_clinic
                          ? "Postal Code"
                          : "Zip Code"
                      }
                      clickToEdit
                    />
                  </Grid>
                  {nyWarning && (
                    <Grid>
                      <div className="border-1 rounded-full py-1 px-3 ml-2 bg-red-300 border-red-900 text-white">
                        Clinics in New York State have access to a restricted
                        catalogue, in accordance with state law.{" "}
                        <a
                          className="text-white"
                          href={NJ_NY_RI_RESTRICTIONS_URL}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Learn more.
                        </a>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </Formsy>
            </div>

            {emrAccountSettingsEnabled && (
              <>
                <Separator />
                <EMRSettings emrIntegrations={emrIntegrations} />
              </>
            )}

            {resultsSummaryLogosEnabled && (
              <>
                <Separator />
                <ResultsSummaryLogoSettings clinic={practitioner?.clinic} />
              </>
            )}
            {inOfficeKitsV2 && (
              <div className="pt-6">
                <Separator />
                <div className="flex flex-row items-start">
                  <InOfficeKitsToggle name="allow_in_office_kits" />
                  <div className="flex flex-col">
                    <div className="text-body font-semibold">
                      Stock Kits In-Office
                    </div>
                    <div className="text-body font-normal">
                      Rupa can manage your orders for kits handed out in person,
                      or blood draws done in-house. We'll handle ordering your
                      supplies, managing your billing, and your patient's
                      requisitions. Please note, In-Office Kits can't be ordered
                      with Physician Services.{" "}
                      <a
                        href="https://www.rupahealth.com/in-office-kits"
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary"
                      >
                        Learn more
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {specimenIssueVisibility && (
              <Formsy className="my-6 text-xl">
                <SelectFormField
                  labelClass="text-body text-base15"
                  name={ClinicInputFieldNames.ClinicSpecimenIssueEmailRecipient}
                  value={specimenIssueEmailRecipient}
                  required={false}
                  hideEmptyOption={true}
                  label="Specimen Issue emails go to:"
                  disabled={!specimenIssueEmailRecipient}
                  onChange={(e) =>
                    onSelectChange(
                      e,
                      ClinicInputFieldNames.ClinicSpecimenIssueEmailRecipient
                    )
                  }
                >
                  {SPECIMEN_ISSUE_EMAIL_RECIPIENT_CHOICES.map((opt) => (
                    <option key={opt.id} value={opt.id}>
                      {opt.name}
                    </option>
                  ))}
                </SelectFormField>
              </Formsy>
            )}
          </Wrapper>
        </Grid>
      </Grid>
    </>
  )
}

export default Clinic
