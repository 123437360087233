import { ResourceType } from "app/types"

import ClinicDocumentAccessDocumentsSection from "./ClinicDocumentAccessDocumentsSection"
import { useClinicDocumentAccessModalContext } from "./ClinicDocumentAccessModalProvider"

const ClinicDocumentAccessDocuments = () => {
  const { documents } = useClinicDocumentAccessModalContext()

  const foodPlanDocuments = documents.filter(
    (document) => document.resource_type === ResourceType.FOOD_PLAN
  )
  const resultsInterpretationDocuments = documents.filter(
    (document) => document.resource_type === ResourceType.RESULTS_INTERPRETATION
  )
  const labResultsDocuments = documents.filter((document) =>
    [
      ResourceType.ORDER_USER_RESULT,
      ResourceType.IMPORTED_USER_RESULT,
    ].includes(document.resource_type)
  )

  return (
    <div className="flex flex-col gap-4">
      <ClinicDocumentAccessDocumentsSection
        title="LAB RESULTS"
        documents={labResultsDocuments}
      />
      <ClinicDocumentAccessDocumentsSection
        title="FOOD PLAN"
        documents={foodPlanDocuments}
      />
      <ClinicDocumentAccessDocumentsSection
        title="RESULTS SUMMARY"
        documents={resultsInterpretationDocuments}
      />
    </div>
  )
}

export default ClinicDocumentAccessDocuments
