import React from "react"

import MuiDialogActions from "@material-ui/core/DialogActions"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

import { ReactComponent as AirplaneIcon } from "app/assets/images/baby-blue-airplane.svg"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { Dialog } from "app/components/modals"

interface Props {
  open: boolean
  title?: string
  message?: string
  backButtonTitle?: string
  confirmButtonTitle?: string
  confirmButtonColor?: string
  onClose: () => void
  loading?: boolean
  handleConfirm: () => void
  showBackButton?: boolean
}
/**
 * Confirmation Modal
 * @param open - The modal will be open if this is true
 * @param title - The title of the modal
 * @param message - The message shown in the modal
 * @param backButtonTitle - The back button title
 * @param confirmButtonTitle - The confirm button title
 * @param confirmButtonColor - destructive | primary | secondary - The color of the confirm button
 * @param onClose - The action which needs to be done when the modal closes
 * @param loading - The loader state
 * @param handleConfirm - The action which needs to be done on confirm
 */
const IconConfirmationModal: React.FC<Props> = ({
  open,
  title = "Are You Sure?",
  message = "",
  backButtonTitle = "Go back",
  confirmButtonTitle = "Delete",
  confirmButtonColor = "destructivePrimary",
  onClose,
  loading,
  handleConfirm,
  showBackButton = true,
}) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="lab-test-bundle-title"
      open={open}
      className="fs-unmask"
      classes={{
        paper: "m-4 rounded-xl",
      }}
      maxWidth="xs"
    >
      <TitleSection title={""} onClose={onClose} />
      <MuiDialogContent className="px-2 pb-6 mx-2 border-b border-[rgb(243,244,246)]">
        <div className="flex flex-col items-center w-full relative">
          <div className="text-center pb-5">
            <AirplaneIcon height={85} width={85} viewBox="0 0 88 88" />
          </div>
          <h2>{title}</h2>
          <div></div>
          <div className="w-[70%] text-center pt-2.5">
            <BodyText>{message}</BodyText>
          </div>
        </div>
      </MuiDialogContent>
      <MuiDialogActions className="justify-start p-[15px_25px]">
        {showBackButton && (
          <DesignSystemButton color="noaction" onClick={onClose} fullWidth>
            {backButtonTitle}
          </DesignSystemButton>
        )}
        <DesignSystemButton
          color={confirmButtonColor}
          loading={loading}
          onClick={handleConfirm}
          fullWidth
        >
          {confirmButtonTitle}
        </DesignSystemButton>
      </MuiDialogActions>
    </Dialog>
  )
}

const TitleSection = ({ title, onClose }) => {
  const closeButton = onClose && (
    <IconButton
      aria-label="close"
      onClick={onClose}
      key="close-button"
      className="absolute sm:relative top-1 sm:right-3 right-0 -mr-3"
    >
      <CloseIcon className="text-[#2B455F]" />
    </IconButton>
  )

  return (
    <MuiDialogTitle
      disableTypography
      className="h-10 flex p-[16px_16px_8px_16px] flex-row justify-between sm:flex-nowrap flex-wrap items-center rounded-t-xl"
    >
      <div className="flex flex-col flex-wrap mr-6">
        <DisplayText weight="semibold" size="lg">
          {title}
        </DisplayText>
      </div>
      {closeButton}
    </MuiDialogTitle>
  )
}

export default IconConfirmationModal
