import { ReactComponent as AirplaneBlueIcon } from "app/assets/images/airplane-blue.svg"
import { KIT_STATUS } from "app/constants.typed"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { DashboardKit } from "types/dashboard/dashboard-kit"
import { DashboardOrder } from "types/dashboard/dashboard-order"
import { DashboardOrderedResult } from "types/dashboard/dashboard-ordered-result"
import { DashboardPatient } from "types/dashboard/dashboard-patient"

import ActionDropdownMenuButton from "./ActionDropdownMenuButton"
import ResultsSummaryDropdownMenuAction from "./ResultsSummaryDropdownMenuAction"
import SendResultsDropdownMenuAction from "./SendResultsDropdownMenuAction"

export interface SendResultsActionProps {
  kit: DashboardKit
  order: DashboardOrder
}

export default function SendResultsAction({
  kit,
  order,
}: SendResultsActionProps) {
  const latestOrderedResult = useCachedResource<DashboardOrderedResult>(
    kit.relationships.latest_ordered_result?.data
  )
  const patient = useCachedResource<DashboardPatient>(
    order.relationships.patient?.data
  )
  const patientName = patient?.attributes.full_name || "Patient"

  const [showPreliminary] = useFeatureFlag(
    FeatureFlag.PreliminaryResultNotifications
  )

  const isPreliminary =
    kit.attributes.status === KIT_STATUS.PRELIMINARY_RESULTS_AVAILABLE

  return (
    <ActionDropdownMenuButton
      disabled={!latestOrderedResult}
      icon={
        <AirplaneBlueIcon
          fill="currentColor"
          height={15}
          width={15}
          viewBox="0 0 18 18"
        />
      }
      tooltipTitle={
        !latestOrderedResult ? (
          <>
            Send Results to {patientName} <br />
            (Not Available Yet)
          </>
        ) : kit.attributes.date_results_sent_to_patient ? (
          isPreliminary && showPreliminary ? (
            `Re-send Preliminary Results to ${patientName}`
          ) : (
            `Re-send Results to ${patientName}`
          )
        ) : isPreliminary && showPreliminary ? (
          `Send Preliminary Results to ${patientName}`
        ) : (
          `Send Results to ${patientName}`
        )
      }
    >
      <SendResultsDropdownMenuAction kit={kit} order={order} />

      <ResultsSummaryDropdownMenuAction kit={kit} order={order} />
    </ActionDropdownMenuButton>
  )
}
