import { createContext, useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { API } from "app/api"
import { RootState } from "app/types"

// To add a new feature flag add a record to the following enum.
// Each feature flag will first default as false.
// See features.md for info on proper setup.
export enum FeatureFlag {
  TaskBasedOnboarding = "task-based-onboarding",
  RupaUniversity = "Rupa University",
  SplitItDisabled = "SplitIt Disabled", // Splitit will be enabled by default
  // Feature allows user to override their access to a specific lab company test
  OrderAccessOverrideHover = "Order Access Override Hover",
  EMRAccountSettings = "emr-account-settings",
  GroupedOrderEventsAndReminders = "grouped-order-events-and-reminders",
  CustomFeeCheckoutText = "custom-fees-checkout-text-enabled",
  LabTestComparison = "lab-test-comparison",
  AchEnabled = "ach-enabled",
  isPromotionsAddCouponCodeButtonEnabled = "is-promotions-add-coupon-code-button-enabled",
  ResultsVisualizer = "results-visualizer",
  CreditCardFeeCheckoutEnabled = "credit-card-fee-checkout-enabled",
  isMedicareCheckEnabled = "is-medicare-check-enabled",
  isHolisticOfficeEnabled = "is-holistic-office-enabled",
  IsEarlyClinicalConsultsEnabled = "is-early-clinical-consults-enabled",
  ClinicNotificationPreferences = "clinic-notification-preferences",
  CustomFeesExpansion = "custom-fees-expansion",
  LabshopsUsePractitionerLicense = "labshops-use-practitioner-license",
  /**
   * This flag is used to enable ach payments for custom fee orders.
   */
  AchForCustomFees = "ach-for-custom-fees",
  ResultsSummaryLogos = "results-summary-logo",
  /**
   * This flag is used to enable the food plans feature.
   */
  FoodPlans = "food-plans",
  /**
   * Practitioner profile / Find a practitioner
   */
  PractitionerProfiles = "practitioner-profiles",
  BeastCoast = "beast-coast", // NYNJRI
  InOfficeKitsV2 = "in-office-kits-v2", // TODO: Deprecate. Old code is broken.
  HidePhysicianServicesPricing = "hide-physician-services-pricing",
  ConnectedAccountSettingsPage = "connected-account-settings-page",
  ConnectedAccountSettingsPageQuest = "connected-accounts-settings-page-quest", // flag for enabling Quest account tile on the connected accounts settings page"
  InsuranceUpdatesForHealthGorilla = "insurance-updates-for-health-gorilla",
  // This flag is used to enable the combo groups functionality.
  ComboGroupsEnabled = "combo-groups-enabled",
  PanelBuilder = "panel-builder",
  PatientCheckoutPanelGrouping = "patient-checkout-panel-grouping",
  DefaultPractitionerPay = "default-practitioner-pay",
  // Flag to enable the showing of pills & other related features on payment methods indicating they are shared with the clinic.
  ClinicPaymentMethodsEnabled = "clinic-payment-methods-enabled",
  InternationalClinics = "international-clinics",
  RupaBloodReports = "rupa-blood-reports",
  RupaBloodReportSnapshots = "rupa-blood-report-snapshots",
  AdsExperiment = "ads-experiment",
  FastingCheckboxInPracCart = "fasting-checkbox-in-prac-cart", // short-lived feature flag to allow us to e2e test the fasting checkbox in the practitioner cart
  LabcorpEnabledForBloodLabsDashboard = "lapcorp-enabled-for-blood-labs-dashboard",
  AxlePhlebotomy = "axle-phlebotomy",
  VibrantDisabled = "vibrant-disabled",
  VibrantLeavingBanner = "vibrant-leaving-banner",
  PatientDetailsTabs = "patient-details-tabs",
  InOfficeKitInventory = "in-office-kit-inventory",
  ShowPhysicianServicesConfirmationModal = "show-physician-services-confirmation-modal",
  ShowResultVisualizationNotifications = "show-result-visualization-notifications",
  PhysicianServicesBackfillPhase1 = "physician-services-backfill-phase-1",
  PhysicianServicesBackfillPhase2 = "physician-services-backfill-phase-2",
  ResultsOverTimeManualResultsUpload = "results-over-time-manual-results-upload",
  SpecimenIssueVisibility = "specimen-issue-visibility",
  RefactoredSnippets = "refactored-snippets",
  RenderBannerObjects = "render-banner-objects",
  LabCompanyOrderingAccessV2 = "lab-company-ordering-access-table-v2",
  CatalogSigningPractitionerFilter = "catalog-signing-practitioner-filter",
  BloodLabDashboardsPdfPrint = "blood-lab-dashboards-pdf-print",
  CredentialUploadV2 = "credential-upload-v2",
  PatientPortalTrends = "patient-portal-trends",
  PatientPortalDocuments = "patient-portal-documents",
  PractitionerDocumentsV2 = "practitioner-documents-v2",
  DocumentAccess = "document-access",
  PreliminaryResultNotifications = "preliminary-result-notifications",
  EmbeddableResultsForPatientPortal = "embeddable-results-for-patient-portal",
  EmbeddableResults = "embeddable-results",
  AllClientsView = "all-clients-view",
  SimultaneousResultsUploads = "simultaneous-results-uploads",
  ShowAlertsOnAllPages = "show-alerts-on-all-pages",
  PatientPortalResultsUploads = "patient-portal-results-uploads",
}

export type FeatureFlagCollection = { [key in FeatureFlag]: boolean }

declare global {
  interface Window {
    waffle: {
      FLAGS: FeatureFlagCollection
    }
  }
}

type FeatureFlagContextProps = [FeatureFlagCollection, { loading: boolean }]

export const DEFAULT_FEATURE_FLAGS = Object.values(FeatureFlag).reduce(
  (flags, flagName) => {
    flags[flagName] = false
    return flags
  },
  {} as FeatureFlagCollection
)

export const FeatureFlagContext = createContext<FeatureFlagContextProps>([
  DEFAULT_FEATURE_FLAGS,
  { loading: true },
])

export const FeatureFlagProvider = ({ children }) => {
  const [flags, setFlags] = useState(DEFAULT_FEATURE_FLAGS)
  const [waffleLoading, setWaffleLoading] = useState(true)

  const user = useSelector<RootState, RootState["auth"]["user"]>(
    ({ auth }) => auth.user
  )

  const initializeWaffle = async () => {
    setWaffleLoading(true)
    try {
      const res = await API.Waffle.getWaffleJS()

      // Using eval is generally discouraged, but in this case, since wafflejs does not provide
      // out of the box support for a REST API endpoint, we reproducing the behavior of
      // an embedded script tag by evaling the javascript returned by the HTTP call to the server.
      // This is done to ensure that feature flags are reloaded after user log in.
      // eslint-disable-next-line no-eval
      eval(res.data)

      if (window?.waffle?.FLAGS) {
        setFlags((prev) => ({
          ...prev, // spread existing flags so not to remove Launch Darkly flags
          ...window.waffle.FLAGS,
        }))
      }

      setWaffleLoading(false)
    } catch (e) {
      setWaffleLoading(false)
    }
  }

  useEffect(() => {
    initializeWaffle()
  }, [user?.id])

  return (
    <FeatureFlagContext.Provider value={[flags, { loading: waffleLoading }]}>
      {children}
    </FeatureFlagContext.Provider>
  )
}
