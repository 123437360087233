import { ReactComponent as CubeIcon } from "app/assets/icons/cube.svg"

import IOKStartOrderModalButton from "../IOKStartOrderModalButton"
import { useOrderHistory } from "./OrderHistoryDataProvider"

interface Props {
  onSuccess?: () => void
}

const IokStartOrderButton: React.FC<Props> = ({ onSuccess }) => {
  const { mutateOrders } = useOrderHistory()

  const onSuccessCallback = () => {
    mutateOrders()
    onSuccess?.()
  }

  return (
    <IOKStartOrderModalButton
      buttonProps={{ className: "text-base", variant: "primary" }}
      // For now, just invalidate the cache so it refetches all orders
      onSuccess={onSuccessCallback}
    >
      <CubeIcon fill="white" className="mr-2" />
      <span className="pt-1">Order In-Office Supplies</span>
    </IOKStartOrderModalButton>
  )
}

export default IokStartOrderButton
