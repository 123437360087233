import { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { find } from "lodash"

import { Theme, useMediaQuery } from "@material-ui/core"

import LabOrderingAccessHeader from "app/components/OrderingAccess/LabOrderingAccessHeader"
import LabOrderingAccessList from "app/components/OrderingAccess/LabOrderingAccessList"
import LabOrderingAccessTable from "app/components/OrderingAccess/LabOrderingAccessTable"
import { VIBRANT_COMPANY_KEYS } from "app/constants"
import { hasOrderingRightsForLabCompany } from "app/dataServices/labTestDataService"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"
import * as Actions from "app/store/actions"

import OverrideAccessModal from "../modals/OverrideAccessModal"

function OrderingAccess() {
  const dispatch = useDispatch()
  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const practitionerListItemsIncludingClinicStaff = useAppSelector(
    ({ teamMember }) => teamMember
  )
  const allLabCompanies = useAppSelector(({ labCompany }) => labCompany.list)

  const labCompanies = useMemo(() => {
    if (!allLabCompanies) return []

    // We no longer integrate with Vibrant.
    return allLabCompanies.filter(
      (labCompany) => !VIBRANT_COMPANY_KEYS.includes(labCompany.key)
    )
  }, [allLabCompanies])

  const labTestOverride = useAppSelector(
    ({ overrideLabTest }) => overrideLabTest
  )
  const [isOrderingAccessOverrideHoverEnabled] = useFeatureFlag(
    FeatureFlag.OrderAccessOverrideHover
  )
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const practitionerListItems = Object.values(
    practitionerListItemsIncludingClinicStaff
  ).filter((practitionerListItem) => {
    return !practitionerListItem.is_clinic_staff
  })

  const [selectedPractitionerListItemId, setSelectedPractitionerListItemId] =
    useState(() =>
      !practitioner.is_clinic_staff ? practitioner.id : undefined
    )

  const selectedPractitionerListItem = useMemo(() => {
    return find(
      practitionerListItems,
      (practitionerListItem) =>
        practitionerListItem.id === selectedPractitionerListItemId
    )
  }, [practitionerListItems, selectedPractitionerListItemId])

  const allowedLabCompanies = useMemo(() => {
    return labCompanies.filter((labCompany) => {
      if (VIBRANT_COMPANY_KEYS.includes(labCompany.key)) {
        return false
      }
      const hasRegisteredLabCompany =
        practitioner.registered_lab_companies?.includes(labCompany.id)

      const [hasOrderingRights, disallowedBecauseOfState] =
        hasOrderingRightsForLabCompany(labCompany, selectedPractitionerListItem)

      return (
        (hasRegisteredLabCompany === true || hasOrderingRights === true) &&
        disallowedBecauseOfState === false
      )
    })
  }, [labCompanies, selectedPractitionerListItem, practitioner])

  useEffect(() => {
    dispatch(Actions.getTeamMembers())
    dispatch(Actions.getLabCompanyList())
  }, [dispatch])

  useEffect(() => {
    if (
      !selectedPractitionerListItemId &&
      Boolean(practitionerListItems.length)
    ) {
      setSelectedPractitionerListItemId(practitionerListItems[0].id)
    }
  }, [practitionerListItems])

  useEffect(() => {
    const sessionStorageItem = window.sessionStorage.getItem("practitionerId")

    sessionStorageItem
      ? setSelectedPractitionerListItemId(sessionStorageItem)
      : setSelectedPractitionerListItemId(selectedPractitionerListItemId)
  }, [])

  useEffect(() => {
    if (!selectedPractitionerListItemId) {
      return
    }

    window.sessionStorage.setItem(
      "practitionerId",
      selectedPractitionerListItemId
    )
  }, [selectedPractitionerListItemId])

  return (
    <>
      <LabOrderingAccessHeader
        isMobile={isMobile}
        onSelectInputChange={setSelectedPractitionerListItemId}
        practitionerListItems={practitionerListItems}
        selectedPractitionerListItem={selectedPractitionerListItem}
        selectedPractitionerListItemId={selectedPractitionerListItemId}
      />
      {isMobile ? (
        <LabOrderingAccessList
          labCompanies={labCompanies}
          allowedLabCompanies={allowedLabCompanies}
          selectedPractitionerListItem={selectedPractitionerListItem}
        />
      ) : (
        <LabOrderingAccessTable
          labCompanies={labCompanies}
          allowedLabCompanies={allowedLabCompanies}
          selectedPractitionerListItem={selectedPractitionerListItem}
        />
      )}
      {isOrderingAccessOverrideHoverEnabled &&
        Boolean(labTestOverride.labCompany) && (
          <OverrideAccessModal
            open={Boolean(labTestOverride.labCompany)}
            title={`Confirm Ordering Access for ${labTestOverride.labCompany?.name}`}
            licenseType={practitioner?.primary_practitioner_type?.name}
            state={labTestOverride.unavailableState}
            labCompany={labTestOverride.labCompany}
            practitionerId={practitioner.id}
          />
        )}
    </>
  )
}

export default OrderingAccess
