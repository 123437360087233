import { useState } from "react"

import axios from "axios"

import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import { getIdentifier } from "app/swr/helpers/resource"
import useMutateResource from "app/swr/hooks/use-mutate-resource"
import { ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { getApiBaseUrl } from "app/utils"
import {
  UserResult,
  UserResultCreate,
  UserResultPostData,
} from "types/user-result"

const useCreateUserResult = () => {
  const handleApiError = useHandleApiError()
  const mutateResource = useMutateResource()
  const [isCreating, setIsCreating] = useState<boolean>(false)

  const createUserResult = useEventCallback(
    async (postData: UserResultPostData) => {
      setIsCreating(true)

      const { pdf_file, patientId, labCompanyId, attributes } = postData

      try {
        let relationships: UserResultCreate["data"]["relationships"] = {
          patient: { data: { type: "patient", id: patientId } },
        }

        if (labCompanyId) {
          relationships["lab_company"] = {
            data: { type: "lab_company", id: labCompanyId },
          }
        }

        const { data } = await resourceRequest<
          ResourceResponse<UserResult>,
          UserResultCreate
        >({
          method: "post",
          url: "/user_results/",
          data: {
            data: {
              type: "user_result",
              attributes: { has_specimen_issues: false, ...attributes },
              relationships,
            },
          },
        })

        if (pdf_file) {
          const formData = new FormData()
          formData.append("pdf_file", pdf_file)

          try {
            const { data: fileUploadData } = await axios.post(
              getApiBaseUrl() +
                `/api/normalized/user_results/${data.id}/pdf_file/`,
              formData,
              { headers: { "Content-Type": "multipart/form-data" } }
            )
            data.attributes.pdf_file = fileUploadData.attributes.pdf_file
          } catch (error) {}
        }

        // Pre-populate the SWR cache with the returned data
        await mutateResource<UserResult>(getIdentifier(data), data)

        return data.id
      } catch (error) {
        handleApiError(error)
      } finally {
        setIsCreating(false)
      }
    }
  )
  return { createUserResult, isCreating }
}

export default useCreateUserResult
