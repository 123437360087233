import { ReactNode, useEffect, useState } from "react"

import axios from "axios"
import { snakeCase } from "lodash"

import NiceModal from "@ebay/nice-modal-react"
import { faXmark, faCircleArrowDown } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Tooltip, TooltipProvider } from "@rupahealth/design"

import EmptyDocumentsIcon from "app/assets/images/results-over-time/documents-empty.png"
import { Dialog } from "app/components/modals"
import { useAcknowledgeUnreadResult } from "app/dashboard/components/TableRowDetailRenderer/actions/DownloadResultsDropdownMenuAction"
import { trackInteractiveReportView } from "app/services/segment.typed"
import { getNormalizedApiUrl } from "app/utils"

import { ContactSupportButton } from "../ContactSupportButton"
import Loading from "../Loading"

export interface InteractiveReportModalProps {
  onClose: () => void
  emmbeddableUserResultId: string | null
  reportName: string | undefined // typically the lab test or kit name
  downloadableResultUrls: string[]
  orderId: string
  labCompanyId: string | undefined
  labTestName: string | undefined
  userType: "patient" | "practitioner"
  latestOrderedResultId?: string
  shouldRevalidate?: boolean
}

export const InteractiveReportModal =
  NiceModal.create<InteractiveReportModalProps>(
    ({
      onClose,
      emmbeddableUserResultId,
      reportName,
      downloadableResultUrls,
      orderId,
      labCompanyId,
      userType,
      latestOrderedResultId,
      shouldRevalidate = true,
    }) => {
      const [loading, setLoading] = useState(true)
      const [error, setError] = useState<ReactNode | null>(null)

      const [embeddableSrc, setEmbeddableSrc] = useState<{
        src?: string
        expiration?: number
      }>({})
      const acknowledgeUnreadResult = useAcknowledgeUnreadResult()

      useEffect(() => {
        const fetchEmbeddableSrc = async () => {
          if (!emmbeddableUserResultId) {
            setError(
              <div className="flex flex-col gap-4 text-center">
                <p>No interactive report was found for this order!</p>
                <Button onClick={onClose} variant="outline-primary">
                  Close
                </Button>
              </div>
            )
            setLoading(false)
            return
          }

          let currentSrcState = embeddableSrc.src
          let currentExpirationState = embeddableSrc.expiration

          if (
            !currentSrcState ||
            (currentExpirationState && currentExpirationState < Date.now())
          ) {
            try {
              const {
                data: {
                  data: { src, expiration },
                },
              }: { data: { data: { src: string; expiration: number } } } =
                await axios.get(
                  getNormalizedApiUrl() +
                    `/user_results/${emmbeddableUserResultId}/embeddable`
                )
              setEmbeddableSrc({ src, expiration })
              currentSrcState = src
              currentExpirationState = expiration
            } catch (e) {
              console.error("Error fetching embeddable src", e)
            }
          }

          if (currentSrcState) {
            setError(null) // Clear any previous error
            trackInteractiveReportView(userType, orderId, labCompanyId)
            if (latestOrderedResultId) {
              // Let this promise run asynchronously.
              acknowledgeUnreadResult(latestOrderedResultId, shouldRevalidate)
            }
          } else {
            setError(
              <div className="flex flex-col gap-4 text-center mx-4">
                <p>
                  The interactive report is currently unavailable. Please
                  refresh to try again or reach out to support for help with
                  this issue.
                </p>
                <div className="flex flex-col sm:flex-row gap-4 justify-center">
                  <Button
                    onClick={() => window.location.reload()}
                    variant="outline-primary"
                  >
                    Refresh
                  </Button>
                  <ContactSupportButton
                    message={
                      "Hi! I am trying to view the interactive report for my order (id: " +
                      orderId +
                      "). There is an error loading the report. Thanks for your help!"
                    }
                  />
                </div>
              </div>
            )
          }

          setLoading(false)
        }
        fetchEmbeddableSrc()
      }, [emmbeddableUserResultId])

      const DownloadButton = (
        <Button
          size="sm"
          className="flex flex-row gap-2 w-full sm:w-auto"
          disabled={!downloadableResultUrls.length}
          onClick={() =>
            downloadableResultUrls.forEach((url, index) => {
              window.open(
                url,
                `${snakeCase(
                  reportName?.toLowerCase() ?? "report"
                )}_result_${index}`
              )
            })
          }
        >
          <FontAwesomeIcon icon={faCircleArrowDown} />
          {`Download PDF${downloadableResultUrls.length > 1 ? "s" : ""}`}
        </Button>
      )

      return (
        <Dialog
          onClose={onClose}
          open
          classes={{ paper: "h-full rounded-xl" }}
          aria-labelledby="interactive-report-modal"
          fullWidth
          maxWidth="xl"
        >
          <div className="relative p-5">
            <div className="flex flex-col sm:flex-row gap-y-4 justify-between text-center sm:text-left items-center text-base font-semibold mr-6 sm:mr-8">
              {!reportName
                ? "Interactive Report"
                : reportName.endsWith("Report")
                ? reportName
                : `${reportName} Report`}
              {downloadableResultUrls.length ? (
                DownloadButton
              ) : (
                <TooltipProvider>
                  <Tooltip content={"PDF is unavailable"}>
                    {DownloadButton}
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
            <FontAwesomeIcon
              className="h-4 font-semibold cursor-pointer absolute right-5 top-1/2 transform -translate-y-1/2"
              onClick={onClose}
              icon={faXmark}
            />
          </div>
          <div className="w-full h-full flex justify-center items-center bg-neutral-100">
            {loading ? (
              <Loading ariaLabel="Loading interactive report" />
            ) : error ? (
              <div className="flex flex-col justify-center items-center">
                <img src={EmptyDocumentsIcon} alt="no report" />
                <div className="text-body text-center max-w-lg">{error}</div>
              </div>
            ) : (
              <iframe
                className="w-full h-full pt-[28px]"
                src={embeddableSrc.src}
                title="Interactive Report"
              />
            )}
          </div>
        </Dialog>
      )
    }
  )
