import { DeepPartial } from "global"
import { useSWRConfig } from "swr"

import { getResourceCacheKey } from "app/swr/helpers/keys"
import { Resource } from "app/swr/types"

/**
 * Write the given resources to the cache without revalidation.
 *
 * Used after fetches and mutations to keep the resource cache up to date.
 *
 * @param config the full swr config
 * @param resources the resource to write to cache
 * @returns the mutation promises
 */
export async function writeToCache(
  config: ReturnType<typeof useSWRConfig>,
  ...resources: Resource[]
) {
  return await Promise.all(
    resources.map(async (resource) =>
      config.mutate(getResourceCacheKey(resource), resource, {
        revalidate: false,
      })
    )
  )
}

/**
 * Merge a stale resource with newly updated data for writing to cache
 */
export function mergeResources<T extends Resource>(
  oldData: T,
  newData: T | Partial<T> | DeepPartial<T>
): T {
  return {
    ...oldData,
    ...newData,
    attributes: { ...oldData.attributes, ...newData.attributes },
    relationships: { ...oldData.relationships, ...newData.relationships },
  }
}
