import * as Sentry from "@sentry/browser"

// This is a simple service to save and load state from local storage

// The prefix is used to namespace the keys in local storage
const prefix = "rupa.saved-state"

export function savedStateKey(key: string) {
  return `${prefix}:${key}`
}

export function loadState<T = unknown>(key: string): T | undefined {
  Sentry.addBreadcrumb({
    level: "debug",
    message: "loadState",
    category: "savedState",
    data: { key },
  })

  const serializedState = window.localStorage.getItem(savedStateKey(key))
  if (serializedState) {
    try {
      const state = JSON.parse(serializedState) as T

      Sentry.addBreadcrumb({
        level: "info",
        message: "loadedState",
        category: "savedState",
        data: { key, state },
      })
      return state
    } catch (ex) {
      Sentry.withScope((scope) => {
        scope.setExtras({ key, serializedState })
        Sentry.captureException(ex, { level: "warning" })
      })

      console.error(ex, key, serializedState)
    }
  }
  return undefined
}

export function saveState(key: string, value: unknown) {
  try {
    Sentry.addBreadcrumb({
      level: "info",
      message: "saveState",
      category: "savedState",
      data: { key, value },
    })

    // undefined doesn't like being json
    const newValue = JSON.stringify(value ?? null)
    const stateKey = savedStateKey(key)
    window.localStorage.setItem(stateKey, newValue)

    // ensures that storage events are fired for the current tab, too
    window.dispatchEvent(
      new StorageEvent("storage", { key: stateKey, newValue })
    )
  } catch (ex) {
    Sentry.withScope((scope) => {
      scope.setExtras({ key, value })
      Sentry.captureException(ex)
    })

    console.error(ex, key, value)
  }
}

export function clearState(key: string) {
  const stateKey = savedStateKey(key)

  Sentry.addBreadcrumb({
    level: "info",
    message: "clearState",
    category: "savedState",
    data: { key },
  })

  window.localStorage.removeItem(stateKey)
  window.dispatchEvent(
    new StorageEvent("storage", { key: stateKey, newValue: null })
  )
}
