/**
 * The ESignature component manages interactions with the HelloSign
 * embedded client and propagates status updates to the parent component.
 *
 * Rendering this component will result in an embedded signing session
 * for the current practitioner via HelloSign.
 */
import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import HelloSign from "hellosign-embedded"

import * as Sentry from "@sentry/react"

import { API } from "app/api"
import useAppSelector from "app/hooks/useAppSelector"
import { hellosignConfig } from "app/settings"
import { showErrorToast } from "app/utils"

interface Props {
  onEsignCancel: () => void
  onEsignClose: () => void
  onEsignCompleted: () => void
  onEsignFail: (exception: Error) => void
  onEsignOpen: () => void
}

enum EmbeddedStatus {
  UNOPENED = "unopened",
  OPENED = "opened",
  CLOSED = "closed",
}
class ESignUrlInvalidError extends Error {
  constructor(message = "Invalid ESign URL") {
    super(message)
    this.name = "ESignUrlInvalidError"
  }
}

/**
 * The ESignature component.
 *
 * This component just renders an empty fragment, and HelloSign injects its own iframe.
 */
const ESignature = ({
  onEsignCancel,
  onEsignClose,
  onEsignCompleted,
  onEsignFail,
  onEsignOpen,
}: Props) => {
  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const signatureNeeded = !practitioner.has_signature
  const [embeddedStatus, setEmbeddedStatus] = useState<EmbeddedStatus>(
    EmbeddedStatus.UNOPENED
  )

  const handleEsignClose = () => {
    setEmbeddedStatus(EmbeddedStatus.CLOSED)
    onEsignClose()
  }

  const handleEsignCancel = () => {
    setEmbeddedStatus(EmbeddedStatus.CLOSED)
    onEsignCancel()
  }

  const handleEsignOpen = () => {
    setEmbeddedStatus(EmbeddedStatus.OPENED)
    onEsignOpen()
  }

  const handleEsignFail = (exception: Error) => {
    onEsignFail(exception)
  }

  const handleEsignEvent = (esignClientEvent: HelloSign.HelloSign.Events) => {
    switch (esignClientEvent.toString()) {
      case HelloSign.events.OPEN:
        handleEsignOpen()
        break
      case HelloSign.events.SIGN:
        onEsignCompleted()
        break
      case HelloSign.events.CLOSE:
        handleEsignClose()
        break
      case HelloSign.events.CANCEL:
        handleEsignCancel()
        break
      default:
        break
    }
  }

  useEmbeddedEsign(
    signatureNeeded,
    embeddedStatus,
    handleEsignEvent,
    handleEsignFail
  )

  return null
}

/**
 * Hook for initializing the ESign session via HelloSign.
 * @returns Memoized promise for initializing the ESignature flow via the API.
 */
function useStartEmbeddedEsignFlow() {
  const dispatch = useDispatch()
  return useCallback(async () => {
    try {
      const response = await API.Esignature.initialize()
      const signUrl = response.data.sign_url
      const isCompleted = response.data.is_completed
      if (!signUrl && !isCompleted) {
        throw new ESignUrlInvalidError()
      }
      return { signUrl: signUrl, isCompleted: isCompleted }
    } catch (error) {
      Sentry.captureException(error)
      dispatch(showErrorToast({ message: "Please try again later." }))
      throw error
    }
  }, [dispatch])
}

/**
 * useEmbeddedEsign is a custom hook which handles the HelloSign client interactions,
 * such as opening the embedded session and propagating events.
 *
 * @param signatureNeeded Boolean to indicate whether the current practitioner requires a signature.
 * @param handleEsignEvent Callback to receive all ESign client events.
 * @param handleEsignFail Callback to receive errors from the ESign client.
 */
function useEmbeddedEsign(
  signatureNeeded: boolean,
  embeddedStatus: EmbeddedStatus,
  handleEsignEvent: Function,
  handleEsignFail: Function
) {
  const startEmbeddedEsignFlow = useStartEmbeddedEsignFlow()
  useEffect(() => {
    const initialize = async () => {
      let embeddedFlow
      try {
        embeddedFlow = await startEmbeddedEsignFlow()
      } catch (error) {
        handleEsignFail(error)
        return
      }

      if (embeddedFlow.isCompleted) {
        // This request has already been signed.
        // Trigger the onEsignCompleted callback to skip to license upload.
        handleEsignEvent(HelloSign.events.SIGN)
        return
      }

      const client = new HelloSign()

      // HelloSign Events
      // https://app.hellosign.com/api/embeddedSigningWalkthrough
      client.on(HelloSign.events.OPEN, () =>
        handleEsignEvent(HelloSign.events.OPEN)
      )
      client.on(HelloSign.events.SIGN, () =>
        handleEsignEvent(HelloSign.events.SIGN)
      )
      client.on(HelloSign.events.CANCEL, () =>
        handleEsignEvent(HelloSign.events.CANCEL)
      )
      client.on(HelloSign.events.CLOSE, () =>
        handleEsignEvent(HelloSign.events.CLOSE)
      )
      // Capture any errors from the client itself and propagate back to sentry.
      client.on(HelloSign.events.ERROR, (error) => {
        handleEsignFail(
          new Error(
            `Failed to initialize ESign session with error code: ${error?.code}`
          )
        )
      })

      // Initialize the HelloSign iframe
      client.open(embeddedFlow.signUrl, hellosignConfig)
    }
    if (signatureNeeded && embeddedStatus === EmbeddedStatus.UNOPENED) {
      initialize()
    }
  }, [signatureNeeded, embeddedStatus, startEmbeddedEsignFlow])
}

export default ESignature
