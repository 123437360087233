import * as React from "react"
import { useState } from "react"
import { useDispatch } from "react-redux"

import { makeStyles, Theme } from "@material-ui/core"

import CheckIcon from "app/assets/icons/check.svg"
import DesignSystemButton from "app/components/design-system/Button"
import ClipboardContainer from "app/components/modals/PhysicianServicesOptInModal/ClipboardContainer"
import { VENDOR_PHYSICIAN_AUTHORIZATION_LABEL } from "app/constants"
import { cancelRed, colors, primaryColor } from "app/theme"
import { showErrorToast } from "app/utils"

const styles = (theme: Theme) => ({
  continueButton: {
    marginBottom: 16,
    fontSize: 17,
    fontWeight: 400,
    width: "100%",
  },
  buttonContainer: {
    flex: 1,
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonContainerFull: {
    width: "100%",
  },
  buttonArea: {
    marginTop: 32,
    display: "flex",
    margin: "auto",
    justifyContent: "center",
    width: 532,
    gap: 16,
    "box-sizing": "border-box",
    [theme.breakpoints.down("sm")]: {
      width: "96%",
      flexDirection: "column",
      gap: 0,
    },
  },
  goBackText: {
    color: primaryColor,
    cursor: "pointer",
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 32,
    height: 24,
  },
  red: {
    color: cancelRed,
  },
  redDisabled: {
    color: colors.red[300],
  },
  title: {
    marginBottom: 16,
    fontWeight: 600,
  },
  link: {
    color: "inherit",
  },
  disabledCheckboxItem: {
    color: colors.blueGray[300],
    display: "flex",
    "text-align": "left",
  },
  checkboxItem: {
    display: "flex",
    "text-align": "left",
  },
  checkbox: {
    appearance: "none" as "none",
    backgroundColor: "#fff",
    borderWidth: 1,
    borderColor: colors.blueGray[300],
    width: 15,
    minWidth: 15,
    height: 15,

    marginRight: 12,
    marginTop: 4,
    cursor: "pointer",
    position: "relative" as "relative",
    borderRadius: 4,

    "&:after": {
      content: '""', // empty string content
      backgroundImage: `url(${CheckIcon})`,
      backgroundSize: "10px 10px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center 20%",

      display: "inline-block",
      width: "100%",
      height: "100%",
      opacity: 0, // hide it initially
    },

    // When the checkbox is checked, show the checkmark
    "&:checked": {
      backgroundColor: primaryColor,
      borderColor: primaryColor,

      // Make the checkmark visible
      "&:after": {
        opacity: 1,
      },
    },
  },
})

const useStyles = makeStyles(styles)

interface Props {
  onSuccess: () => void
  onGoBack: () => void
  onDisable: () => void
  updatedTerms?: boolean
}

/**
 * Apply to account step for physician services opt in modal
 * @param onSuccess - Action to take on step submit
 */
const ApplyToAccountStep = ({
  onSuccess,
  onGoBack,
  onDisable,
  updatedTerms,
}: Props) => {
  const classes = useStyles(styles)
  const dispatch = useDispatch()

  // State to manage the checked status of checkboxes
  const [checkedStates, setCheckedStates] = useState({
    first_step: false,
    second_step: false,
    third_step: false,
  })

  // Function to handle checkbox change
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target
    setCheckedStates((prevState) => ({
      ...prevState,
      [id]: checked,
    }))
  }

  const disabledErrorText = `Please confirm each item to enable ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}`

  const handleDisabledClick = () => {
    dispatch(showErrorToast({ message: disabledErrorText }))
  }

  const buttonDisabled =
    !checkedStates.first_step ||
    !checkedStates.second_step ||
    !checkedStates.third_step

  return (
    <div className="mb-5">
      <ClipboardContainer
        title={
          updatedTerms
            ? `Updated Terms for ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}`
            : `What is ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}?`
        }
      >
        <div>
          <div className={classes.title}>
            We are excited to offer{" "}
            <a
              href="https://www.rupahealth.com/physician-services"
              className="text-body !underline"
            >
              {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}
            </a>{" "}
            on Rupa
            {updatedTerms ? ", and we've updated our Terms & Conditions" : ""}!
            Please carefully read and confirm each item below. 😊
          </div>

          <label className={classes.checkboxItem}>
            <input
              className={classes.checkbox}
              type="checkbox"
              id="first_step"
              checked={checkedStates.first_step}
              onChange={handleCheckboxChange}
            />
            <div>
              I acknowledge that I am not using this service to order testing
              outside of my scope of practice. I understand that I am adding a
              service for a client to gain access to testing through Rupa and
              that a Signing Physician will review the results for critical
              values.<span className={classes.red}>*</span>
            </div>
          </label>
          <br />

          <label
            className={
              !checkedStates.first_step
                ? classes.disabledCheckboxItem
                : classes.checkboxItem
            }
            onClick={
              !checkedStates.first_step ? handleDisabledClick : undefined
            }
          >
            <input
              className={classes.checkbox}
              type="checkbox"
              id="second_step"
              checked={checkedStates.second_step}
              onChange={handleCheckboxChange}
              disabled={!checkedStates.first_step} // Disable this until the first one is checked
            />
            <div>
              I represent and warrant that I (i) hold any and all required
              licenses and/or certifications required by the state in which I
              provide professional services and such licenses and/or
              certifications are unrestricted, valid, and active (ii) I have
              never been convicted of a felony or crime of moral turpitude as
              defined by governing laws of the state in which the client is
              located; (iii) am not currently listed, and have not at any time
              been listed, by a federal agency as excluded, debarred, suspended
              or otherwise ineligible to participate in any federal or state
              funded health care program, including but not limited to Medicare
              and Medicaid; and (iv) do not discriminate in the performance of
              any services or the quality of goods or care provided on the basis
              of race, sex, age, religion or natural origin.
              <span
                className={
                  checkedStates.first_step ? classes.red : classes.redDisabled
                }
              >
                *
              </span>
            </div>
          </label>
          <br />

          <label
            className={
              !checkedStates.second_step
                ? classes.disabledCheckboxItem
                : classes.checkboxItem
            }
            onClick={
              !checkedStates.second_step ? handleDisabledClick : undefined
            }
          >
            <input
              className={classes.checkbox}
              type="checkbox"
              id="third_step"
              checked={checkedStates.third_step}
              onChange={handleCheckboxChange}
              disabled={!checkedStates.second_step} // Disable this until the second one is checked
            />
            <div>
              I agree to{" "}
              <a
                href="https://www.rupahealth.com/terms-and-conditions"
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                <u>Rupa’s Terms of Use and Sale</u>
              </a>
              .
              <span
                className={
                  checkedStates.second_step ? classes.red : classes.redDisabled
                }
              >
                *
              </span>
            </div>
          </label>
          <br />
        </div>
      </ClipboardContainer>
      <div className={classes.buttonArea}>
        {updatedTerms ? (
          <div className={classes.buttonContainer}>
            <DesignSystemButton
              color="destructiveSecondary"
              onClick={onDisable}
              className={classes.continueButton}
            >
              Turn Off {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}
            </DesignSystemButton>
          </div>
        ) : null}
        <div
          className={
            updatedTerms ? classes.buttonContainer : classes.buttonContainerFull
          }
          onClick={buttonDisabled ? handleDisabledClick : undefined}
        >
          <DesignSystemButton
            color="primary"
            onClick={onSuccess}
            className={classes.continueButton}
            disabled={buttonDisabled}
          >
            Confirm!
          </DesignSystemButton>
        </div>
      </div>
      {!updatedTerms ? (
        <div className={classes.goBackText} onClick={onGoBack}>
          Go back
        </div>
      ) : null}
    </div>
  )
}

export default ApplyToAccountStep
