import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, cn } from "@rupahealth/design"

import BaseFileInput from "app/components/upload/BaseFileInput"
import Dropzone from "app/components/upload/dropzone/Dropzone"

import { RESULTS_UPLOAD_IMAGE_TYPES } from "../utils"
import { ResultsUploadFileGroup } from "./ResultsUploadForm"
import { UploadedFileCard } from "./UploadedFileCard"

interface Props {
  files: ResultsUploadFileGroup
  groupId: string
  addFiles: (files: File[], groupId?: string) => void
  removeFile: (fileId: string) => void
}

export const UploadedFilesGroup: React.FC<Props> = ({
  files,
  groupId,
  addFiles,
  removeFile,
}) => {
  const onUploadMore = (files: File[]) => addFiles(files, groupId)

  return (
    <BaseFileInput
      onFilesAdded={onUploadMore}
      allowedFileTypes={RESULTS_UPLOAD_IMAGE_TYPES}
      multiple
      disableClickToUpload
    >
      {(inputProps) => (
        <Dropzone
          {...inputProps}
          className={cn(
            "p-4 rounded-lg border border-solid border-light-blue-200 bg-light-blue-50",
            {
              "border-primary bg-light-blue-100": inputProps.isDragging,
            }
          )}
          hideUploadLogo
        >
          <div className="flex flex-col gap-2.5">
            <span className="text-base15 font-semibold text-left">
              {files.length} Screenshots of a Report
            </span>
            {Object.entries(files).map(([fileId, file]) => (
              <UploadedFileCard
                key={`${groupId}_${fileId}`}
                file={file}
                removeFile={() => removeFile(fileId)}
              />
            ))}

            <Button
              variant="ghost"
              className="w-full justify-start !text-base15 p-0 h-auto text-primary font-semibold hover:text-primary-600 hover:bg-transparent"
              onClick={inputProps.handleClick}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add Screenshots
              to this Report
            </Button>
          </div>
        </Dropzone>
      )}
    </BaseFileInput>
  )
}
