import { Dispatch } from "react"

import axios from "axios"

import * as Sentry from "@sentry/react"

import { getApiBaseUrl, handleApiError, showErrorToast } from "app/utils"
import {
  InvokePipelineResponse,
  LabResult,
  LabResultCreateFromFilesData,
} from "types/lab-result"

// Helper methods for calling LabResultView

export const invokePipelineForLabResult = async (
  labResult: LabResult,
  dispatch: Dispatch<any>
) => {
  try {
    const response = await axios.post<InvokePipelineResponse>(
      getApiBaseUrl() + `/api/normalized/lab_results/${labResult.id}/invoke/`,
      { generate_snapshot: true }
    )
    const jobId = response?.data?.data?.job_id

    if (!jobId) {
      // Unexpected error, capture it
      // We could fallback to creating a user result from the labResult with missing info
      Sentry.captureException(
        "Unknown error invoking pipeline. No job ID returned."
      )
    }
    return jobId
  } catch (error) {
    dispatch(handleApiError(error))
    return
  }
}

export const createLabResultFromOrderedResult = async (
  patientId: string,
  orderedResultId: string,
  dispatch: Dispatch<any>
): Promise<LabResult | undefined> => {
  try {
    const response = await axios.post<{ data: LabResult }>(
      getApiBaseUrl() +
        `/api/normalized/lab_results/create_from_ordered_result/`,
      {
        uploaded_for_patient_id: patientId,
        source_ordered_result_id: orderedResultId,
      }
    )

    const labResult = response?.data?.data

    if (!labResult) {
      dispatch(
        showErrorToast({
          message: "There was an error uploading the lab result",
        })
      )

      return
    }

    return labResult
  } catch (error) {
    dispatch(handleApiError(error))
    return
  }
}

export const createLabResultsFromFiles = async (
  patientId: string,
  fileArrays: File[][],
  dispatch: Dispatch<any>
): Promise<LabResult[] | undefined> => {
  const postBody: LabResultCreateFromFilesData = {
    uploaded_for_patient_id: patientId,
  }
  fileArrays.forEach((files, index) => {
    postBody[`files[${index}]`] = files
  })

  try {
    const response = await axios.post<{ data: LabResult[] }>(
      getApiBaseUrl() + `/api/normalized/lab_results/create_from_files/`,
      postBody,
      { headers: { "Content-Type": "multipart/form-data" } }
    )
    const labResults = response?.data?.data

    if (!labResults) {
      Sentry.captureException(
        "Unknown error uploading lab results. No data returned."
      )

      dispatch(
        showErrorToast({
          message: "There was an error uploading your reports.",
        })
      )
    }

    return labResults
  } catch (error) {
    dispatch(handleApiError(error))
    return
  }
}
