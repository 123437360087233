import { useState } from "react"

import PersonPlusIcon from "app/assets/icons/person-plus.svg"
import RupaButton from "app/components/design-system/Button"
import { Practitioner } from "app/types"

import NewPatientModal from "../modals/NewPatientModal"

interface Props {
  practitioner: Practitioner
  icon?: boolean
  className?: string
}

const AddAClientButtonAndModal: React.FC<Props> = ({
  practitioner,
  icon,
  className,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <RupaButton
        className={className}
        size="small"
        color="secondary"
        onClick={() => setOpen((prev) => !prev)}
      >
        {icon && (
          <img src={PersonPlusIcon} alt="Add a Client" className="me-1" />
        )}
        Add a Client
      </RupaButton>

      <NewPatientModal
        practitioner={practitioner}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  )
}

export default AddAClientButtonAndModal
