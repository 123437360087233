import React, { useCallback, useMemo } from "react"

import clsx from "clsx"

import { faCheck } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { ReactComponent as ResultsEditIcon } from "app/assets/icons/flask.svg"
import { ReactComponent as OrderedResultDocumentIcon } from "app/assets/images/documents/ordered-result-document-icon.svg"
import BodyText from "app/components/design-system/BodyText"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"
import useBloodReportActionsEnabledForOrderedResult from "app/main/blood-lab-dashboards/hooks/use-blood-report-actions-enabled-for-ordered-result"
import useResultsUploadPdfModal from "app/main/results-upload/modals/ResultsUploadPdfModal"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { Kit } from "types/kit"
import { OrderedResultDocument } from "types/patient-with-documents"

import DocumentCardActionsMenu from "./DocumentCardActionsMenu"
import PatientDocumentCard from "./PatientDocumentCard"
import PendingTrackingInTrendsSubTitle from "./PendingTrackingInTrendsSubTitle"
import useBloodReportModal from "./hooks/use-blood-report-modal"

interface Props {
  orderedResult: OrderedResultDocument
}

const OrderedResultDocumentCard: React.FC<Props> = ({ orderedResult }) => {
  const kit = useCachedResource<Kit>(orderedResult.relationships.kit.data)
  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const patient = useAppSelector(({ patient }) => patient.patient)

  const [isManualTrendsEnabled] = useFeatureFlag(
    FeatureFlag.ResultsOverTimeManualResultsUpload
  )

  const { isBloodReportActionEnabled } =
    useBloodReportActionsEnabledForOrderedResult({
      supportsBloodReport: orderedResult?.attributes?.supports_blood_report,
      hasSnapshots:
        !!orderedResult?.relationships?.blood_lab_dashboard_report_snapshots
          .data?.length,
      clinicFeatures: practitioner.clinic?.clinic_features,
      clinicSettings: practitioner.clinic?.clinic_settings,
      inPatientPortal: false,
    })

  const { onModalOpen } = useBloodReportModal(orderedResult, kit)
  const resultsUploadPdfModal = useResultsUploadPdfModal()

  const resultFile = useMemo(() => {
    return orderedResult.attributes.result_file
  }, [orderedResult.attributes.result_file])

  const onClick = useMemo(() => {
    if (isBloodReportActionEnabled) {
      return onModalOpen
    } else if (resultFile) {
      return async () => window.open(resultFile)
    }
    return undefined
  }, [isBloodReportActionEnabled, onModalOpen, resultFile])

  const supportsTrends =
    orderedResult.attributes.supports_trends_visualization &&
    isManualTrendsEnabled

  // If we have snapshots and we support trends, item should be in trends
  const visibleInTrends =
    supportsTrends &&
    orderedResult.relationships.blood_lab_dashboard_report_snapshots.data
      .length > 0

  const subTitle = useMemo(() => {
    if (isBloodReportActionEnabled || visibleInTrends) {
      return (
        <span className="flex items-center gap-[3px]">
          <div className="bg-emerald-500 rounded-full p-[3px] flex items-center justify-center">
            <FontAwesomeIcon icon={faCheck} className="text-white text-[8px]" />
          </div>
          <BodyText size="sm" weight="semibold">
            Tracking in Trends
          </BodyText>
        </span>
      )
    } else if (supportsTrends) {
      return (
        <div>
          <PendingTrackingInTrendsSubTitle title="Pending Tracking in Trends" />
        </div>
      )
    }

    return ""
  }, [isBloodReportActionEnabled])

  const Icon = useCallback(
    (className: string) => {
      if (orderedResult.attributes.result_file_thumbnail) {
        return (
          <img
            className={clsx(className, "w-[90px] h-[111px]")}
            src={orderedResult.attributes.result_file_thumbnail}
            alt="Report Thumbnail"
          />
        )
      }
      return <OrderedResultDocumentIcon className={className} />
    },
    [orderedResult]
  )

  const handleOnDownload = () => {
    if (orderedResult.attributes.result_file) {
      window.open(orderedResult.attributes.result_file)
    }
  }

  const handleConvertToTrends = () => {
    resultsUploadPdfModal.show({
      onClose: () => resultsUploadPdfModal.remove(),
      existingOrderedResultId: orderedResult.id,
      patientId: patient.id,
    })
  }

  return (
    <PatientDocumentCard
      onClick={onClick}
      Icon={Icon}
      preTitle={orderedResult.attributes.lab_company_name?.toUpperCase()}
      title={
        isBloodReportActionEnabled
          ? "Blood Panel Report"
          : kit?.attributes.display_name
      }
      subTitle={subTitle}
      date={orderedResult.attributes.created_at}
      actions={
        isManualTrendsEnabled ? (
          <DocumentCardActionsMenu
            onDownload={
              isBloodReportActionEnabled ? undefined : handleOnDownload
            }
            onDelete={undefined}
            onEdit={visibleInTrends ? undefined : handleConvertToTrends}
            editIcon={visibleInTrends ? undefined : <ResultsEditIcon />}
            editLabel={
              visibleInTrends ? undefined : "Prepare to Track in Trends"
            }
            deleteLabel={undefined}
          />
        ) : null
      }
    />
  )
}

export default OrderedResultDocumentCard
