import { useDispatch } from "react-redux"

import clsx from "clsx"
import copy from "copy-to-clipboard"

import { useMediaQuery } from "@material-ui/core"

import { ReactComponent as LinkIcon } from "app/assets/icons/link.svg"
import Button from "app/components/design-system/Button"
import useLabTestIds from "app/main/comparison/hooks/use-lab-test-ids"
import { getAbsoluteComparisonUrl } from "app/main/comparison/utils"
import { Theme } from "app/theme"
import { showSuccessToast } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles({
  button: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    "& svg": {
      fill: "currentColor",
      marginTop: -3,
    },
  },
})

export default function ComparisonCopyLink({
  className,
}: {
  className?: string
}) {
  const reduxDispatch = useDispatch()
  const classes = useStyles()
  const labTestIds = useLabTestIds()
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"))

  if (!labTestIds || labTestIds.length === 0) {
    return null
  }

  function handleClick() {
    const url = getAbsoluteComparisonUrl(labTestIds)

    copy(url)

    reduxDispatch(
      showSuccessToast({
        message: "Lab test comparison link copied to your clipboard!",
      })
    )
  }

  return (
    <Button
      className={clsx(className, classes.button)}
      color="secondary"
      onClick={handleClick}
      startIcon={<LinkIcon viewBox="0 0 17 16" />}
      type="button"
    >
      {isMobile ? "Share Comparison" : "Copy Link to Comparison"}
    </Button>
  )
}
