import { useEffect, useState } from "react"
import { batch, useDispatch } from "react-redux"

import { isArray } from "lodash"
import { FormProvider, useController, useForm } from "react-hook-form"
import { useDebounce } from "react-use"
import { MutatorOptions } from "swr"
import { z } from "zod"

import { zodResolver } from "@hookform/resolvers/zod"
import { Divider, makeStyles } from "@material-ui/core"

import { getInitialValue } from "app/components/RichTextEditor"
import { ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { showErrorToast } from "app/utils"

import PractitionerProfileAbout from "./PractitionerProfileAbout"
import PractitionerProfilePricing from "./PractitionerProfilePricing"
import PractitionerProfileService from "./PractitionerProfileService"
import { PractitionerProfile } from "./types"
import { urlPassesRegex } from "./utils"

const styles = () => ({
  sectionDivider: {
    marginTop: 34,
    marginBottom: 34,
  },
})

const useStyles = makeStyles(styles)

interface Props {
  profile: PractitionerProfile | undefined
  mutateProfile: (
    mutator: any,
    _mutatorOptions: MutatorOptions<PractitionerProfile>
  ) => Promise<PractitionerProfile | undefined>
  setIsMutatingProfile: (isMutating: boolean) => void
}

const webflowFieldOptionsToCheckboxValue = (data: any[], fieldName: string) => {
  const values: any = {}
  for (const item of data) {
    values[`${fieldName}-${item.id}`] = true
  }
  return values
}

const buildConditionsTreatedJsonFromFormFields = (formData: {
  [key: string]: any
}) => {
  const values: any = {}
  for (const key in formData) {
    if (key.startsWith("condition_treatment_explanations-")) {
      const id = key.replace("condition_treatment_explanations-", "")
      values[id] = formData[key]
    }
  }
  return values
}

const parseFormFieldsFromConditionExplanationsJson = (jsonObject: object) => {
  const values: any = {}
  for (const key in jsonObject) {
    values["condition_treatment_explanations-" + key] = jsonObject[key]
  }
  return values
}

const PractitionerProfileForm = ({
  profile,
  mutateProfile,
  setIsMutatingProfile,
}: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const methods = useForm({
    defaultValues: {
      website: profile?.attributes.website,
      phone_number: profile?.attributes.phone_number,
      practice_description: isArray(profile?.attributes.practice_description)
        ? profile?.attributes.practice_description
        : getInitialValue(),
      waitlist_time_for_appointment:
        profile?.relationships.waitlist_time_for_appointment?.data?.id,
      practice_location: profile?.relationships.practice_location?.data?.id,
      city: profile?.attributes.city,
      state: profile?.attributes.state,
      zip_code: profile?.attributes.zip_code,
      number_of_patients_provided_care_for:
        profile?.relationships.number_of_patients_provided_care_for?.data?.id,
      year_started_practicing: profile?.attributes.year_started_practicing,
      education_credentials: isArray(profile?.attributes.education_credentials)
        ? profile?.attributes.education_credentials
        : getInitialValue(),
      licensed_regions: profile?.relationships?.licensed_regions?.data || [],
      insurance_accepted: profile?.relationships.insurance_accepted?.data?.id,
      insurance_type: profile?.attributes.insurance_type,
      pricing_models: profile?.relationships.pricing_models?.data,
      pricing_for_clients: profile?.attributes.pricing_for_clients,
      has_free_initial_patient_consulation:
        profile?.attributes?.has_free_initial_patient_consulation,
      ...webflowFieldOptionsToCheckboxValue(
        profile?.relationships?.specialties?.data || [],
        "specialties"
      ),
      ...webflowFieldOptionsToCheckboxValue(
        profile?.relationships?.conditions_treated?.data || [],
        "conditions_treated"
      ),
      ...parseFormFieldsFromConditionExplanationsJson(
        profile?.attributes?.condition_treatment_explanations || {}
      ),
    },
    resolver: zodResolver(
      z.object({
        website: z
          .string()
          .refine(
            (value) => urlPassesRegex(value),
            "Please enter a valid URL including http:// or https://"
          ),
        waitlist_time_for_appointment: z
          .string()
          .nonempty("This field is required."),
        practice_location: z.string().nonempty("This field is required."),
        city: z.string().nonempty("This field is required."),
        state: z.string().nonempty("This field is required."),
        zip_code: z
          .string()
          .length(5, "Zip code must be 5 digits.")
          .nonempty("This field is required."),
        number_of_patients_provided_care_for: z
          .string()
          .nonempty("This field is required."),
        insurance_accepted: z.string().nonempty("This field is required."),
        insurance_type: z
          .string()
          .nonempty("This field is required.")
          .refine(
            (val) => val.length && val.length < 281,
            "This field requires less than 280 characters."
          ),
        year_started_practicing: z.string().nonempty("This field is required."),
        education_credentials: z.array(z.any()).refine((value) => {
          const hasText = value.some((node) => {
            return node.children.some((child) => {
              if (child.children) {
                return child.children.some((child) => !!child.text)
              }
              return !!child.text
            })
          })

          return hasText
        }, "This field is required."),
        states_can_see_clients_in: z
          .array(z.string())
          .nonempty("This field is required."),
        pricing_models: z.array(z.string()).nonempty("This field is required."),
        pricing_for_clients: z
          .string()
          .nonempty("This field is required.")
          .refine(
            (val) => val.length && val.length < 281,
            "This field requires less than 280 characters."
          ),
        has_free_initial_patient_consulation: z.boolean(),
      })
    ),
    mode: "onChange",
  })

  const [profileAttributeUpdates, setProfileAttributeUpdates] = useState<
    Partial<PractitionerProfile["attributes"]>
  >({}) // form field values

  const [profileRelationshipUpdates, setProfileRelationshipUpdates] = useState<
    Partial<PractitionerProfile["relationships"]>
  >({})

  const updateProfile = async () => {
    if (!profile) {
      return
    }
    setIsMutatingProfile(true)

    // if no changes, don't update
    if (
      Object.keys(profileAttributeUpdates).length === 0 &&
      Object.keys(profileRelationshipUpdates).length === 0
    ) {
      return
    }

    // Sometimes the form will be invalid by update time, don't send those fields.
    for (const key in profileAttributeUpdates) {
      // @ts-ignore
      const isInvalid = methods.getFieldState(key)?.invalid
      if (isInvalid) {
        delete profileAttributeUpdates[key]
      }
    }

    const simpleRelationships = {}
    const singleResourceRelationships = [
      "waitlist_time_for_appointment",
      "practice_location",
      "number_of_patients_provided_care_for",
      "insurance_accepted",
    ]

    for (const fieldName in profileRelationshipUpdates) {
      const relationship = !!profileRelationshipUpdates[fieldName]
        ? {
            type: "WebflowFieldOption",
            id: profileRelationshipUpdates[fieldName],
          }
        : null // null is used to unset the relationship

      if (singleResourceRelationships.includes(fieldName)) {
        simpleRelationships[fieldName] = { data: relationship }
      } else {
        const data: any[] = []
        for (const id of profileRelationshipUpdates[fieldName]) {
          data.push({ type: "WebflowFieldOption", id })
        }
        simpleRelationships[fieldName] = { data }
      }
    }

    // remove condition_treatment_explanations from profileAttributeUpdates
    const conditionTreatmentExplanations = {
      ...profile.attributes.condition_treatment_explanations,
      ...buildConditionsTreatedJsonFromFormFields(profileAttributeUpdates),
    }
    for (const key in profileAttributeUpdates) {
      if (key.startsWith("condition_treatment_explanations-")) {
        delete profileAttributeUpdates[key]
      }
    }

    const relationships = {
      specialties: {
        data: [] as any[],
      },
      conditions_treated: {
        data: [] as any[],
      },
    }

    const formValues = methods.getValues()
    for (const key in formValues) {
      if (formValues[key] && key.startsWith("conditions_treated-")) {
        const id = key.replace("conditions_treated-", "")
        relationships.conditions_treated.data.push({
          type: "WebflowFieldOption",
          id,
        })
      }

      if (formValues[key] && key.startsWith("specialties-")) {
        const id = key.replace("specialties-", "")
        relationships.specialties.data.push({
          type: "WebflowFieldOption",
          id,
        })
      }
    }

    // remove condition_treatment_explanations that are no longer in conditions_treated
    for (const explanationId of Object.keys(conditionTreatmentExplanations)) {
      if (
        !relationships.conditions_treated.data.find(
          (item) => item.id === explanationId
        )
      ) {
        delete conditionTreatmentExplanations[explanationId]
      }
    }

    profileAttributeUpdates.condition_treatment_explanations =
      conditionTreatmentExplanations

    const patch = {
      data: {
        type: "PractitionerProfile",
        id: profile.id,
        attributes: profileAttributeUpdates,
        relationships: {
          ...relationships,
          ...simpleRelationships,
        },
      },
    }

    batch(() => {
      setProfileAttributeUpdates({})
      setProfileRelationshipUpdates({})
    })

    try {
      await mutateProfile(
        resourceRequest<ResourceResponse<PractitionerProfile>>({
          url: `/practitioner_profiles/${profile.id}/`,
          method: "PATCH",
          data: patch,
        }),
        {
          revalidate: false,
          throwOnError: true,
        }
      ).finally(() => {
        setIsMutatingProfile(false)
      })
    } catch (e) {
      setIsMutatingProfile(false)
      dispatch(
        showErrorToast({
          message: `There was an error saving your information. Please refresh to try again or reach out to hello@rupahealth.com for support with this issue.`,
        })
      )
    }
  }

  useDebounce(
    () => {
      updateProfile()
    },
    400,
    [profileAttributeUpdates, profileRelationshipUpdates]
  )

  // @ts-ignore
  const { field: practiceDescriptionField } = useController({
    control: methods.control,
    name: "practice_description",
    shouldUnregister: true,
  })

  // @ts-ignore
  const {
    field: educationCredentialsField,
    formState: educationCredentialsFormState,
  } = useController({
    control: methods.control,
    name: "education_credentials",
    shouldUnregister: true,
  })

  // @ts-ignore
  const { field: statesSelectField, formState: statesSelectFormState } =
    useController({
      control: methods.control,
      name: "licensed_regions",
      shouldUnregister: true,
    })

  // @ts-ignore
  const { field: pricingSelectField, formState: pricingSelectFormState } =
    useController({
      control: methods.control,
      name: "pricing_models",
      shouldUnregister: true,
    })

  const { field: insuranceAcceptedField } = useController({
    control: methods.control,
    name: "insurance_accepted",
    shouldUnregister: true,
  })

  const { field: yearStartedPracticingField } = useController({
    control: methods.control,
    name: "year_started_practicing",
    shouldUnregister: true,
  })

  const { field: numberOfPatientsProvidedCareForField } = useController({
    control: methods.control,
    name: "number_of_patients_provided_care_for",
    shouldUnregister: true,
  })

  const { field: stateField } = useController({
    control: methods.control,
    name: "state",
    shouldUnregister: true,
  })

  const { field: practiceLocationField } = useController({
    control: methods.control,
    name: "practice_location",
    shouldUnregister: true,
  })

  const { field: waitlistTimeForAppointmentField } = useController({
    control: methods.control,
    name: "waitlist_time_for_appointment",
    shouldUnregister: true,
  })

  useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      if (!profile) {
        return
      }

      if (!name) {
        return
      }

      const relationshipFields = [
        "waitlist_time_for_appointment",
        "practice_location",
        "specialties",
        "number_of_patients_provided_care_for",
        "pricing_models",
        "insurance_accepted",
        "licensed_regions",
      ]

      if (relationshipFields.includes(name)) {
        const relationships = { [name]: value[name] }
        setProfileRelationshipUpdates((prev) => ({ ...prev, ...relationships }))
      } else {
        const attributes = { [name]: value[name] }
        setProfileAttributeUpdates((prev) => ({ ...prev, ...attributes }))
      }
    })
    return () => subscription.unsubscribe()
  }, [methods])

  if (!profile) {
    return null
  }

  return (
    <FormProvider {...methods}>
      <form>
        <>
          <PractitionerProfileAbout
            profile={profile}
            mutateProfile={mutateProfile}
            practiceDescriptionField={practiceDescriptionField}
            educationCredentialsField={educationCredentialsField}
            educationCredentialsFormState={educationCredentialsFormState}
            statesSelectField={statesSelectField}
            statesSelectFormState={statesSelectFormState}
            yearStartedPracticingField={yearStartedPracticingField}
            numberOfPatientsProvidedCareForField={
              numberOfPatientsProvidedCareForField
            }
            stateField={stateField}
            practiceLocationField={practiceLocationField}
            waitlistTimeForAppointmentField={waitlistTimeForAppointmentField}
          />
          <Divider className={classes.sectionDivider} />
          <PractitionerProfilePricing
            pricingSelectField={pricingSelectField}
            pricingSelectFormState={pricingSelectFormState}
            insuranceAcceptedField={insuranceAcceptedField}
          />
          <Divider className={classes.sectionDivider} />
          <PractitionerProfileService practitionerProfile={profile} />
        </>
      </form>
    </FormProvider>
  )
}

export default PractitionerProfileForm
