import { useState } from "react"
import { useDispatch } from "react-redux"

import { API } from "app/api"
import DesignSystemButton from "app/components/design-system/Button"
import { OrderEvent, OrderEventKey } from "app/types"
import { showErrorToast } from "app/utils"

interface Props {
  event: OrderEvent
}

export async function downloadReceipt(event: OrderEvent) {
  if (event.metadata.order_payment_id) {
    const response = await API.Receipts.download(
      event.metadata.order_payment_id
    )
    window.location = response.data.url
  } else {
    const response = await API.Invoices.download(event.metadata.invoice_id)
    window.location = response.data.url
  }
}

/**
 * This component allows custom actions for each OrderEvent key.
 * For example, the ORDER_PAID_PRACTITIONER key generates a receipt download link.
 */
function OrderEventAction({ event }: Props) {
  const dispatch = useDispatch()

  const [actionLoading, setActionLoading] = useState(false)
  if (event.key === OrderEventKey.ORDER_PAID_PRACTITIONER) {
    return (
      <DesignSystemButton
        color="text"
        disabled={actionLoading}
        loading={actionLoading}
        variant="text"
        className="text-sm"
        onClick={async () => {
          try {
            setActionLoading(true)
            await downloadReceipt(event)
          } catch {
            setActionLoading(false)
            dispatch(
              showErrorToast({
                message: "Unable to open the receipt. Please try again later.",
              })
            )
          }
        }}
      >
        See the receipt here.
      </DesignSystemButton>
    )
  }
  return null
}

export default OrderEventAction
