import { faCheck } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface Props {
  title: string
}

const TrackingInTrendsSubTitle = ({ title }: Props) => {
  return (
    <span className="flex items-center gap-[3px]">
      <div className="bg-emerald-500 rounded-full p-[4px] flex items-center justify-center">
        <FontAwesomeIcon icon={faCheck} className="text-white text-[8px]" />
      </div>
      <span className="text-sm13 font-semibold">{title}</span>
    </span>
  )
}

export default TrackingInTrendsSubTitle
