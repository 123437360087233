import { useState } from "react"

import PageToolbar from "app/components/PageToolbar"
import RupaButton from "app/components/design-system/Button"
import useAppSelector from "app/hooks/useAppSelector"

import StartOrderModal from "../modals/StartOrderModal"
import AddAClientButtonAndModal from "./AddAClientButtonAndModal"
import ClientsTable from "./table/ClientsTable"

const ClientsPage: React.FC = () => {
  const practitioner = useAppSelector(({ practitioner }) => practitioner)

  const [orderOpen, setOrderOpen] = useState(false)

  return (
    <div>
      <PageToolbar title="Clients">
        <AddAClientButtonAndModal practitioner={practitioner} />

        <RupaButton
          size="small"
          color="primary"
          onClick={() => setOrderOpen(true)}
        >
          Start an Order
        </RupaButton>

        <StartOrderModal open={orderOpen} onClose={() => setOrderOpen(false)} />
      </PageToolbar>

      <ClientsTable />
    </div>
  )
}

export default ClientsPage
