import { ComponentProps, forwardRef, useCallback, useState } from "react"
import { useDispatch } from "react-redux"

import { Button as MuiButton } from "@material-ui/core"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"

import { ReactComponent as LinearChartIcon } from "app/assets/icons/results-summary/linear-chart.svg"
import { ReactComponent as AirplaneBlue } from "app/assets/images/airplane-blue.svg"
import { NavyTooltip } from "app/components/NavyTooltip"
import BodyText from "app/components/design-system/BodyText"
import ConfirmationModal from "app/components/modals/ConfirmationModal"
import IconConfirmationModal from "app/components/modals/IconConfirmationModal"
import useEventCallback from "app/hooks/use-event-callback"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useResultsSummaryModal from "app/results-summary/hooks/use-results-summary-modal"
import { trackPractitionerDashboardAction } from "app/services/segment.typed"
import { colors, primaryColor, shadows } from "app/theme"
import { OrderedTest, Patient, PatientOrdersOrder } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import { PatientOrderEvent } from "../constants"
import * as Actions from "../store/actions"

const useResultsIconButtonStyles = makeAppStyles((theme) => ({
  actionButton: {
    height: 36,
    width: 36,
    border: `1px solid ${colors.blueGray[300]}`,
    borderRadius: 6,
    padding: "5px 8px",
    minWidth: 0,
    marginRight: 8,
    backgroundColor: "white",
    boxShadow: shadows.sm,
    "&:disabled": {
      opacity: 0.5,
    },

    [theme.breakpoints.up("md")]: {
      marginLeft: 8,
      marginRight: "auto",
    },
  },
}))

interface ResultsIconButtonProps extends ComponentProps<typeof MuiButton> {
  onClick?: () => void
  orderedTest: OrderedTest
}

const ResultsIconButton = forwardRef<HTMLButtonElement, any>(
  ({ onClick, orderedTest, ...props }: ResultsIconButtonProps, ref) => {
    const styles = useResultsIconButtonStyles()

    return (
      <MuiButton
        {...props}
        ref={ref}
        className={styles.actionButton}
        disabled={!orderedTest.results}
        key="airplane-button"
        onClick={onClick}
      >
        <AirplaneBlue className={styles.icon} />
      </MuiButton>
    )
  }
)

const LegacyResultsAction = (props: {
  onClick: () => void
  orderedTest: OrderedTest
  patient: Patient
}) => {
  const [showPreliminary] = useFeatureFlag(
    FeatureFlag.PreliminaryResultNotifications
  )

  const isPreliminary =
    props.orderedTest.latest_user_result_status?.includes("preliminary")

  return (
    <NavyTooltip
      arrow
      placement="top"
      title={
        !props.orderedTest.results ? (
          <>
            Send Results to {props.patient.first_name} <br />
            (Not Available Yet)
          </>
        ) : props.orderedTest.date_results_sent_to_patient ? (
          isPreliminary && showPreliminary ? (
            `Re-send Preliminary Results to ${props.patient.first_name}`
          ) : (
            `Re-send Results to ${props.patient.first_name}`
          )
        ) : isPreliminary && showPreliminary ? (
          `Send Preliminary Results to ${props.patient.first_name}`
        ) : (
          `Send Results to ${props.patient.first_name}`
        )
      }
    >
      <div>
        <ResultsIconButton
          onClick={props.onClick}
          orderedTest={props.orderedTest}
        />
      </div>
    </NavyTooltip>
  )
}

const useResultsActionDropdownMenuStyles = makeAppStyles((theme) => ({
  dropdownContent: {
    width: 250,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 0.5),
    background: "white",
    borderRadius: 6,
    border: `1px solid ${primaryColor}`,
    boxShadow: shadows.lg,
    marginTop: 5,
    overflowY: "auto",
    zIndex: 1300,
  },
  item: {
    display: "flex",
    alignItems: "center",
    padding: "14px 19px",
    cursor: "pointer",
    fontSize: 15,

    "&:focus, &:hover": {
      "& .checkbox": {
        borderColor: primaryColor,
      },
    },
  },
  itemLabel: {
    marginLeft: theme.spacing(1.0),
    whiteSpace: "nowrap",
  },
  itemIcon: {
    minWidth: 15,
    width: 15,
    height: 15,
  },
  separator: {
    margin: theme.spacing(0, "auto"),
    background: colors.blueGray[200],
    height: 1,
    width: "85%",
  },
}))

const ResultsActionDropdownMenu = (props: {
  openSendResultConfirmationModal: () => void
  openResultsVisualizerModal: () => void
  order: PatientOrdersOrder
  orderedTest: OrderedTest
  patient: Patient
}) => {
  const styles = useResultsActionDropdownMenuStyles()
  const [showPreliminary] = useFeatureFlag(
    FeatureFlag.PreliminaryResultNotifications
  )

  return (
    <DropdownMenu.Root modal={false}>
      <NavyTooltip
        arrow
        placement="top"
        title={
          !props.orderedTest.results ? (
            <>
              Send Results to {props.patient.first_name} <br />
              (Not Available Yet)
            </>
          ) : props.orderedTest.date_results_sent_to_patient ? (
            props.orderedTest.latest_user_result_status?.includes(
              "preliminary"
            ) && showPreliminary ? (
              `Re-send Preliminary Results to ${props.patient.first_name}`
            ) : (
              `Re-send Results to ${props.patient.first_name}`
            )
          ) : props.orderedTest.latest_user_result_status?.includes(
              "preliminary"
            ) && showPreliminary ? (
            `Send Preliminary Results to ${props.patient.first_name}`
          ) : (
            `Send Results to ${props.patient.first_name}`
          )
        }
      >
        <span>
          <DropdownMenu.Trigger asChild>
            <ResultsIconButton orderedTest={props.orderedTest} />
          </DropdownMenu.Trigger>
        </span>
      </NavyTooltip>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="end"
          className={styles.dropdownContent}
          sideOffset={5}
          onCloseAutoFocus={(e) => {
            e.preventDefault()
          }}
        >
          {/* Send Results to Patient */}
          <DropdownMenu.Item
            className={styles.item}
            onSelect={() => {
              props.openSendResultConfirmationModal()
            }}
          >
            <AirplaneBlue className={styles.itemIcon} viewBox="0 0 18 18" />
            <BodyText
              className={styles.itemLabel}
              color="primary"
              weight="semibold"
            >
              Send to {props.patient.first_name}
            </BodyText>
          </DropdownMenu.Item>

          <DropdownMenu.Separator className={styles.separator} />

          {/* Summarize Results */}
          {props.order.has_ordered_results && (
            <DropdownMenu.Item
              className={styles.item}
              onSelect={() => {
                props.openResultsVisualizerModal()
              }}
            >
              <LinearChartIcon
                className={styles.itemIcon}
                viewBox="0 0 17 15"
              />
              <BodyText
                className={styles.itemLabel}
                color="primary"
                weight="semibold"
              >
                Create Results Summary
              </BodyText>
            </DropdownMenu.Item>
          )}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default function PatientOrdersOrderedTestResultsAction(props: {
  order: PatientOrdersOrder
  orderedTest: OrderedTest
  patient: Patient
}) {
  const [resultsVisualizerEnabled] = useFeatureFlag(
    FeatureFlag.ResultsVisualizer
  )
  const dispatch = useDispatch()
  const [
    sendResultConfirmationModalIsOpen,
    setSendResultConfirmationModalIsOpen,
  ] = useState(false)

  const [showPreliminary] = useFeatureFlag(
    FeatureFlag.PreliminaryResultNotifications
  )
  const isPreliminary =
    props.orderedTest.latest_user_result_status?.includes("preliminary")

  const sendResultsToPatient = useCallback(() => {
    trackPractitionerDashboardAction(
      PatientOrderEvent.SEND_RESULTS,
      props.order.practitioner.id
    )
    dispatch(
      Actions.sendResultsToPatient(
        showPreliminary,
        props.orderedTest,
        props.patient,
        () => setSendResultConfirmationModalIsOpen(false)
      )
    )
  }, [props.orderedTest, props.patient, setSendResultConfirmationModalIsOpen])

  const openSendResultConfirmationModal = useEventCallback(() =>
    setSendResultConfirmationModalIsOpen(true)
  )

  const modal = useResultsSummaryModal()
  const openResultsVisualizerModal = useEventCallback(() =>
    modal.show({ orderId: props.order.id })
  )

  return (
    <div title="Results">
      {resultsVisualizerEnabled ? (
        <ResultsActionDropdownMenu
          openSendResultConfirmationModal={openSendResultConfirmationModal}
          openResultsVisualizerModal={openResultsVisualizerModal}
          order={props.order}
          orderedTest={props.orderedTest}
          patient={props.patient}
        />
      ) : (
        <LegacyResultsAction
          onClick={openSendResultConfirmationModal}
          orderedTest={props.orderedTest}
          patient={props.patient}
        />
      )}

      {!showPreliminary && (
        <ConfirmationModal
          open={sendResultConfirmationModalIsOpen}
          title={`Send Results to ${props.patient.first_name}`}
          message={`Email ${props.patient.first_name} a secure link to their results.`}
          backButtonTitle="Cancel"
          confirmButtonTitle={"Send Results"}
          confirmButtonColor="primary"
          onClose={() => setSendResultConfirmationModalIsOpen(false)}
          handleConfirm={sendResultsToPatient}
        />
      )}

      {showPreliminary && (
        <IconConfirmationModal
          open={sendResultConfirmationModalIsOpen}
          title={`Send ${isPreliminary ? "Preliminary" : ""} Results to ${
            props.patient.first_name
          }`}
          message={`Email ${props.patient.first_name} a secure link to their ${
            isPreliminary ? "preliminary" : ""
          } results.`}
          backButtonTitle="Cancel"
          confirmButtonTitle={"Send"}
          confirmButtonColor="primary"
          onClose={() => setSendResultConfirmationModalIsOpen(false)}
          handleConfirm={sendResultsToPatient}
        />
      )}
    </div>
  )
}
