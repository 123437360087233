import { useCallback } from "react"

import { useLocalStorage } from "app/hooks/use-local-storage"

// the prefix used to store upload jobs in local storage
const KEY_PREFIX = "practitionerDismissedResultsMissingInfo"

const dismissedResultsKey = (userId: string) => `${KEY_PREFIX}.${userId}`

interface Props {
  userId: string
}

export const useDismissedResultsMissingInfo = ({ userId }: Props) => {
  const [dismissedResultIds, setDismissedResultIds] = useLocalStorage<string[]>(
    dismissedResultsKey(userId),
    []
  )

  const dismissResult = useCallback(
    (resultId: string) => {
      setDismissedResultIds([...dismissedResultIds, resultId])
    },
    [dismissedResultIds, setDismissedResultIds]
  )

  return { dismissedResultIds, dismissResult, setDismissedResultIds }
}
