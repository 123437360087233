import { CircularProgress, TextField } from "@material-ui/core"

import { ReactComponent as SearchIcon } from "app/assets/icons/search-icon.svg"
import { primaryColor } from "app/theme"

export interface SearchInputProps {
  value?: string
  isLoading?: boolean
  placeholder?: string
  fieldMessage?: string
  onChange: (value: string) => void
}

function SearchInput({
  value,
  isLoading,
  placeholder,
  fieldMessage,
  onChange,
}: SearchInputProps) {
  return (
    <div className="mt-4 max-w-[500px]">
      <TextField
        size="small"
        variant="outlined"
        fullWidth
        value={value}
        placeholder={placeholder || "Search..."}
        onChange={(e) => onChange(e.target.value)}
        className="bg-white rounded-[4px]"
        InputProps={{
          endAdornment: (
            <>
              {isLoading ? (
                <CircularProgress color="primary" size={16} />
              ) : (
                <SearchIcon width={16} height={16} fill={primaryColor} />
              )}
            </>
          ),
        }}
      />
      {fieldMessage && <div className="pt-2">{fieldMessage}</div>}
    </div>
  )
}

export default SearchInput
