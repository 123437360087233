import React from "react"

import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@rupahealth/design"

import { ResultsUploadFormStep } from "./ResultsUploadForm"
import { Step1UploadProps } from "./Step1Upload"
import { UploadedFileCard } from "./UploadedFileCard"
import { UploadedFilesGroup } from "./UploadedFilesGroup"

type Props = Pick<
  Step1UploadProps,
  "fileGroups" | "addFiles" | "removeFile" | "setStep"
> & {
  handleUploadClick: (e: React.MouseEvent) => void
}

export const Step1UploadMore: React.FC<Props> = ({
  fileGroups,
  handleUploadClick,
  setStep,
  addFiles,
  removeFile,
}) => {
  const hasGroupedFiles = Object.values(fileGroups).some(
    (g) => g instanceof Object && Object.keys(g).length > 1
  )

  return (
    <div className="flex flex-col items-center gap-[18px] w-full">
      {hasGroupedFiles && (
        <div className="py-3 px-4 rounded-lg bg-light-blue-50 flex items-center gap-3.5 text-left">
          <div className="text-3xl">📑 </div>
          <div className="flex flex-col ">
            <div className="font-semibold">We Grouped Your Screenshots</div>
            <div>
              If your screenshots are for multiple reports, please remove them
              and add as new reports.
            </div>
          </div>
        </div>
      )}
      {Object.entries(fileGroups).map(([groupId, fileGroup]) => (
        <div key={groupId} className="w-full">
          {fileGroup instanceof File ? (
            <UploadedFileCard
              file={fileGroup}
              removeFile={() => removeFile(groupId)}
            />
          ) : (
            <UploadedFilesGroup
              files={fileGroup}
              groupId={groupId}
              addFiles={(files: File[]) => addFiles(files, groupId)}
              removeFile={(fileId: string) => removeFile(groupId, fileId)}
            />
          )}
        </div>
      ))}
      <Button
        variant="ghost"
        className="w-full !text-base15 p-0 h-auto text-primary font-semibold hover:text-primary-600 hover:bg-transparent"
        onClick={handleUploadClick}
      >
        <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add Report
      </Button>
      <hr className="h-[1px] bg-slate-200 w-full" />
      <Button
        variant="primary"
        className="w-full"
        onClick={() => setStep(ResultsUploadFormStep.Confirm)}
      >
        Continue
      </Button>
    </div>
  )
}
