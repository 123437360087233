import { useEffect, useState } from "react"

function preloadImage(src: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image()

    image.onload = () => resolve(image)
    image.onerror = image.onabort = () => reject(src)
    image.src = src
  })
}

/**
 * Preload a set of image srcs.
 *
 * Note: Be careful about relying on the hook if `images` ever changes. This does not cause a flip of `loaded` back to `false`.
 *
 * @param images an array of image srcs to preload
 * @returns a boolean indicating whether the images have been loaded
 */
export default function usePreloadImages(images: string[]) {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    async function effect() {
      try {
        await Promise.all(
          images.filter((image) => !!image).map((image) => preloadImage(image))
        )
        // If an image can't be preloaded, just set loaded `true` so we don't block rendering
      } catch (error) {
        console.error({ error, images })
      }

      setLoaded(true)
    }

    effect()
  }, [images])

  return loaded
}

export function usePreloadImagesOnDemand(images: string[]) {
  const [loaded, setLoaded] = useState(false)

  const preload = async () => {
    try {
      await Promise.all(images.map((image) => preloadImage(image)))
    } catch (error) {
      console.error(error)
    }
    setLoaded(true)
  }

  return { loaded, preload }
}
