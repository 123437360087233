import { faXmark } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Theme, useMediaQuery } from "@material-ui/core"

import { ReactComponent as ResultFileIcon } from "app/assets/images/results-over-time/result-file.svg"
import BodyText from "app/components/design-system/BodyText"
import { ResultsUploadButton } from "app/main/results-upload-v2/ResultsUploadButton"
import formatDateForHuman from "app/utils/format-date-for-human"
import { ResultVisualizationNotification } from "types/result-visualization-notification"

interface Props {
  patientId: string
  notification: ResultVisualizationNotification
  onAcknowledge: () => void
  onDismiss: () => void
}

const ResultVisualuzationNotificationItem = ({
  notification,
  patientId,
  onAcknowledge,
  onDismiss,
}: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
  return (
    <div className="flex justify-between items-center w-full">
      <div className="flex sm:flex-row flex-col gap-[9px] pr-2 sm:items-center items-start justify-between w-full">
        <div className="flex sm:flex-row flex-col gap-[9px] sm:items-center items-start">
          <ResultFileIcon />
          <BodyText>
            <span className="font-semibold">
              {notification.attributes.lab_company_name}
            </span>{" "}
            result on {formatDateForHuman(notification.attributes.created_at)}
          </BodyText>
        </div>
        <div className="flex gap-[9px] items-center">
          <ResultsUploadButton
            patientId={patientId}
            buttonText="Prepare to Track in Trends"
            existingUserResultId={
              notification.relationships?.user_result?.data?.id
            }
            existingOrderedResultId={
              notification.relationships?.ordered_result?.data?.id
            }
            onClick={onAcknowledge}
          />
          {!isMobile && (
            <FontAwesomeIcon
              icon={faXmark}
              className="text-slate-400 text-lg17 cursor-pointer"
              onClick={onDismiss}
            />
          )}
        </div>
      </div>
      <FontAwesomeIcon
        icon={faXmark}
        className="text-slate-400 text-lg17 mr-2 sm:hidden block"
        onClick={onDismiss}
      />
    </div>
  )
}

export default ResultVisualuzationNotificationItem
