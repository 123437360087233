import React from "react"

import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@rupahealth/design"

import useResultsUploadManualEntryModal from "app/main/results-upload/modals/ResultsUploadManualEntryModal"

import { Step1UploadProps } from "./Step1Upload"

type Props = Pick<Step1UploadProps, "patientId" | "onClose"> & {
  handleUploadClick: (e: React.MouseEvent) => void
}

export const Step1UploadInitial: React.FC<Props> = ({
  patientId,
  onClose,
  handleUploadClick,
}) => {
  const resultsUploadManualEntryModal = useResultsUploadManualEntryModal()

  return (
    <div className="flex flex-col items-center gap-[9px] w-full transition-opacity duration-500">
      <span>Upload PDFs or screenshots of your lab test reports.</span>
      <Button variant="primary" className="w-full" onClick={handleUploadClick}>
        <FontAwesomeIcon icon={faPlus} className="text-white mr-2" /> Select
        Files
      </Button>
      <span className="text-slate-500">or</span>
      <Button
        variant="ghost"
        className="text-primary p-0 m-0 h-auto w-full hover:bg-transparent"
        onClick={(e) => {
          resultsUploadManualEntryModal.show({
            patientId,
            onClose: () => resultsUploadManualEntryModal.remove(),
          })
          onClose()
          e.stopPropagation()
        }}
      >
        Enter Results Manually
      </Button>
    </div>
  )
}
