import React, { useMemo } from "react"

import { Theme, useMediaQuery } from "@material-ui/core"

import useFeatureFlag from "app/hooks/use-feature-flag"
import {
  StoredUploadJobStatus,
  useStoredUploadJobs,
} from "app/main/results-upload-v2/results-upload-modal/hooks/use-stored-upload-jobs"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { useInvalidateCollections } from "app/swr/hooks/use-invalidate-collections"
import { Patient } from "app/types"

import { ResultInProgressPill } from "./ResultInProgressPill"
import { ScanningResultsLottie } from "./ScanningResultsLottie"

interface Props {
  patient: Patient
}

export const ResultsInProgressBanner: React.FC<Props> = (props) => {
  const [simultaneousUploadsEnabled, flagLoading] = useFeatureFlag(
    FeatureFlag.SimultaneousResultsUploads
  )
  const showBanner = useMemo(
    () => !flagLoading && simultaneousUploadsEnabled,
    [flagLoading, simultaneousUploadsEnabled]
  )

  if (!showBanner) {
    return null
  }

  return <ResultsInProgressBannerInner {...props} />
}

const ResultsInProgressBannerInner: React.FC<Props> = ({ patient }) => {
  // These are the collections that should be invalidated when a result upload is completed
  const invalidateOnSuccess = useInvalidateCollections([
    "/documents/",
    "/body_system/",
    "/results_over_time/",
    "/user_results/",
  ])

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
  const { storedJobStatuses, removeStoredUploadJobs } = useStoredUploadJobs({
    patientId: patient.id,
  })

  const onRemove = (statusId: string) => {
    // TODO: PROD-2312 actually update the LabResult record
    removeStoredUploadJobs([statusId])
  }

  const onSuccess = async (jobStatus: StoredUploadJobStatus) => {
    if (jobStatus.overallPercent !== 100) {
      return
    }
    await invalidateOnSuccess()
  }

  if (!storedJobStatuses?.length) {
    // TODO: when the banner appears / disappears, windshield animate it
    return null
  }

  return (
    <div className="bg-sky-50 border border-light-blue-200 rounded-[10px] p-5">
      <div className="flex items-center gap-5">
        {!isMobile && <ScanningResultsLottie height={100} />}
        <div className="w-full flex flex-col gap-2">
          <div className="flex justify-between items-center">
            <div className="font-semibold">Scanning Results</div>
            {isMobile && <ScanningResultsLottie height={50} />}
          </div>
          <div className="text-sm">
            In just a couple minutes, you'll see {patient.first_name}'s results
            over time.
          </div>
          <div className="w-full flex flex-col sm:flex-row gap-2">
            {storedJobStatuses?.map((jobStatus, idx) => (
              <ResultInProgressPill
                key={jobStatus.id}
                percentComplete={jobStatus.overallPercent}
                name={jobStatus.name ?? `Result ${idx + 1}`}
                onRemove={() => onRemove(jobStatus.statusId)}
                onComplete={() => onSuccess(jobStatus)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
