import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection } from "app/swr/types"

interface UserResultsCollectionFilters {
  userId?: string
  hasSnapshots?: boolean
  uploadSource?: string
  writeAccessOnly?: boolean
}

export function useUserResultsCollection(
  filters: UserResultsCollectionFilters = {}
) {
  const params: Record<string, string> = {}
  if (filters.hasSnapshots === false) {
    params["filter[blood_lab_dashboard_report_snapshots.isnull]"] = "true"
  } else if (filters.hasSnapshots === true) {
    params["filter[blood_lab_dashboard_report_snapshots.isnull]"] = "false"
  }

  if (filters.uploadSource) {
    params["filter[upload_sources.contains]"] = filters.uploadSource
  }

  if (filters.userId) {
    params["filter[user.id]"] = filters.userId
  }

  if (filters.writeAccessOnly) {
    params["write_access_only"] = "true"
  }

  return useCollectionSWR<ResourceCollection>("/user_results/", { params })
}
