import { ReactComponent as FoodPlanDocumentIcon } from "app/assets/images/documents/food-plan-document-icon.svg"
import useDownloadFoodPlan from "app/food-plans/hooks/use-download-food-plan"
import useRedirectToEditFoodPlan from "app/food-plans/hooks/use-redirect-to-edit-food-plan"
import useEventCallback from "app/hooks/use-event-callback"
import { Patient } from "app/types"
import { FoodPlanDocument } from "types/patient-with-documents"

import DocumentCardActionsMenu from "./DocumentCardActionsMenu"
import PatientDocumentCard from "./PatientDocumentCard"

interface Props {
  patient: Patient
  foodPlan: FoodPlanDocument
  onDelete: (foodPlan: FoodPlanDocument) => void
}

const FoodPlanDocumentCard: React.FC<Props> = ({
  patient,
  foodPlan,
  onDelete,
}) => {
  const { downloadFoodPlan, isDownloadingFoodPlan } = useDownloadFoodPlan()
  const redirectToEditFoodPlan = useRedirectToEditFoodPlan()
  const onEdit = useEventCallback(() => {
    redirectToEditFoodPlan(patient.id, foodPlan.id)
  })

  return (
    <PatientDocumentCard
      onClick={onEdit}
      Icon={(className) => <FoodPlanDocumentIcon className={className} />}
      title="Food Plan"
      subTitle={`Created by ${foodPlan.attributes.created_by_name}`}
      date={foodPlan.attributes.created_at}
      actions={
        <DocumentCardActionsMenu
          onDelete={() => onDelete(foodPlan)}
          onDownload={() => downloadFoodPlan(foodPlan.id)}
          isDownloading={isDownloadingFoodPlan}
          onEdit={onEdit}
        />
      }
    />
  )
}

export default FoodPlanDocumentCard
